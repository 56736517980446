import React, { useState } from 'react';
import Select from 'react-select';
import COUNTRIES from "../countries"
import TYPECOUVERTURE from "../typeCourverture"


export default function SelectComponent({handleChangePays,defaultValue,placeholder}) {
  const [selectedOption, setSelectedOption] = useState(null);

  const countries = [];

  for (const key in COUNTRIES) {
    countries.push({ value: `${COUNTRIES[key]}`, label: `${COUNTRIES[key]}`})
  }


  return (
    <div className="App">
      <Select
        defaultValue={defaultValue}
        onChange={handleChangePays}
        options={countries}
        isSearchable={true}
        placeholder={placeholder}
      />
    </div>
  );
}

export function SelectComponentMulti({handleChangeMulti,defaultValue,placeholder}) {

  const typeCourverture = [];

  for (const key in TYPECOUVERTURE) {
    typeCourverture.push({ value: `${TYPECOUVERTURE[key]}`, label: `${TYPECOUVERTURE[key]}`})
  }

  return (
    <div className="App">
      <Select
        isMulti
        defaultValue={defaultValue}
        onChange={handleChangeMulti}
        options={typeCourverture}
        isSearchable={true}
        placeholder={placeholder}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </div>
  );
}