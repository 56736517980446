import React from 'react'

export default function Schengen() {
  return (
    <>
        <div className="page-title-area page-title-bg-schengen">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Assurances Schengen</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="about-area ptb-60 bg-f8f8f8">
            <div className="container">

                    <div className='col-lg-12 col-md-12 col-12 section-title'>
                        
                        <h2 className=''>Notre sélection "Assurance Schengen" : indispensable pour venir dans l'U.E</h2>

                    </div>

                <div className="col-lg-12 col-md-12 col-sm-12 text-center mtreduce-35">

                <p className='justify-text' style={{fontSize:'17px'}}>Souscrivez en ligne par CB et recevez votre attestation immédiatement par mail. 
                            Elle sera à présenter avec la demande de Visa auprès du consulat local.</p>

                        <div className="container mt-4" data-aos="fade-up" data-aos-delay="10" data-aos-duration="2000" data-aos-once="false">
                            <div className="row">
                                <div className="col-xs-12 col-12 offset-sm-4 col-sm-8">
                                    <div className="row">
                                        <div className="col-md-4 col-4 col-xs-4 theader free">
                                            <div className="ptitle">CAP SCHENGEN CHAPKA</div>
                                            <div className="pprice">À partir de 12 €</div>
                                        </div>
                                        <div className="col-md-4 col-4 col-xs-4 theader standard" >
                                            <div className="ptitle">VOYAGE SCHENGEN</div>
                                            <div className="pprice">À partir de 10 €</div>
                                            <i style={{color: "#C14742"}} className="fa fa-heart fa-beat-fade"></i>
                                        </div>
                                        <div className="col-md-4 col-4 col-xs-4 theader premium">
                                            <div className="ptitle">EUROPAX ACS-AMI</div>
                                            <div className="pprice">À partir de 20 €</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    Zone de couverture
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">
                                            <span>Europe</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">
                                            <span>Europe</span> 
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <span>Europe</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    Limite souscription
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">
                                            <span>- 81 ans</span>                    
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">
                                            <span>aucune</span>                             
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <span>- 70 ans</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    Durée
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">
                                            <span>max 3 mois</span>         
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">
                                            <span>max 12 mois</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <span>max 3 mois</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    Assistance 24/24
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">
                                            <span>oui</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">
                                            <span>oui</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            
                                            <span>oui</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    Frais médicaux
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">
                                            <span>max 50 000 €</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">
                                            <span>max 35 000 €</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <span>max 30 000 €</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    Franchise
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">
                                            <span>30 €</span>
                                        </div> 
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">
                                            <span>80 €</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <span>30 €</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    Remboursement frais de visa si refus
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">
                                            <span>non</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">
                                            <span>max 50 €</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <span>non</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    Remboursement de la prime suite à refus de visa
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free" style={{height: "70px"}}>              
                                            <span>non</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">       
                                            <span>oui, franchise 10 €</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <span>non</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row feature color">
                                <div className="offset-md-4 col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">
                                            <a href="http://www.chapkadirect.fr/index.php?action=produit&id=756&app=cd_bilan" target="_blank" className="btn btn-lg btn-xs devis-btn">Devis</a>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">
                                            <a href="http://bsi.assurancesvisa.com" target="_blank" className="btn btn-lg devis-btn-middle">Devis</a>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <a href="http://www.acs-ami.com/fr/acs/europax?part=bilansante" target="_blank" className="btn btn-lg devis-btn">Devis</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>


                <div className='row ptb-60'>
                    <div className='col-lg-12 col-md-12 col-12'>

                        <div className='section-title'>
                            <h2 data-aos="fade-right">Schengen : votre obligation d'assurance</h2>
                        </div>
                        <div>

                            
                                <p className='justify-text' data-aos="fade-up">
                                    Tout voyageur temporaire n’appartenant pas à l’Espace Schengen a besoin de présenter pour l’obtention de son visa (ou un simple transit en U.E) 
                                    un certificat d’assurance conforme à la réglementation européenne. Cette assurance doit être valable pendant toute la durée du séjour. 
                                    Elle doit garantir  à concurrence d’un minimum de 30 000 € les frais de rapatriement sanitaire et les soins médicaux ou hospitaliers 
                                    d’urgence survenus lors du séjour.
                                </p>
                            

                            
                                <p className='justify-text' data-aos="fade-up">
                                    Tout visa Schengen délivré par un État membre des Accords de Schengen est valable pour l’ensemble du territoire Schengen.
                                </p>
                            

                            
                                <p className='justify-text' data-aos="fade-up">
                                    <b>Qu’est-ce que l’Espace Schengen ?</b><br/>
                                    L’Espace Schengen désigne les 26 États européens ayant adhéré aux Accords de Schengen en 1985 et à la Convention de Schengen en 1990, à savoir : Allemagne, Autriche, Belgique, République tchèque, Danemark (sauf Groenland et Iles Féroé), Estonie, Espagne (sauf Ceuta et Melilla), Finlande, 
                                    France (sauf France d’outre-mer), Grèce, Hongrie, Islande, Italie, Lettonie, Liechtenstein, Lituanie, Luxembourg, Malte, Norvège (sauf Svalbard), 
                                    Pays-Bas (sauf Aruba, Curaçao, Saint-Martin et les Pays-Bas caribéens), Pologne, Portugal, Slovaquie, Slovénie, Suède, Suisse. 
                                </p>
                            

                            
                                <p className='justify-text' data-aos="fade-up">
                                    Choisissez votre formule parmi notre sélection et souscrivez en quelques clics : vous  recevez immédiatement par mail votre attestation, après <b>paiement par carte bancaire.</b>
                                </p>
                           


                            <p className='justify-text'>Demandez-nous votre projet, sans engagement ! La souscription est ensuite 
                                très simple, avec nos conseils en prime !</p>

                            <a href='/Demandededevis/particuliers' data-aos="fade-right"  className="default-btn"><i className="fa-solid fa-chart-column fa-1x fa-flip">&nbsp;&nbsp;</i> Demandez votre devis !<span></span></a>

                
                            <div className='row another-link' data-aos="fade-right" >
                                <h3 className='mt-5'>Vous pourriez être intéressé par les produits suivants :</h3>

                                <div className='col-md-4 mt-2'>
                                    <a href="/Expatries"   className=""><b>Assurances expatriés</b> <span></span></a>
                                </div>

                                <div className='col-md-4 mt-2'>
                                    <a href="/Pas-expatriés"   className=""><b>Assurances non expatriés</b> <span></span></a>
                                </div>

                                <div className='col-md-4 mt-2'>
                                    <a href="/GlobeTrotters"   className=""><b>Globe-trotters et étudiants</b> <span></span></a>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
                
 
            </div>
        </section>
    </>
  )
}
