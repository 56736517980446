import React from 'react'

export default function GuarantiSection() {
  return (
        <section className="about-area ptb-60 mt-5">
            <div className="container" 
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-once="false">
                <div className="section-title">
                    <h2>Notre engagement</h2>
                    <p>Nous vous assurons un service de qualité {" "}
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                    </p>
                </div>

                <div className="about-boxes-area mtreduce-35">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-4 col-sm-4  col-6">
                            <div className="single-about-box">
                                <div className="icon">
                                    <i className="flaticon-target"></i>
                                </div>

                                <h3>Tarifs comparés</h3>
                                {
                                    window.innerWidth > 600 && <p>Les meilleures compagnies aux meilleurs prix</p>
                                }
                                 
                                
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-4  col-6">
                            <div className="single-about-box">
                                <div className="icon">
                                    <i className="flaticon-like"></i>
                                </div>

                                <h3>Satisfaction</h3>
                                {
                                    window.innerWidth > 600 && <p>87% de renouvellements</p>
                                }
                                
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-4  col-6">
                            <div className="single-about-box">
                                <div className="icon">
                                    <i className="flaticon-timetable"></i>
                                </div>

                                <h3>Réponse rapide</h3>
                                {
                                    window.innerWidth > 600 && <p>Moins de 24h</p>
                                }
                                
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-4  col-6">
                            <div className="single-about-box">
                                <div className="icon">
                                    <i className="flaticon-care"></i>
                                </div>

                                <h3>Paiement par CB</h3>
                                {
                                    window.innerWidth > 600 && <p>Pour être couvert immédiatement</p>
                                }
                                
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-6">
                            <div className="single-about-box">
                                <div className="icon">
                                    <i className="flaticon-policy"></i>
                                </div>

                                <h3>24/24 assistance monde entier</h3>
                                {
                                    window.innerWidth > 600 && <p>Dans la plupart de nos contrats</p>
                                }
                                
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="single-about-box">
                                <div className="icon">
                                    <i className="flaticon-insurance"></i>
                                </div>

                                <h3>BSI récompensé</h3>
                                {
                                    window.innerWidth > 600 && <p>Lauréat des 11èmes trophées du courtage de l'assurance</p>
                                }
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}
