import React from 'react'
import nos_valeurs from '../styles/img/nos_valeurs.png'
import cartographie_image from '../styles/img/cartographie.png'

export default function Ebsi() {
  return (
    <>

        <div className="page-title-area page-title-ebsi">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>E-BSI, Créateur d'assurtechs !</h2>
                            <p className="mt-2">
                                Digitalisation de produits d'assurance et développement de progiciels métiers : c'est votre chiffre d'affaires qui va exploser 😉
                            </p>
                            <p>
                                Consultez nos références et parlons de vos projets !
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="insurance-details-area mt-60 ptb-60">
            <div className="container" data-aos="fade-up" data-aos-once="false" >
                <div className="insurance-details-header">
                    <div className="row align-items-center">
                        <div className="col-lg-8 col-md-12 col-12" data-aos="fade-left" data-aos-once="false">
                        <div className="section-title-left">
                            <h2>UNE "ASSURTECH" ENGAGÉE À VOS CÔTÉS.</h2>
                        </div>
                            <div className="content">
                                <p className='justify-text'>
                                    <b style={{color: "#C14742", fontSize: "20px"}}>E-BSI</b> est notre département “assurtech” dédié à la
                                    transformation digitale des assureurs : <b>Business System for
                                        Insurance</b>.
                                </p>
                                <p className='justify-text'>
                                    Nous sommes experts métiers de l’assurance et utilisateurs
                                    : c’est pourquoi, mieux que personne, nous savons ce
                                    qu’assurés et intermédiaires attendent de leur assureur 3.0.
                                </p>
                                <p className='justify-text'>
                                    Pour digitaliser l’assurance, l’expertise technique est un
                                    prérequis mais également la compréhension du parcours
                                    client et le marketing produit. <b>Chez BSI, nous regroupons
                                        ces différentes compétences pour faire avancer vos
                                        projets avec une approche globale, en assurances IARD ou
                                        Vie.</b>
                                </p>

                                <p className='justify-text'>
                                    Pour nos clients (compagnies d’assurance ou courtiers){" "}
                                    <b>nous concevons des outils web et des progiciels</b> dernière
                                    génération, indispensables à leur développement
                                    commercial comme à leur gestion : solution digitale
                                    innovante, ergonomique et sécurisée 100% RGPD.
                                </p>

                                <p className='justify-text'>
                                    <b>Avec notre conseil, ce ne sont pas les informaticiens qui
                                        dictent leurs lois mais les utilisateurs !</b>
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12 col-12" data-aos="fade-right" data-aos-once="false">
                            <div className="image text-center">
                                <img className='fa-beat-fade' style={{'--fa-animation-duration': "3s", width: '80vw'}} src="assets/img/Logo-ebsi.webp" alt="e-bsi"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

         

       <section className="insurance-details-area ptb-60 bg-black-color">
            <div className="container" data-aos="fade-up" data-aos-once="false">
                <div className="insurance-details-header">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-12 col-12" data-aos="fade-left">
                            <div className="image text-center">
                                <img src="assets/img/gagnez_en_performance.webp" alt="image"/>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-12 col-12" data-aos="fade-right">
                            <div className="section-title-left">
                                <h2>GAGNEZ EN CONFORT ET PERFORMANCE</h2>
                            </div>
                            <div className="content">
                                <p className='justify-text'>
                                    Nous sommes <b>spécialistes de la zone CIMA</b> et de ses
                                    réglementations particulières. Ceci nous donne dans cette
                                    zone une longueur d’avance sur les SSII ou ESN
                                    traditionnelles et leurs équipes généralement issues du
                                    monde informatique et non de l’assurance.
                                </p>
                                <p className='justify-text'>
                                    Nous sommes en effet implantés également au Bénin
                                    comme assureur-conseil et pour <b>E-BSI</b> nous nous appuyons
                                    localement sur une équipe de développeurs et graphistes
                                    brillants, dynamiques et réactifs pour relever vos défis : nos
                                    propositions sont dès lors généralement <b>tarifairement plus
                                    compétitives</b> que celles de nos confrères en Europe ou
                                    ailleurs. <b>Nous garantissons les délais de livraison et le SAV.</b>

                                </p>
                                <p className='justify-text'>
                                    Nous concevons des plateformes web et par extension
                                    proposons aux assureurs santé <b>nos services de
                                        souscription médicale pour compte</b>, avec notre
                                    département interne et son médecin conseil. Vous pouvez
                                    par exemple visiter <b><a href='https://aps-benin.com/'>ICI</a></b> notre réalisation pour l’APS,
                                    association d’assurés avec Atlantique Assurances Bénin. Ou
                                    encore la <b><a href='https://tarification.aabvie.net/'>marketplace d’Atlantique Assurance Vie.</a></b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section className="insurance-details-area ptb-60">
            <div className="container" data-aos="fade-up" data-aos-once="false">
                <div className="insurance-details-header">
                    <div className="section-title">
                        <h2>INGÉNIERIE D'ASSURANCES</h2>
                    </div>
                    <div className="row ">
                        <div className="col-lg-6 col-md-12 col-12" data-aos="fade-left">
                                <div className="content">
                                    <p className='justify-text'>
                                        <b>Chaque client a des besoins spécifiques sur son marché.</b>
                                    </p>
                                    <p className='justify-text'>
                                        <p className='justify-text'>
                                            Nous pouvons accompagner nos partenaires dans les <br/>différentes étapes de
                                            la vie d'un contrat d'assurance :
                                        </p>
                                        
                                        <ul>
                                            <li>rédaction wording / relooking</li>
                                            <li>documents techniques et commerciaux</li>
                                            <li>modélisation tarifaire</li>
                                            <li>réassurance</li>
                                            <li>souscription médicale</li>
                                            <li>digitalisation</li>
                                        </ul>
                                    </p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-12" data-aos="fade-right">
                            <div className="content">
                                <p className='justify-text'>
                                    Comme nous sommes convaincus et passionnés, nous pensons que <b>la
                                    digitalisation de nos réalisations est un fort vecteur de croissance</b> pour nos
                                    clients, indispensable à l'ère du numérique : nous nous sommes donnés les moyens de les
                                    accompagner en nous entourant de notre équipe de développeurs.
                                </p>

                                <p className='justify-text'>
                                    Pour digitaliser un contrat et des processus, nous maîtrisons parfaitement le cahier
                                    des charges et le fil d'Ariane... Nos développements s'intègrent dans les sites
                                    internet de nos clients et sont API compatibles.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="portfolio" className="portfolio ptb-60 bg-black-color" >
                <div className="container" data-aos="fade-up" data-aos-once="false">
                    <div className="section-title">
                        <h2>CARTOGRAPHIE CHAÎNE DE VALEURS</h2>
                        <p className=''> Une solution complète et personnalisable pour gérer vos produits et vos intermédiaires de A à Z.</p>
                        <div><img src={cartographie_image}  alt="cartographie_image"/></div>
                    </div>
                </div>
        </section>

        <section id="portfolio" className="portfolio ptb-60" >
                <div className="container" data-aos="fade-up" data-aos-once="false">
                    <div className="section-title ">
                        <h2>FONCTIONNALITÉS PRINCIPALES DE NOS MARKETPLACES</h2>
                    </div>

                        <div className="row insurance-details-desc" style={{marginTop: "-30px"}}>
                        <div className="col-lg-6 col-md-6 col-12" >
                            <ul className="features-list">
                                <li> Tunnel de tarification et de souscription</li>
                                <li> Extranets Clients et Intermédiaires</li>
                                <li> Gestion et suivi exploitation</li>
                                <li> Développement commercial</li>
                            </ul>
                        </div>

                        <div className="col-lg-6 col-md-6 col-12">
                            <ul className="features-list">
                                <li> GED, signature électronique, outils CRM</li>
                                <li> Business Intelligence</li>
                                <li> Sécurité et gestion des habilitations</li>
                                <li> Interopérabilité : API/EDI/Import/Export</li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
        </section>

        <section className="insurance-details-area ptb-60 bg-black-color">
            <div className="container" data-aos="fade-up" data-aos-once="false">
                <div className="insurance-details-desc">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                            <img src={nos_valeurs} style={{ width: '100vw'}} alt="nos_valeurs"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="portfolio" className="portfolio ptb-60">
            <div className="container" data-aos="fade-up" data-aos-once="false">
                <div className="section-title">
                    <h2>NOS DERNIÈRES RÉALISATIONS</h2>
                </div>
    
                <div className="row">
        
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" data-aos="fade-left">
                        <div className="card text-center realisation-section">

                            <div className="content">
                                <h3><a href="https://tarification.aps-benin.com/">APS - Bénin | Assurance Santé & Prévoyance</a></h3>
        
                                <p className='justify-text'>APS est une association d’assurés loi 1901 pour laquelle
                                    nous avons développé une plateforme de tarification et
                                    de souscription d'assurances santé et prévoyance SƆHU,
                                    avec Atlantique Assurances Bénin IARD et Vie.
                                </p>
                            </div>

                            <div className="text-center mt-2">
                                <img src="assets/img/logo-aps.png"   alt="image"/>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" data-aos="fade-right">
                        <div className="card text-center realisation-section">

                            <div className="content">
                                <h3><a href="http://e-bsi.co/">E-BSI | Progiciel de gestion courtage</a></h3>
        
                                <p className='justify-text'>Nous avons développé un “progiciel maison” de gestion
                                    de cabinet de courtage y.c extranets Apporteurs
                                    d'affaires et Clients. Qui mieux qu’un courtier connaît nos
                                    besoins quotidiens ?
                                </p>
                            </div>

                            <div className="text-center mt-2">
                                <img src="assets/img/logo-ebsi-mini.webp" height="75" alt="image"/>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" data-aos="fade-left">
                        <div className="card text-center realisation-section">

                            <div className="content">
                                <h3><a href="https://tarification.aabvie.net/">AAB - VIE | Marketplace en mode « SaaS »</a></h3>
        
                                <p className='justify-text'>Plateforme de commercialisation de produits assurances
                                    vie : gestion des tarifications et des souscriptions, suivi
                                    des contrats, gestion des réseaux de distribution,
                                    extranets Intermédiaires et Clients.
                                </p>
                            </div>

                            <div className="text-center mt-2">
                                <img src="assets/img/logo-atlantique-vie.png" width="120"  alt="image"/>
                            </div>
                        </div>
                    </div>
        
                </div>
    
            </div>
        </section>

        <section className="blog-area bg-black-color ptb-60">
            <div className="container" data-aos="fade-up" data-aos-once="false">
                    <div className="section-title" data-aos="fade-up" data-aos-once="false">
                        <h2>Ils nous ont fait confiance</h2>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center" style={{marginTop: "-30px"}}>
                        <div className="container">
                            <div className="partner-slides owl-carousel owl-theme">
                                
                                <a href="http://www.atlantiqueassurances.bj/" className="single-partner-item">
                                    <img src="assets/img/logo-atlantique-iardt.png" alt="image"/>
                                </a>

                                <a href="https://aps-benin.com/" className="single-partner-item">
                                    <img src="assets/img/logo-aps.png" alt="image"/>
                                </a>
                            
                                <a href="https://aabvie.net/public/accueil" className="single-partner-item">
                                    <img src="assets/img/logo-atlantique-vie.png" alt="image"/>
                                </a>

                                <a href="https://www.sanlampanafrica.com/" className="single-partner-item">
                                    <img src="assets/img/logo-sanlam.png" alt="image"/>
                                </a>

                                <a href="https://www.groupensia.com/" className="single-partner-item">
                                    <img src="assets/img/logo-nsia.png" alt="image"/>
                                </a>
                            
                                <a href="http://www.africabrokersonline.com/" className="single-partner-item">
                                    <img src="assets/img/logo_africa_brokers_company.png" alt="image"/>
                                </a>
                            
                                <a href="https://www.orabank.net/fr" className="single-partner-item">
                                    <img src="assets/img/logo-oragroup.png" alt="image"/>
                                </a>

                                <a href="https://sfa-congo.com/" className="single-partner-item">
                                    <img src="assets/img/logo-sfa.png" alt="image"/>
                                </a>
                            
                            </div>
                        </div>

                        <p style={{fontSize: "15px"}} className="mt-4">
                            N’hésitez pas à nous faire part de vos projets. Nous sommes une
                            équipe à l’écoute, pragmatique et pleine de talents pour accompagner
                            votre transition digitale.
                        </p>

                    </div>
                    
            </div>
        </section>
    
    </>
  )
}
