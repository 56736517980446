import React from 'react'
import aurelie from '../../styles/img/image-aurelie.webp'
import gillus from '../../styles/img/image-gillus.webp'
import francois from '../../styles/img/image-françois.webp'


export default function TeamSection() {
  return (
    <section className="team-area ptb-60">
            <div className="container"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-once="false">
                <div className="section-title" data-aos="zoom-in-down">
                    <h2>LES ASSOCIÉS BSI</h2>
                    {/* <p>Des spécialistes « métiers » à votre service !</p> */}
                    <p>Des spécialistes « métiers » à votre service. Et en plus on est sympa 😄</p>
                </div>

                <div className="team-slides owl-carousel owl-theme mtreduce-35">

                    <div className="single-team-box" data-aos="flip-left" data-aos-offset="200"
                        data-aos-delay="10"
                        data-aos-duration="2500">
                        <div className="image">
                            <img src={aurelie} alt="image-aurelie"/>

                            <ul className="social">
                                <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>

                        <div className="content">
                            <h3>Aurélie Faure</h3>
                            <span>Associée BSI</span>
                        </div>
                    </div>

                    <div className="single-team-box" data-aos="flip-right" data-aos-offset="200"
                        data-aos-delay="10"
                        data-aos-duration="2500">
                        <div className="image">
                            <img src={gillus} alt="image-gillus"/>

                            <ul className="social">
                                <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>

                        <div className="content">
                            <h3>Gillus Landehou</h3>
                            <span>Associé BSI</span>
                        </div>
                    </div>

                    <div className="single-team-box" data-aos="flip-up" data-aos-offset="200"
                        data-aos-delay="10"
                        data-aos-duration="2500">
                        <div className="image">
                            <img src={francois} alt="image-françois"/>

                            <ul className="social"> 
                                <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>

                        <div className="content">
                            <h3>François Masson</h3>
                            <span>Associé et fondateur BSI - C.C.E.F</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}
