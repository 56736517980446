import React from 'react'

export default function HeroSection() {
  return (
        <div className="home-area home-slides-two owl-carousel owl-theme">
            
            <div className="banner-section item-bg4">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="main-banner-content">
                                <h1 data-aos="slide-up">Assurances pour nomades du 21è siècle !</h1>                      
                                <div className="" data-aos="fade-left">
                                    <span className="sub-title">Expatriés, globe trotters, VIP dans le monde ? <br/> Nous avons des solutions pour vous assurer !</span>
                                </div>
                                <div className="" data-aos="fade-right">
                                    <span className="sub-title1">Marre des robots ? Ici des Humains vous répondent &#x1F642;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="banner-section item-bg1">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="main-banner-content">
                                <h1 data-aos="fade-bottom">Employeurs de tous les pays, choyez vos expatriés !</h1>
                                <div className="" data-aos="slide-right">
                                    <span className="sub-title">Nous offrons un service sur mesure à des entreprises ou des ONG...</span>
                                </div>
                                <div className="" data-aos="slide-left">
                                    <span className="sub-title1">Votre courtier 2.0 expert en assurance santé internationale.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="banner-section item-bg-ebsi">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="main-banner-content">
                                <h1 data-aos="slide-up">E-BSI, Créateur d'assurtechs !</h1>
                                <div className="" data-aos="fade-left">
                                    <span className="sub-title">Digitalisation de produits d'assurance et développement de progiciels métiers : c'est votre chiffre d'affaires qui va exploser 😉</span>
                                </div>
                                <div className="" data-aos="fade-right">
                                    <span className="sub-title1">Consultez nos références et parlons de vos projets !</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="banner-section banner-section-casino item-bg-bsimmobiler">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="main-banner-content">
                                <h1 data-aos="slide-up">épargne ou casino ?</h1>
                                <div className="" data-aos="fade-left">
                                    <span className="sub-title">Vous avez trouvé une pépite ou amassé un pécule ? <br />
                                    Tous nos conseils pour investir le plus sereinement possible.</span>

                                    {/* <span className="sub-title">Tous nos conseils pour investir le plus sereinement possible.</span> */}
                                </div>
                                <div className="" data-aos="fade-right">
                                    <span className="sub-title1">Expatrié ou non, nous vous guidons de A à Z !</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}
