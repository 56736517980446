import React from "react";

export default function Bsimmobilier() {
  return (
    <>
      <div className="page-title-area page-title-bsimmobiler">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>
                  épargne ou casino ?
                </h2>
                <p className="mt-2">
                Vous avez trouvé une pépite ou amassé un pécule ?
                </p>
                <p>
                Tous nos conseils pour investir le plus sereinement possible.
                </p>
                <p>Expatrié ou non, nous vous guidons de A à Z !</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="insurance-details-area ptb-60">
        <div className="container">
          <div className="insurance-details-header">
            <div className="row align-items-center" 
                data-aos="fade-right"
                data-aos-once="false">
                <div className="section-title-left mt-5">
                  <h2>Investir dans de la pierre en France ou en Europe</h2>
                </div>
              <div
                className="col-lg-8 col-md-12 col-12">
                <div className="content">
                  <p className="justify-text">
                    <b>
                    L'expatriation est souvent source de revenus complémentaires et une occasion rêvée pour se 
                    constituer un capital, transmissible en toute sécurité. BSI propose de l’investissement immobilier 
                    (locatif ou non) à travers son département BSImmobilier, spécialisé dans cette activité d’investissement. 
                    Nous sommes titulaires de la fameuse carte T qui nous permet de proposer des biens immobiliers à nos clients :
                    </b>
                    <ul className="mt-4">
                      <li>
                        vente de bien “en l’état futur d’achèvement” (garanties
                        d’achèvement, garantie de mise en place du premier
                        locataire, gestion mensuelle de proximité avec garantie
                        des loyers impayés, garantie biennale, décennale…).
                        Optimisation fiscale pour les assujettis à l’impôt sur
                        les revenus en France ou pour les expatriés ;
                      </li>
                      <li>
                        vente de biens anciens et « chasse d’appartement » ;
                      </li>
                      <li>
                        courtage en crédit immobilier spécial expatriés (crédit
                        in fine ou non) ;
                      </li>
                      <li>assurance emprunteur spécial expatriés ;</li>
                      <li>assurance immeuble ;</li>
                    </ul>
                    Retrouvez <b><a href="/Blog">ICI</a></b> tous nos articles sur l’investissement immobilier.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="image text-center">
                  <img
                    className="fa-beat-fade"
                    style={{ "--fa-animation-duration": "3s" }}
                    src="assets/img/logo-bsi-immobilier.webp"
                    alt="bsi-immobilier"
                  />
                </div>
              </div>
            </div>

            <div className="section-title-left mt-5">
                <h2>Investir dans une « assurance vie »</h2>
            </div>

            <div className="content">
              <p className="justify-text">
                <b>
                  Investir dans une « assurance vie » (retraite “par
                  capitalisation”) :
                </b>

                <ul className="mt-4">
                  <li>
                    Nous représentons Allianz Life Luxembourg et son vaste choix
                    de fonds actions ou obligations, en différentes devises.
                  </li>
                  <li>
                    Tous les avantages de l’assurance vie en cas de transmission
                    et la sécurité du Luxembourg.
                  </li>
                  <li>
                    Un objectif d’investissement conseillé sur au moins huit
                    ans. Ouverture 50 000 euros minimum puis abondement libre à
                    votre rythme. Le fonds « euro garanti » n’est plus proposé.
                    Mais au Luxembourg, on adore les expatriés européens 😊.
                  </li>
                </ul>
                Retrouvez <b><a href="/Blog">ICI</a></b> tous nos articles sur l’assurance vie au Luxembourg.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
