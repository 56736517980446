import React, { useState, useEffect } from "react";
import ChildForm from "../FormTools/ChildForm";
import AssuredForm from "../FormTools/AssuredForm";
import Dropzone from "../FormTools/Dropzone";

import SelectComponent from "../../common/SelectComponent"
import CurrentDate from '../../common/getCurrentDate'
import DateInput from '../../../helpers/datepicker'
import { useSelector, useDispatch } from 'react-redux';
import { setMainAssured } from "../../../features/enterpriseForm/enterpriseFormSlice";


export default function Step4({ CurrentTitle, Prev, Next, radioAssured, setRadioAssured, activeRadio, setActiveRadio, errors, setErrors, setRadioChild, radioChild, radioConjoint, setRadioConjoint }) {

  const { mainAssured } = useSelector((state) => state.enterpriseData);
  const dataStep4 = useSelector((state) => state.enterpriseData);
  const dispatch = useDispatch()
  const AssuredData = AssuredForm;
  const [old, setOld] = useState([]);
  // const [oldConjoint, setOldConjoint] = useState(null);
  // const [oldEnfant, setOldEnfant] = useState([]);
  const [alert, setAlert] = useState(null);

  // const [show, setShow] = useState(false);

  // const handleClose = () => {setShow(false)}

  // const handleShow = () => {

  //     setShow(true);

  //   }

  // replace filter by filterMap

  const onAssuredClose = (position) => {

    setRadioAssured(radioAssured.filter((v, i) => i !== position));
    dispatch(setMainAssured(mainAssured.filter((v, i) => i !== position)))
    setOld(old.filter((v, i) => i !== position))

  };

  const onDisabled = () => {

    let ValidateForm = [];

    if (mainAssured && mainAssured.length > 0 ) {
      for (const element of mainAssured) {
        if (mainAssured[mainAssured.indexOf(element)].dateNaissanceAssurePrincipal !== '' 
          && mainAssured[mainAssured.indexOf(element)].sexeAssurePrincipal !== '' 
          && mainAssured[mainAssured.indexOf(element)].nationaliteAssurePrincipal !== '') {

            ValidateForm.push('pass');

        } else {

            ValidateForm.push('not pass');

        }

      }
      if( ValidateForm.includes('not pass')) {
        return true;
      }else {
        return false;
      }
    }

  }

  const NextStep = () => {

    setErrors({})
    Next();

  }

  return (
    <div>

      {radioAssured && radioAssured.map((number, index) => (
        <>
          <AssuredForm
            position={index}
            onAssuredClose={() => onAssuredClose(index)}
            errors={errors}
            setErrors={setErrors}
            radioConjoint={radioConjoint}
            radioChild={radioChild}
            setRadioChild={setRadioChild}
            setRadioConjoint={setRadioConjoint}
            radioAssured={radioAssured}
            setRadioAssured={setRadioAssured}
            activeRadio={activeRadio}
            setActiveRadio={setActiveRadio}
            old={old}
            setOld={setOld}
            AssuredData={AssuredData}
          >
          </AssuredForm>
        </>
      ))}

      {/* Ceci pour l'afffichage du dropzone pour le chargement de fichier excel */}
      {/* {show ===  true &&

      <div className="mt-5 pt-3">
        <Dropzone file={file} setFile={setFile} />
        <a href="#">? format fichier</a>
      </div>
        
      } */}


      {/* {show ===  false && */}
      <div className="row">

        <div className="col-lg-12 col-md-12 col-sm-12 mt-3"
          onClick={(e) => {
            setRadioAssured([...radioAssured, AssuredData]);
            dispatch(setMainAssured([...mainAssured,
            {
              dateNaissanceAssurePrincipal: '',
              sexeAssurePrincipal: '',
              nationaliteAssurePrincipal: '',
              conjoint: {},
              enfants: []
            }
            ]))
          }}
          style={{ cursor: "pointer" }}
        >

          <span className={`${mainAssured.length > 0 ? "fas fa-user-plus offset-1" : "fas fa-user-plus"}`} style={{
            fontSize: '10px',
            color: '#ffffff',
            background: '#002e5b',
            border: '1px solid #002e5b',
            borderRadius: '50%',
            padding: '8px'
          }}></span>{" "}
          <span>
            <b>Ajouter un(e) {radioAssured.length > 0 ? 'autre' : ''}  assuré(e)</b>
          </span>{" "}
        </div>

        {/* { mainAssured.length === 0 ?
              <div
              className="offset-md-1 col-lg-5 col-md-5 col-sm-12 mt-3"
              style={{ cursor: "pointer" }}
              onClick={handleShow}
              >

                <span className="fas fa-upload" style={{fontSize:'10px',
                    color: '#ffffff',
                    background: '#002e5b',
                    border: '1px solid #002e5b',
                    borderRadius:'50%',
                    padding:'8px'}}></span>{" "}
                <span>
                  <b>Téléchager une liste d'assuré(e)</b>
                </span>{" "}
              </div>

              : ''
            } */}


      </div>

      {/* } */}



      <div className="offset-lg-1 col-lg-12 col-md-12 col-sm-12">

        {mainAssured.length > 0 &&
          <div style={{ color: 'red' }} className='col-lg-6 col-md-6 col-sm-12 mt-5'>* Champ obligatoire</div>
        }

        <div className={`form-footer-content-position 
            ${CurrentTitle === 3 || CurrentTitle === 4 || CurrentTitle === 5 ? 'form-footer-margin' : ''} 
            ${mainAssured.length > 0 ? 'col-lg-12 col-md-12 col-12' : ''}`}>

          <button type="button" onClick={Prev} className="default-btn-annuler">
            Retour
          </button>

          <button disabled={onDisabled()} type="button" onClick={NextStep} style={{ marginLeft: "10px" }} className="default-btn">
            Suivant
          </button>

        </div>

      </div>

      {/* <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            centered={true}
            keyboard={false}
          >
            <Modal.Header >
              <Modal.Title>Confirmation !!!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Dropzone />
            </Modal.Body>
            <Modal.Footer>
            <a>? format csv</a>
            <button type="button" onClick={handleClose}  style={{ marginLeft: "10px"}} className="default-btn-annuler">
              Annuler</button>
              <button type="button"   style={{ marginLeft: "10px"}} className="default-btn">
              Télécharger</button>
            </Modal.Footer>
          </Modal> */}





    </div>
  );
}
