import React, {useState} from 'react'
import FormBottomText from '../components/common/FormBottomText'
import { postEntity } from '../services/api';
import { Alert } from 'react-bootstrap';

export default function Partner() {

    const [data, setData] = useState([]);
    const [alert, setAlert] = useState(null);
    const [button, setButton] = useState(<i className="fa fa-paper-plane"></i>);


    const onSubmit = (event) => {
            event.preventDefault();

            setButton(<i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>);

              postEntity("/contact/save",data).then(() => {

                setAlert({ success: true, message: 'Votre soumission a été prise en compte' })
                setButton(<i className="fa fa-paper-plane"></i>)
                
                }, () => {
                    setAlert({ success: false, message: 'Erreur l\'ors de l\'envoie' });
                }).finally(() => {
                    setTimeout(() => {
                      setAlert(null);
                      window.location.reload()
                    }, 5000);
                    
                  });
    
      }

  return (
    <>

        <div className="page-title-area page-title-bg-partner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Devenir partenaire</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="insurance-details-area mt-60 ptb-60">
            <div className="container" data-aos="fade-up" data-aos-once="true">
                <div className="insurance-details-header">
                    <div className="row align-items-center">
                        <div className="section-title-left">
                            <h2>QUI EST BSI ?</h2>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="content">
                                <p className='justify-text'>
                                    Un cabinet de Courtage d’Assurances basé à Paris, spécialisé en assurance santé et prévoyance internationale.
                                </p>
                                <p className='justify-text'>
                                    Nous offrons un service personnalisé et rapide à travers le réseau de compagnies qui nous ont agréés
                                    et assurons des clients de toutes nationalités, dans le monde entier.
                                </p>
                                <p className='justify-text'>
                                    Nous développons un réseau de ‘‘partenaires de BSI’’, souvent professionnels de 
                                    l’assurance (Courtiers ou Agents de compagnies) et leur proposons un concept d’apport d’affaires 
                                    dans notre domaine de compétences : l’assurance santé ou prévoyance internationale, en contrats 
                                    individuels ou collectifs.
                                    Nos accords s’inscrivent dans le cadre de l’article R 511-3 du code des Assurances.
                                </p>

                                <p className='justify-text'>
                                    Notre conviction est que l’avenir est aux professionnels qui sauront s’organiser pour travailler en réseau 
                                    et sauront combiner leurs forces. Notre offre complète harmonieusement l’expertise et la présence 
                                    locale de nos partenaires.
                                </p>

                                <p className='justify-text'>
                                    Nos clients (les vôtres demain ?) sont des expatriés européens, des binationaux dans le monde, des 
                                    impatriés en Europe ou encore des VIP de toutes nationalités dans leur pays ou à l’étranger*. 
                                    Les solutions auxquelles vous avez accès ne sont pas toujours satisfaisantes alors que vos 
                                    clients ont des exigences légitimes en matière de protection, pour eux-mêmes et leur famille : 
                                    nous sommes à leur écoute. Le monde bouge : vous et vos clients aussi !
                                </p>

                                <p className='justify-text'>
                                    *sous réserve d’éventuelles restrictions internationales ou locales dans le pays du candidat à l’assurance : 
                                    il est conseillé à ce dernier de se renseigner auprès des autorités locales compétentes pour vérifier si des 
                                    restrictions existent à ce que des ressortissants locaux souscrivent des plans d’assurances internationaux.
                                </p>
                            </div>
                        </div>

                        {/* <div className="col-lg-4 col-md-12 col-12" data-aos="fade-right" data-aos-once="false">
                            <div className="image text-center">
                                <img src="assets/img/assurance-cote1.jpg" height={400} alt="image"/>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>

        <section className="insurance-details-area ptb-60 bg-black-color">
            <div className="container" data-aos="fade-up" data-aos-once="false">
                <div className="insurance-details-header">
                    <div className="row align-items-center">
                        <div className="section-title-left">
                            <h2>UN MODÈLE DE PARTENARIAT UNIQUE</h2>
                            <p>Des commissions pour "indication d'affaires" très incitatives !</p>
                        </div>
                        <div className="col-lg-4 col-md-12 col-12" data-aos="fade-left" data-aos-once="false">
                            <div className="image text-center">
                                <img src="assets/img/model_partenariat_unique.webp" height={400} alt="image"/>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-12 col-12" data-aos="fade-right" data-aos-once="false">
                            <div className="content">
                                <p className='justify-text'>
                                    Le partenariat conçu par BSI permet à des personnes physiques (généralement Agents de compagnies ou Courtiers) n’ayant 
                                    pas les connaissances techniques, la capacité de placement ou tout simplement le temps, de <b>fidéliser des clients 
                                    existants</b> ou d’en <b>conquérir de nouveaux</b>, grâce à des solutions variées en matière d’assurance santé internationale 
                                    ou prévoyance.
                                </p>
                                <p className='justify-text'>
                                    Sur votre introduction, nous <b>‘‘prenons le relais’’</b> avec pour seul objectif la satisfaction du client ! <b>Contrairement à une convention de co-courtage</b>, plus contraignante pour les parties, 
                                    la convention de partenariat dégage nos partenaires de responsabilité. Nous assumons notre rôle de conseil, 
                                    de A à Z et nous occupons de toute la procédure.
                                </p>
                                <p className='justify-text'>
                                    Notre principe est simple : si nous réalisons une affaire grâce à l’un de nos ‘‘partenaires de BSI’’, 
                                    une commission pour ‘‘introduction d’affaire’’ lui est versée par virement bancaire, conformément à 
                                    la convention signée entre nous. Et plus vous êtes ‘‘actif’’, plus vos commissions augmentent ! Alors, 
                                    serez-vous un partenaire ‘‘bronze’’, ‘‘argent’’ ou ‘‘or’’ ?
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="insurance-details-area mt-60 ptb-60">
            <div className="container" data-aos="fade-up" data-aos-once="true">
                <div className="insurance-details-header">
                    <div className="row align-items-center">
                        <div className="section-title-left">
                            <h2>DEVENEZ PARTENAIRE DE BSI</h2>
                            <p>Pas d'objectif annuel minimum et des procédures très simples pour vous !</p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="content">
                                <p className='justify-text'>
                                    Nous ne vous imposons pas de minima de production annuelle : si votre dossier est retenu et que vous devenez <b>‘‘partenaire de BSI’’</b>, nous comptons sur une relation de long terme, fructueuse et sincère ! <b>Parce qu’un 
                                    client heureux est un client fidèle, pour vous comme pour nous !</b>
                                </p>
                                <p className='justify-text'>
                                    En tant qu’indicateur d’affaires, vous n’avez pas à disposer de stocks de documents ni à vous tenir informé des 
                                    dernières évolutions tarifaires ou changement de garanties proposées par nos différents fournisseurs. 
                                    Quelques informations à nous préciser suffisent à ce que nous ‘‘prenions le relais’’. Dans les plus brefs 
                                    délais nous transmettrons à l’intéressé son ou ses projets tout en vous tenant informé quelle que soit 
                                    la conclusion.
                                </p>
                            </div>
                        </div>

                        {/* <div className="col-lg-4 col-md-12 col-12" data-aos="fade-right" data-aos-once="false">
                            <div className="image text-center">
                                <img src="assets/img/assurance-cote1.jpg" height={400} alt="image"/>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>

        <section className="insurance-details-area ptb-60 bg-black-color">
            <div className="container" data-aos="fade-up" data-aos-once="true">
                <div className="insurance-details-header">
                    <div className="row align-items-center">
                        <div className="section-title-left">
                           <h2>UN SERVICE EXCEPTIONNEL</h2>
                           <p>Car vos clients sont exceptionnels, nous nous devons de l'être aussi !</p>
                        </div>
                        <div className="col-lg-4 col-md-12 col-12" data-aos="fade-left" data-aos-once="false">
                            <div className="image text-center">
                                <img src="assets/img/service_exeptionnel.webp" height={400} alt="image"/>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-12 col-12" data-aos="fade-right" data-aos-once="false">
                            <div className="content">
                                <p className='justify-text'>
                                    <b>Quand votre client ne trouve pas localement une solution satisfaisante</b> en matière d’assurance santé internationale, 
                                    au 1er euro ou en ‘‘complément à la CFE’’ (ou l’OSSOM), pour lui, ses proches ou ses collaborateurs : nous sommes présents. <b>Et si un client est important pour vous, il le sera pour nous aussi !</b>
                                </p>
                                <p className='justify-text'>
                                    Parce que <b>nos clients communs sont exceptionnels</b>, nous ferons tout notre possible pour leur apporter la solution 
                                    adaptée à leurs attentes et leur profil, dans la transparence et les meilleurs délais.
                                </p>
                                <p className='justify-text'>
                                    La souscription d’un contrat santé international, par exemple, est un moment important : le futur assuré doit 
                                    comprendre la logique de l’assureur par rapport aux pathologies préexistantes et compléter un “questionnaire médical“. 
                                    C’est notre rôle de l’accompagner dans ces démarches et de lui expliquer comment fonctionnera son futur contrat, si 
                                    son dossier est accepté et qu’il confirme son adhésion.
                                </p>
                                <p className='justify-text'>
                                    Pendant toutes ces étapes, <b>nous vous tiendrons informé</b>, comme votre client. Notre structure est à 
                                    taille humaine, très réactive et facilement joignable.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="insurance-details-area mt-60 ptb-60">
            <div className="container" data-aos="fade-up" data-aos-once="true">
                <div className="insurance-details-header">
                    <div className="row align-items-center">
                        <div className="section-title-left">
                            <h2>"QUEL APRÈS VENTE ?”</h2>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="content">
                                <p className='justify-text'>
                                    Le <b>service 24/24</b> est assuré par les assisteurs et assureurs que nous distribuons.  Les compagnies que nous avons sélectionnées 
                                    disposent en effet de leur plateforme pour prendre en charge les demandes en cas d’urgence (hospitalisation, évacuation sanitaire…) ou 
                                    remboursement des soins a posteriori. Pour vous comme pour nous, c’est <b>la certitude d’un service professionnel partout dans le 
                                    monde, 365 jours par an.</b>
                                </p>
                            </div>
                        </div>

                        {/* <div className="col-lg-4 col-md-12 col-12" data-aos="fade-right" data-aos-once="false">
                            <div className="image text-center">
                                <img src="assets/img/assurance-cote1.jpg" height={400} alt="image"/>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>

        <section className="insurance-details-area ptb-60 bg-black-color">
            <div className="container" data-aos="fade-up" data-aos-once="false">
                <div className="insurance-details-header">
                    <div className="row align-items-center">
                        <div className="section-title-left">
                            <h2>COMMENT CONVAINCRE VOS FUTURS CLIENTS ?</h2>
                            <p>Voici quelques arguments que vous pouvez utiliser !</p>
                        </div>
                        <div className="col-lg-4 col-md-12 col-12" data-aos="fade-left" data-aos-once="false">
                            <div className="image text-center">
                                <img src="assets/img/quelques_arguments.webp" height={400} alt="image"/>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-12 col-12" data-aos="fade-right" data-aos-once="false">
                            <div className="content" style={{color: "#000000"}}>
                                <div className='justify-text'>
                                    <p className=''>
                                        Comme pour tout contrat d’assurance, il faut souscrire avant que l’événement ne se réalise : <b>c’est pendant qu’on est en bonne santé qu’il faut s’assurer !</b>
                                    </p>
                                    <p className=''>
                                        <b>En fonction du budget</b> du ‘‘candidat à l’assurance’’, de son mode de vie et de ses priorités, <b>de nombreuses 
                                        solutions sont envisageables</b> : des solutions ‘‘a minima’’ pour couvrir les cas ‘‘lourds’’ uniquement ou 
                                        l’évacuation sanitaire seule, ou des solutions plus complètes incluant la médecine courante, les soins 
                                        dentaires, la maternité…
                                    </p>
                                    <p>La plupart de nos contrats existent en <b>euro, dollar, livre britannique ou franc suisse.</b></p>
                                    <p>Les assurés ont le <b>libre choix des prestataires médicaux dans le monde</b> en cas d’accident ou maladie.</p>
                                    <p><b>Assistance 24/24</b> ‘‘monde entier’’ illimitée.</p>
                                    <p>
                                        Les assurés se soumettent au <b></b>droit Européen en matière d’assurances : <b>ils bénéficient d’une des meilleures 
                                        protections du consommateur dans le monde !</b> Par exemple la plupart de nos contrats offrent une <b>‘‘garantie viagère’’</b> : 
                                        c’est important ! Ils ont pour autant toujours le choix de ne pas renouveler à l’échéance et restent libres.
                                    </p>         
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12 col-md-12 col-12 justify-text mt-4'>
                            <p>
                                Paiement à <b>confirmation de l’acceptation</b> par l’assureur : virement bancaire ou CB internationale ou prélèvement 
                                sur compte en France sous conditions.
                            </p>
                            <p>Paiement direct des dépenses en cas d’hospitalisation.</p>
                            <p>BSI propose des <b>services ‘‘complémentaires’’</b> pour ses clients : organisation d’un ‘‘bilan de santé’’ à Paris par exemple…</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="blog-area ptb-60 pb-70">
            <div className="container" data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-once="false">
                    <div className="section-title" data-aos="slide-up">
                        <h2>Envie de nous rejoindre ?</h2>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 text-center" style={{marginTop: "-30px"}}>
                    {
                            alert && <Alert style={{color:'#fff', backgroundColor:'#4AA340'}} variant={alert.success ? 'success' : 'danger'} >
                            { alert.message }
                            </Alert>
                         }
                        <div className="offset-md-3 offset-lg-3 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="contact-form">
                                <form id="contactForm" onSubmit={onSubmit} method="post">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-6" data-aos="fade-left" data-aos-delay="250">
                                            <div className="form-group">
                                                <input type="text" onInput={ (e) => setData({...data,subject:e.target.value})} name="name" id="name" className="form-control" required data-error="Entrer un sujet" placeholder="Sujet"/>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-6" data-aos="fade-left" data-aos-delay="250">
                                            <div className="form-group">
                                                <input type="text" onInput={ (e) => setData({...data,name:e.target.value})} name="name" id="name" className="form-control" required data-error="Entrer votre nom" placeholder="Nom"/>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
        
                                        <div className="col-lg-12 col-md-6" data-aos="fade-left" data-aos-delay="260">
                                            <div className="form-group">
                                                <input type="email" onInput={ (e) => setData({...data,email:e.target.value})} name="email" id="email" className="form-control" required data-error="Entrer votre email" placeholder="Email"/>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
        
                                        <div className="col-lg-12 col-md-12" data-aos="fade-left" data-aos-delay="270">
                                            <div className="form-group">
                                                <input type="text" onInput={ (e) => setData({...data,phone:e.target.value})} name="phone_number" id="phone_number" required data-error="Entrer votre téléphone" className="form-control" placeholder="Tel"/>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
        
                                        <div className="col-lg-12 col-md-12" data-aos="fade-left" data-aos-delay="280">
                                            <div className="form-group">
                                                <textarea name="message" onInput={ (e) => setData({...data,message:e.target.value})} className="form-control" id="message" cols="30" rows="6" required data-error="Saisissez un message" placeholder="Votre Message"></textarea>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
        
                                        <div className="col-lg-12 col-md-12 text-center" data-aos="fade-left" >
                                            <button type="submit" className="default-btn">Soumettre ma demande {button}<span></span></button>
                                            <div id="msgSubmit" className="h3 text-center hidden"></div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <FormBottomText></FormBottomText>
                            
                        </div>
                    </div>
            </div>
        </section>

    </>
  )
}
