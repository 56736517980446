import React from 'react'
import florence from '../../styles/img/florence-90x90.jpg'
import georges from '../../styles/img/Georges-Karam-90x90.jpg'
import melanie from '../../styles/img/mélanie-besancenot-1-90x90.jpg'
import boris from '../../styles/img/boris-90x90.jpg'
import majorelle from '../../styles/img/PHOTOS-MAJORELLE8-90x90.jpg'



export default function TestimonialsSection() {
  return (
    <section className="feedback-area ptb-60">
            <div className="container"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-once="false">
                    <div className="section-title">
                    <h2>Ce qu'en disent nos clients...</h2>
                    <p>Notre meilleure récompense : des assurés satisfaits !</p>
                </div>

                <div className="feedback-slides mtreduce-35">
					<div className="client-feedback">
						<div>
							<div className="item">
								<div className="single-feedback">
									<p>“BSI a su trouver le contrat qui me convenait, signé dans un temps éclair. Vu le  contexte difficile, le processus a été des plus efficace et tranquillisant.”</p>
								</div>
							</div>
							
							<div className="item">
								<div className="single-feedback">
									<p>“Comme expatrié, j'apprécie la disponibilité et l'écoute de l'équipe BSI.”</p>
								</div>
							</div>
							
							<div className="item">
								<div className="single-feedback">
									<p>“J'ai apprécié la clarté des explications de BSI avant de souscrire.”</p>
								</div>
							</div>
							
							<div className="item">
								<div className="single-feedback">
									<p>“J'apprécie la réactivité et le professionnalisme de BSI lors de toutes mes démarches santé. On se sent plus serein lorsque l'on est bien conseillé.”</p>
								</div>
							</div>
							
							<div className="item">
								<div className="single-feedback">
									<p>“Quand je me suis installé à Londres pour étudier, BSI a compris ce qui me fallait et ma famille a été rassurée de me savoir bien couvert.”</p>
								</div>
							</div>
						
                        </div>
                        
                        <button className="prev-arrow slick-arrow">
                            <i className='flaticon-left-chevron'></i>
                        </button>
                        
                        <button className="next-arrow slick-arrow">
                            <i className='flaticon-right-chevron'></i>
                        </button>
                    </div>
                    
                    <div className="client-thumbnails">
						<div>
							<div className="item">
                                <div className="img-fill"><img src={florence} alt="client"/></div>
                                
                                <div className="title">
                                    <h3>Florence Oberholzer</h3>
                                    <span>Kananga en République Démocratique du Congo</span>
                                </div>
							</div>
							
							<div className="item">
								<div className="img-fill"><img src={georges} alt="client"/></div>
                                
                                <div className="title">
                                    <h3>Georges Karam</h3>
                                    <span>Beyrouth au Liban</span>
                                </div>
                            </div>
                            
                            <div className="item">
								<div className="img-fill"><img src={melanie} alt="client"/></div>
                                
                                <div className="title">
                                    <h3>Mélanie Besancenot</h3>
                                    <span>Guangzhou en Chine</span>
                                </div>
							</div>
							
							<div className="item">
								<div className="img-fill"><img src={boris} alt="client"/></div>
                                
                                <div className="title">
                                    <h3>Boris Delmouly</h3>
                                    <span>Antananarivo à Madagascar</span>
                                </div>
                            </div>
							
							<div className="item">
								<div className="img-fill"><img src={majorelle} alt="client"/></div>
                                
                                <div className="title">
                                    <h3>Chadrac Ouedraogo</h3>
                                    <span>Londres au Royaume-Uni</span>
                                </div>
                            </div>
                            
						</div>
                    </div>
				</div>
            </div>
        </section>
  )
}
