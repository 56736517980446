import React from 'react'
import ReactGA from 'react-ga';


export default function ServiceSection() {

    const trakerEventProduit = (event) => {

        ReactGA.event({
            category: 'Produit',
            action: `Produit : ${event.target.innerText}`,
            });

    }
  return (
        <section id="portfolio" className="portfolio ptb-60" >
        <div className="container"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-once="false">
            <div className="section-title">
                <h2>Nos services</h2>
                <p> <b style={{color: "#C14742"}}>Particuliers</b> ou <b style={{color: "#C14742"}}>entreprises</b>, expatriés ou non, découvrez ici nos assurances. Et aussi nos bons plans "assurances voyage" ou "Schengen".</p>
            </div>
  
          <div className="row mtreduce-35" data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
            <div className="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-flters">
                <li data-filter=".filter-card" className="filter-active">Particuliers</li>
                <li data-filter=".filter-web">Entreprises</li>
                <li data-filter=".filter-bsi">Autres services</li>
              </ul>
            </div>
          </div>
  
          <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200" data-aos-once="false">
  
            <div className="col-lg-4 col-md-6 col-6 portfolio-item filter-web" >
                <div className="services-box">
                    <div className="image">
                        <img loading="lazy" src="assets/img/entreprise/petit_format/contrat-collectif.webp"  alt="contrat-collectif"/>
                    </div>

                    <div className="content">
                        <h3><a href="/ContratCollectifPrevoyance" onClick={trakerEventProduit}>Collectifs santé et prévoyance</a></h3>


                        {
                            window.innerWidth < 600 
                            ? '' 
                            : <p>Courtier spécialiste de la protection santé et prévoyance des salariés expatriés ou locaux ...</p>
                        }

                        

                        <a href="/ContratCollectifPrevoyance" className="read-more-btn">Voir plus <i className="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>
  
            <div className="col-lg-4 col-md-6 col-6 portfolio-item filter-web" >
                <div className="services-box">
                    <div className="image">
                        <img loading="lazy" src="assets/img/entreprise/petit_format/audit.webp"  alt="image"/>
                    </div>

                    <div className="content">
                        <h3><a href="/AuditRisquesEntreprises" onClick={trakerEventProduit}>Audit risques entreprises</a></h3>

                        {
                            window.innerWidth < 600 
                            ? '' 
                            : window.innerWidth < 1395
                            ? <p>Nous pouvons convenir d’honoraires pour auditer vos garanties et risques ou collaborer à travers un “mandat d’étude </p>
                            : <p>Nous pouvons convenir d’honoraires pour auditer vos garanties et risques ou collaborer à travers un “mandat d’étude et de placement”.</p>
                        }

                        <a href="/AuditRisquesEntreprises" className="read-more-btn">Voir plus <i className="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 col-6 portfolio-item filter-web" >
                <div className="services-box">
                    <div className="image">
                        <img loading="lazy" src="assets/img/entreprise/petit_format/kidnapping.webp" alt="kidnapping"/>
                    </div>

                    <div className="content">
                        <h3><a href="/Kidnapping" onClick={trakerEventProduit}>Assurance kidnapping</a></h3>

                        {
                            window.innerWidth < 600 
                            ? '' 
                            : <p>Des formules d’assurance “crise et sécurité” pour les voyageurs ou expatriés y compris dans les “zones à risques”.</p>
                        }

                        <a href="/Kidnapping" className="read-more-btn">Voir plus <i className="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div className='row'>

                <div className="offset-md-2 col-lg-4 col-md-6 col-6 portfolio-item filter-card" >
                    <div className="services-box">
                        <div className="image">
                            <img loading="lazy" src="assets/img/particulier/petit_format/expatries.webp"  alt="expatries"/>
                        </div>

                        <div className="content">
                            <h3><a href="/Expatries" onClick={trakerEventProduit}>Assurances expatriés</a></h3>

                            {
                                window.innerWidth < 600 
                                ? '' 
                                : window.innerWidth < 1395
                                ? <p>On est considéré comme expatrié quand on vit plus de six mois et un jour par an en dehors de son pays d’origine</p>
                                : <p>On est considéré comme expatrié quand on vit plus de six mois et un jour par an en dehors de son pays d’origine...</p>
                            }

                            <a href="/Expatries" className="read-more-btn mobile-margin-top">Voir plus <i className="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-6 portfolio-item filter-card">
                    <div className="services-box">
                        <div className="image">
                            <img loading="lazy" src="assets/img/particulier/petit_format/pas-expatries.webp" alt="Pas-Expatries"/>
                        </div>

                        <div className="content">
                            <h3><a href="/Pas-Expatries" onClick={trakerEventProduit}>Assurances non expatriés</a></h3>

                            {
                                window.innerWidth < 600 
                                ? '' 
                                : window.innerWidth < 1395
                                ? <p>Découvrez nos plans de santé internationaux «1er euro» ...</p>
                                : <p>Découvrez nos plans de santé internationaux «1er euro» pour les personnes de toutes nationalités du monde entier.</p>
                            }

                            <a href="/Pas-Expatries" className="read-more-btn">Voir plus <i className="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>

            </div>

            

            <div className='row'>
                <div className="offset-md-2 col-lg-4 col-md-6 col-6 portfolio-item filter-card" >
                    <div className="services-box">
                        <div className="image">
                            <img loading="lazy" src="assets/img/particulier/petit_format/etudiants.webp" alt="etudiants"/>
                        </div>

                        <div className="content">
                            <h3><a href="/GlobeTrotters" onClick={trakerEventProduit}>Globe-trotters et étudiants</a></h3>

                            {
                                window.innerWidth < 600 
                                ? '' 
                                : <p>Des assurances adaptées aux budgets étudiants avec notre sélection de bons plans pour vous accompagner ...</p>
                            }

                            <a href="/GlobeTrotters" className="read-more-btn">Voir plus <i className="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
  
                <div className="col-lg-4 col-md-6 col-6 portfolio-item filter-card">
                    <div className="services-box">
                        <div className="image">
                            <img loading="lazy" src="assets/img/particulier/petit_format/schengen-voyage.webp" alt="schengen-voyage"/>
                        </div>

                        <div className="content">
                            <h3><a href="/Schengen" onClick={trakerEventProduit}>Assurances Schengen</a></h3>

                            {
                                window.innerWidth < 600 
                                ? '' 
                                : window.innerWidth < 1395
                                ? <p>Nos informations sur l’espace Schengen et les meilleures “assurances obligatoires” pour l’obtention du visa. </p>
                                : <p>Nos informations sur l’espace Schengen et les meilleures “assurances obligatoires” pour l’obtention du visa. Souscription ...</p>
                            }

                            <a href="/Schengen" className="read-more-btn mobile-margin-top">Voir plus <i className="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 col-6 portfolio-item filter-bsi" >
                <div className="services-box">
                    <div className="image">
                        <img loading="lazy" src="assets/img/entreprise/petit_format/conseil-cima.webp" alt="conseil-cima"/>
                    </div>

                    <div className="content">
                        <h3><a href="/ConseilCima" onClick={trakerEventProduit}>Conseil CIMA</a></h3>

                        {
                            window.innerWidth < 600 
                            ? ''
                            : <p>Nous connaissons particulièrement bien la zone CIMA où nous sommes également agréés à travers notre cabinet à Cotonou (Bénin).</p>
                        }

                        <a href="/ConseilCima" className="read-more-btn">Voir plus <i className="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 col-6 portfolio-item filter-bsi">
                <div className="services-box">
                    <div className="image">
                        <img loading="lazy" src="assets/img/Logo-ebsi.webp" alt="Logo-ebsi"/>
                    </div>

                    <div className="content">
                        <h3><a href="/E-bsi" onClick={trakerEventProduit}>Assurtech</a></h3>

                        {
                            window.innerWidth < 600 
                            ? ''
                            : <p>E-BSI est notre “assurtech” dédiée à la transformation digitale des assureurs : E-Business System for Insurance.</p>
                        }

                        <a href="/E-bsi" className="read-more-btn">Voir plus <i className="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 col-6 portfolio-item filter-bsi">
                <div className="services-box">
                    <div className="image">
                        <img loading="lazy" src="assets/img/logo-bsi-immobilier.webp" alt="logo-bsi-immobilier"/>
                    </div>

                    <div className="content">
                        <h3><a href="/Bsimmobilier" onClick={trakerEventProduit}>Bsi Immobilier</a></h3>

                        {
                            window.innerWidth < 600 
                            ? ''
                            : <p>Notre département qui traite de tout ce qui a trait a l'Immobilier pour un accompagnement exeptionnel dans vos investissements.</p>
                        }

                        <a href="#" className="read-more-btn">Voir plus <i className="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>
  
          </div>
  
        </div>
        </section>
  )
}
