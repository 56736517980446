import React from 'react'

export default function Blog() {
  return (
    <>

        <div className="page-title-area page-title-blog">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Blog & News</h2>                 
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <section className="blog-area ptb-60">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="post-image">
                                <a href="#"><img src="assets/img/coronavirus.jpg" alt="image"/></a>

                                <div className="date"><i className="flaticon-timetable"></i> 05.02.2020</div>
                            </div>

                            <div className="post-content">
                                <h3><a href="#">Autour du Coronavirus</a></h3>
                                <p>
                                    L’avis d’un expert : Selon le Professeur Christian D’Enfert : « On a des niveaux de 2 à 3 % de 
                                    mortalité chez les personnes qui présentent des symptômes sévères, explique le directeur de 
                                    l’Unité Biologie et Pathogénicité fongiques de l’Institut Pasteur. Cela reste un taux de ...
                                </p>

                                <a href="#" className="default-btn">Lire plus <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="post-image">
                                <a href="#"><img src="assets/img/brokerslink.jpg" alt="image"/></a>

                                <div className="date"><i className="flaticon-timetable"></i> 01.12.2021</div>
                            </div>

                            <div className="post-content">
                                <h3><a href="#">A Cotonou, François Masson rejoint le réseau Brokerslink</a></h3>
                                <p>
                                    François Masson, associé de BSI mais également du cabinet Africa Brokers’ Company au Bénin,
                                     rejoint le réseau mondial de courtiers en assurances Brokerslink en tant que nouvel affilié.
                                    Africa Brokers’ Company est une ...
                                </p>

                                <a href="#" className="default-btn">Lire plus <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="post-image">
                                <a href="#"><img src="assets/img/sportsanté.jpg" alt="image"/></a>

                                <div className="date"><i className="flaticon-timetable"></i> Sep 13, 2021</div>
                            </div>

                            <div className="post-content">
                                <h3><a href="#">ASSUREZ-VOUS ET FAÎTES DU SPORT !</a></h3>
                                <p>METTEZ TOUTES LES CHANCES DE VOTRE CÔTÉ : ASSUREZ-VOUS ET FAÎTES DU SPORT ! 
                                    L’hygiène de vie est primordiale pour votre santé et le sport devrait  – quel que soit votre âge
                                     – en faire partie. Le sport-santé a des vertus sociales : 
                                    vivre ensemble, tolérance ...
                                </p>

                                <a href="#" className="default-btn">Lire plus <span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </>
  )
}
