import React, { useContext, useEffect, useState } from 'react';
import {Form,Col,Alert} from 'react-bootstrap';
import Select from 'react-select'


export default function QuoteBoxSection() {

    const [query, setQuery] = useState([]);
    const[data, setData] = useState([]);
    const [alert, setAlert] = useState(null);


    const onSubmit = (e) => {
        e.preventDefault();
        if(typeof query !== 'undefined' && query.length > 0 ) {
            return  window.location.assign(`/Demandededevis/${query}`);
        }else{
            alert('Veuillez choisir ...')
        }
    }
   
    const getQuery = (e) => {
        setQuery(e.value);
    }

    const options = [

        { value: "Sélectionner un profil ...", label: "Sélectionner un profil ..." },
        { value: "particuliers", label: "PARTICULIER" },
        { value: "entreprises", label: "ENTREPRISE" }

    ]

  return (
        <section className="quote-boxes-area">
            <div className="container" data-aos="fade-in" data-aos-once="false">
                <div className="quote-boxes-content">
                    <h2>Demandez votre devis</h2>

                    <form onSubmit={onSubmit}>
                        <div className="row align-items-center mb-2">
                            <div style={{ zIndex: '1000'}} className="offset-lg-1 col-lg-7 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <Select className="form-group"
                                        placeholder="Sélectionner un profil ..."
                                        options={options}
                                        defaultValue={options[0]}
                                        name="query"
                                        onChange={getQuery}
                                    />
                                </div>
                            </div>
                            <div  className="col-lg-3 col-md-6">
                                <button type="submit" className="default-btn"><i className="fa-solid fa-chart-column fa-1x">&nbsp;&nbsp;</i> Devis <span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>

  )
}
