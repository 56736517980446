import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const apiURL = 'http://localhost:3001/api';

/**
 *
 * @param {string} entity_url
 */
  const getEntity = (entity_url) => {
    return axios.get(apiURL + entity_url, {headers: {'Content-Type': 'application/json'}})
  }


  /**
 *
 * @param {string} entity_url
 * @param {number} id
 */

  const getEntityById = (entity_url, id) => {
    return axios.get(apiURL + entity_url + '/' + id, {headers: {'Content-Type': 'application/json'}}) 
  }



/**
 *
 * @param {string} entity_url - Url for API
 * @param {object} data - Data
 * @param {object} headers
 */

  const postEntity = (entity_url, data, headers ) => {
    return axios.post(apiURL + entity_url, data, {headers: {'Content-Type': 'application/json'}})
  }

  export {postEntity, getEntityById, getEntity};