import React from 'react'

export default function Politiquevieprivee() {
  return (
    <>

        <div className="page-title-area page-title-bg-politiques-vie">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Politique vie privée</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="blog-area ptb-60 pb-70">
            <div className="container" data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-once="false">
                    <div className="col-lg-12 col-md-12 col-sm-12" style={{marginTop: "-30px"}}>
                        <ol className='politiqueCookiesListe' type="I">

                            <li>
                                <h4 className='mt-4'>Objet</h4>
                                <p className='justify-text'>
                                La présente Politique est établie par BILAN SANTÉ INTERNATIONAL SARL, immatriculée au RCS de Paris sous le numéro 519 688 550 dont le siège social est situé au 27 boulevard Saint-Martin, 75003 Paris ; 
                                (ci-après dénommé(e) « le responsable du traitement »).

                                </p>
                                <p className='justify-text'>
                                    L’objet de la présente Politique est d’informer les visiteurs du site web hébergé à l’adresse suivante :   
                                    <a href='/'>www.bilansanteinternational.com</a> (ci-après dénommé le « site web ») de la manière dont les données sont 
                                    récoltées et traitées par le responsable du traitement.
                                </p>

                                <p className='justify-text'>
                                    La présente Politique s’inscrit dans le souhait du responsable du traitement, d’agir en toute transparence, dans le respect 
                                    de ses dispositions nationales, comme la loi n° 2018-493 du 20 juin 2018, promulguée le 21 juin 2018, modifiant la loi Informatique 
                                    et Libertés afin de mettre en conformité le droit national avec le cadre juridique européen, et du règlement (UE) 2016/679 du 
                                    Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des 
                                    données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE 
                                    (ci-après dénommé le « règlement général sur la protection des données »).
                                </p>

                                <p className='justify-text'>
                                    Le responsable du traitement porte une attention particulière à la protection de la vie privée de ses utilisateurs et s’engage par 
                                    conséquent à prendre les précautions raisonnables requises pour protéger les données à caractère personnel récoltées contre la perte, 
                                    le vol, la divulgation ou l’utilisation non autorisée.
                                </p>

                                <p className='justify-text'>
                                    Les « données à caractère personnel » sont définies comme toutes les données à caractère personnel qui concernent l’utilisateur, 
                                    c’est-à-dire toute information qui permet de l’identifier directement ou indirectement en tant que personne physique. 
                                </p>

                                <p className='justify-text'>
                                    Si l’utilisateur souhaite réagir à l'une des pratiques décrites ci-après, il peut contacter le responsable de traitement à 
                                    l'adresse postale ou à l'adresse email précisées au point "données de contact" de la présente Politique. 
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Quelles données collectons-nous ?</h4>
                                <p className='justify-text'>
                                    Le responsable du traitement recueille et traite, selon les modalités et principes décrits ci-après, 
                                    les données à caractère personnel suivantes :
                                    <ul>
                                        <li>son domaine (détecté automatiquement par le serveur du responsable du traitement), y compris l’adresse IP dynamique ;</li>
                                        <li>son adresse de courrier électronique si l’utilisateur l’a précédemment révélée, par exemple en envoyant des messages 
                                            ou questions sur le site web, en communiquant avec le responsable du traitement par courrier électronique, en participant 
                                            aux forums de discussion, en accédant à la partie restreinte du site web moyennant identification, etc. ;</li>
                                        <li>l'ensemble de l'information concernant les pages que l’utilisateur a consultées sur le site web ;</li>
                                        <li>toute information que l’utilisateur a donnée volontairement, par exemple dans le cadre d'enquêtes 
                                            d'informations et/ou des inscriptions sur le site web, 
                                            ou en accédant à la partie restreinte du site web moyennant une identification.</li>
                                    </ul>
                                </p>
                                <p className='justify-text'>
                                    Il est possible que le responsable du traitement soit également amené à récolter des données à caractère non personnel. 
                                    Ces données sont qualifiées de données à caractère non personnel car elles ne permettent pas d’identifier directement ou 
                                    indirectement une personne en particulier. Elles pourront dès lors être utilisées à quelques fins que ce soit, par exemple 
                                    pour améliorer le site web, les produits et services proposés ou les publicités du responsable du traitement.
                                </p>
                                <p className='justify-text'>
                                    Dans l’hypothèse où des données à caractère non personnel seraient combinées à des données à caractère personnel, de sorte qu’une 
                                    identification des personnes concernées serait possible, ces données seront traitées comme des données à caractère personnel jusqu’à 
                                    ce que leur rapprochement avec une personne particulière soit rendu impossible.
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Méthodes de collecte</h4>
                                <p className='justify-text'>
                                    Le responsable du traitement récolte les données à caractère personnel de la manière suivante :
                                    <ul>
                                        <li>Formulaire de contact </li>
                                        <li>Formulaire de demande de devis</li>
                                        <li>Inscription Newsletter</li>
                                        <li>Espace clients</li>
                                        <li>e-shop</li>
                                    </ul>
                                </p>

                            </li>

                            <li>
                                <h4 className='mt-4'>Finalités du traitement</h4>
                                <p className='justify-text'>
                                    Les données à caractère personnel ne sont recueillies et traitées qu’aux seules fins mentionnées ci-dessous :
                                    <ul>
                                        <li>assurer la gestion et le contrôle de l’exécution des services proposés ;</li>
                                        <li>envoi et suivi des commandes et facturations ;</li>
                                        <li>envoi d’informations promotionnelles sur les produits et services du responsable du traitement ;</li>
                                        <li>envoi de matériel promotionnel ;</li>
                                        <li>répondre aux questions de l’utilisateur ;</li>
                                        <li>réaliser des statistiques ;</li>
                                        <li>améliorer la qualité du site web et des produits et/ou services proposés par le responsable du traitement ;</li>
                                        <li>transmettre des informations sur des nouveaux produits et/ou services du responsable du traitement ;</li>
                                        <li>en vue d’actions de prospection commerciale ;</li>
                                        <li>permettre une meilleure identification des centres d’intérêt de l’utilisateur.</li>
                                    </ul>
                                </p>
                                <p className='justify-text'>
                                    Nous collectons et traitons également les données personnelles aux fins suivantes :
                                    <ul>
                                        <li>transfert de données à un partenaire commercial ;</li>
                                        <li>transfert de données à une filiale ou membre du groupe auquel le responsable de traitement est lié ;</li>
                                        <li>transfert de données en dehors de l'EU dans le cadre d'un contrat de sous-traitance encadré par des clauses contractuelles conformes.</li>
                                    </ul>
                                </p>
                                <p className='justify-text'>
                                    Le responsable du traitement pourrait être amené à effectuer des traitements qui ne sont pas encore prévus dans la présente Politique. 
                                    Dans ce cas, il contactera l’utilisateur avant de réutiliser ses données à caractère personnel, afin de lui faire connaître les 
                                    changements et lui donner la possibilité, le cas échéant, de refuser cette réutilisation.
                                </p>
     
                            </li>

                            <li>
                                <h4 className='mt-4'>Intérêts légitimes</h4>
                                <p className='justify-text'>
                                    Certains des traitements effectués par le responsable de traitement sont fondés sur la base légale des intérêts légitimes de celui-ci. 
                                    Ces intérêts légitimes sont proportionnés par rapport au respect des droits et libertés de l’utilisateur. 
                                    Si l’utilisateur souhaite être informé du détail des finalités fondées sur la base légale des intérêts légitimes, 
                                    il lui est recommandé de prendre contact avec le responsable de traitement (voir point relatif aux « données de contact »).
                                </p> 
                            </li>

                            <li>
                                <h4 className='mt-4'>Durée de conservation</h4>
                                <p className='justify-text'>
                                    De manière générale, le responsable du traitement ne conserve les données à caractère personnel que pendant le temps raisonnablement 
                                    nécessaire aux finalités poursuivies et en accord avec les exigences légales et réglementaires. 
                                </p>
                                <p className='justify-text'>
                                    Les données à caractère personnel d’un client sont conservées au maximum 10 ans après la fin de la relation 
                                    contractuelle qui lie ce client au responsable du traitement.
                                </p>
                                <p className='justify-text'>
                                    Au terme de l’écoulement de la période de conservation, le responsable du traitement met tout en œuvre pour s’assurer que les 
                                    données personnelles ont bien été rendues indisponibles et inaccessible.
                                </p>
                                
                            </li>

                            <li> 
                                <h4 className='mt-4'>Application des droits</h4>
                                <p className='justify-text'>
                                    Pour tous les droits repris ci-après, le responsable de traitement se réserve le droit de vérifier l’identité de l’utilisateur 
                                    pour l’application des droits repris ci-après.  
                                </p>
                                <p className='justify-text'>
                                    Cette demande d’information supplémentaire se fera dans un délai d’un mois à partir de l’introduction de la demande par l’utilisateur.
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Contact</h4>
                                <p className='justify-text'>
                                    L’utilisateur peut obtenir gratuitement la communication écrite ou une copie des données à caractère personnel le 
                                    concernant qui ont été recueillies. 
                                </p>
                                <p className='justify-text'>
                                    Le responsable du traitement peut exiger le paiement de frais raisonnables basés sur les coûts administratifs pour toute 
                                    copie supplémentaire demandée par l’utilisateur. Lorsque l’utilisateur introduit cette demande par voie électronique, les 
                                    informations sont fournies sous une forme électronique d'usage courant, à moins que l’utilisateur ne demande qu'il en soit autrement.
                                </p>
                                <p className='justify-text'>
                                    Sauf exception prévue par le règlement général sur la protection des données, la copie de ses données sera communiquée à 
                                    l’utilisateur au plus tard dans le mois après la réception de la demande.
                                </p>
                            </li>

                            <li className='mt-4'>
                                <h4>Droit de rectification</h4>
                                <p className='justify-text'>
                                    L’utilisateur peut obtenir gratuitement, dans les meilleurs délais et au plus tard dans un délai d’un mois, la rectification de ses 
                                    données à caractère personnel qui seraient inexactes, incomplètes ou non pertinentes, ainsi que les compléter si elles s’avèrent 
                                    incomplètes.  
                                </p>
                                <p className='justify-text'>
                                    Sauf exception prévue par le règlement général sur la protection des données, la demande d'application du droit à la rectification 
                                    est traitée dans le mois de l'introduction de celle-ci.  
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Droit de s’opposer au traitement</h4>
                                <p className='justify-text'>
                                    L’utilisateur peut à tout moment, pour des raisons tenant à sa situation particulière, s’opposer gratuitement au traitement 
                                    de ses données à caractère personnel, lorsque :
                                    <ul>
                                        <li>le traitement est nécessaire à l'exécution d'une mission d'intérêt public ou relevant de l'exercice de l'autorité 
                                            publique dont serait investi le responsable du traitement ;
                                        </li>
                                        <li>
                                            le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le responsable du traitement ou par un tiers, 
                                            à moins que ne prévalent les intérêts ou les libertés et droits fondamentaux de la personne concernée qui exigent une 
                                            protection des données à caractère personnel (notamment lorsque la personne concernée est un enfant).
                                        </li>
                                    </ul>  
                                </p>
                                <p className='justify-text'>
                                    Le responsable du traitement pourra refuser de mettre en œuvre le droit d’opposition de l’utilisateur lorsqu’il établit l'existence 
                                    de motifs impérieux et légitimes justifiant le traitement, qui priment les intérêts ou les droits et les libertés de l’utilisateur, 
                                    ou pour la constatation, l'exercice ou la défense d'un droit en justice. En cas de contestation, l’utilisateur peut introduire 
                                    un recours conformément au point « réclamation et plainte » de la présente Politique.
                                </p>
                                <p className='justify-text'>
                                    L’utilisateur peut également, à tout moment, s’opposer, sans justification et gratuitement, au traitement des données à caractère 
                                    personnel le concernant lorsque ses données sont collectées à des fins de prospection commerciale (y compris un profilage).
                                </p>
                                <p className='justify-text'>
                                    Lorsque des données à caractère personnel sont traitées à des fins de recherche scientifique ou historique ou à des fins statistiques 
                                    conformément au règlement général sur la protection des données, l’utilisateur a le droit de s'opposer, pour des raisons tenant à 
                                    sa situation particulière, au traitement de données à caractère personnel le concernant, à moins que le traitement ne soit nécessaire 
                                    à l'exécution d'une mission d'intérêt public.
                                </p>
                                <p className='justify-text'>
                                    Sauf exception prévue par le règlement général sur la protection des données, le responsable du traitement est tenu de répondre à 
                                    la demande de l’utilisateur dans les meilleurs délais et au plus tard dans un délai d’un mois et de motiver sa réponse lorsqu’il a 
                                    l’intention de ne pas donner suite à une telle demande.
                                </p>
                            </li>

                            <li className='mt-4'>
                                <h4>Droit à la limitation du traitement</h4>
                                <p className='justify-text'>
                                    L’utilisateur peut obtenir la limitation du traitement de ses données à caractère personnel dans les cas énumérés ci-après : 
                                    <ul>
                                        <li>
                                            lorsque l’utilisateur conteste l’exactitude d’une donnée et uniquement le temps 
                                            que le responsable du traitement puisse contrôler celle-ci ;
                                        </li>
                                        <li>lorsque le traitement est illicite et que l’utilisateur préfère la limitation du traitement à l’effacement ;</li>
                                        <li>lorsque, bien que n’étant plus nécessaire à la poursuite des finalités du traitement, l’utilisateur en a besoin pour 
                                            la constatation, l'exercice ou la défense de ses droits en justice ;</li>
                                        <li>pendant le temps nécessaire à l’examen du caractère fondé d’une demande d’opposition introduite par l’utilisateur, 
                                            en d’autres termes le temps que le responsable du traitement procède à la vérification de la balance des intérêts entre 
                                            les intérêts légitimes du responsable du traitement et ceux de l’utilisateur.</li>
                                    </ul>
                                </p>
                                <p className='justify-text'>
                                    Le responsable du traitement informera l’utilisateur lorsque la limitation du traitement est levée.  
                                </p>
                            </li>

                            <li className='mt-4'>
                                <h4>Droit à l’effacement (droit à l’oubli)</h4>
                                <p className='justify-text'>
                                    L’utilisateur peut obtenir l’effacement des données à caractère personnel le concernant, lorsque l’un des motifs suivants s’applique : 
                                    <ul>
                                        <li>
                                            les données ne sont plus nécessaires au regard des finalités du traitement ;
                                        </li>
                                        <li>l’utilisateur a retiré son consentement à ce que ses données soient traitées et il n'existe pas d'autre fondement juridique au traitement ;</li>
                                        <li>l’utilisateur s'oppose au traitement et il n'existe pas de motif légitime impérieux pour le traitement et/ou l’utilisateur exerce son droit 
                                            spécifique d’opposition en matière de marketing direct (y compris le profilage) ;</li>
                                        <li>les données à caractère personnel ont fait l'objet d'un traitement illicite ;</li>
                                        <li>les données à caractère personnel doivent être effacées pour respecter une obligation légale 
                                            (du droit de l'Union ou du droit de l'État membre) auquel le responsable du traitement est soumis ;</li>
                                        <li>les données à caractère personnel ont été collectées dans le cadre de l'offre de services de la société de 
                                            l'information qui s’adressent aux enfants.</li>
                                    </ul>
                                </p>
                                <p className='justify-text'>
                                    L’effacement des données n’est toutefois pas applicable dans les cas suivants :  
                                    <ul>
                                        <li>
                                            lorsque le traitement est nécessaire à l'exercice du droit à la liberté d'expression et d'information ;
                                        </li>
                                        <li>lorsque le traitement est nécessaire pour respecter une obligation légale qui requiert le traitement prévue par 
                                            le droit de l'Union ou par le droit de l'État membre auquel le responsable du traitement est soumis, ou pour exécuter 
                                            une mission d'intérêt public ou relevant de l'exercice de l'autorité publique dont serait investi le responsable ;
                                        </li>
                                        <li>
                                            lorsque le traitement est nécessaire pour des motifs d'intérêt public dans le domaine de la santé publique ;
                                        </li>
                                        <li>
                                            lorsque le traitement est nécessaire à des fins archivistiques dans l'intérêt public, à des fins de recherche 
                                            scientifique ou historique ou à des fins statistiques et pour autant que le droit à l’effacement est susceptible 
                                            de rendre impossible ou de compromettre gravement la réalisation des objectifs du traitement en question ;
                                        </li>
                                        <li>lorsque le traitement est nécessaire à la constatation, à l'exercice ou à la défense de droits en justice.</li>
                                     
                                    </ul>
                                </p>
                                <p className='justify-text'>
                                    Sauf exception prévue par le règlement général sur la protection des données, le responsable du traitement est tenu de 
                                    répondre à la demande de l’utilisateur dans les meilleurs délais et au plus tard dans un délai d’un mois et de motiver 
                                    sa réponse lorsqu’il a l’intention de ne pas donner suite à une telle demande.
                                </p>
                            </li>

                            <li className='mt-4'>
                                <h4>Droit à la « portabilité des données »</h4>
                                <p className='justify-text'>
                                L'utilisateur peut à tout moment, demander de recevoir gratuitement ses données à caractère personnel dans un format structuré, 
                                couramment utilisé et lisible par machine, en vue notamment de les transmettre à un autre responsable du traitement, lorsque : 
                                    <ul>
                                        <li>
                                        	le traitement de données est effectué à l’aide de procédés automatisés ;
                                        </li>
                                        <li>le traitement est fondé sur le consentement de l’utilisateur ou sur un contrat conclu entre ce dernier et le responsable du traitement.</li>  
                                    </ul>
                                </p>
                                <p className='justify-text'>
                                    Dans les mêmes conditions et selon les mêmes modalités, l’utilisateur a le droit d’obtenir du responsable du traitement 
                                    que les données à caractère personnel le concernant soient transmises directement à un autre responsable du traitement 
                                    de données à caractère personnel, pour autant que cela soit techniquement possible. 
                                </p>
                                <p className='justify-text'>
                                    Le droit à la portabilité des données ne s'applique pas au traitement qui est nécessaire à l'exécution d'une mission 
                                    d'intérêt public ou relevant de l'exercice de l'autorité publique dont serait investi le responsable du traitement.  
                                </p>
                            </li>

                            <li className='mt-4'>
                                <h4>Destinataires des données et divulgation à des tiers</h4>
                                <p className='justify-text'>
                                    Les destinataires des données récoltées et traitées sont, outre le responsable du traitement lui-même, 
                                    ses préposés ou autre sous-traitants, ses partenaires commerciaux sélectionnés avec soin, situés en France 
                                    ou dans l’Union européenne, et qui collaborent avec le responsable du traitement dans le cadre de la 
                                    commercialisation de produits ou de la fourniture de services.
                                </p>
                                <p className='justify-text'>
                                    Dans l’hypothèse où les données seraient divulguées à des tiers à des fins de marketing direct ou de prospection commerciale, 
                                    l’utilisateur en sera préalablement informé de manière à pouvoir choisir d’accepter le transfert de ses données à des tiers.  
                                </p>
                                <p className='justify-text'>
                                    Dès lors que ce transfert est basé sur le consentement de l’utilisateur, celui-ci peut, à tout moment, retirer 
                                    son consentement pour cette finalité précise.  
                                </p>
                                <p className='justify-text'>
                                    Le responsable du traitement respecte les dispositions légales et réglementaires en vigueur et veillera dans 
                                    tous les cas à ce que ses partenaires, préposés, sous-traitants ou autres tiers ayant accès à ces données à caractère 
                                    personnelles respectent la présente Politique.  
                                </p>
                                <p className='justify-text'>
                                    Le responsable de traitement divulgue les données à caractère personnel de l’utilisateur dans l’hypothèse où une loi, 
                                    une procédure judiciaire ou un ordre d’une autorité publique rendrait cette divulgation nécessaire.  
                                </p>
                                <p className='justify-text'>
                                    Aucun transfert de données à caractère personnel hors de l’Union européenne n’est effectué par le responsable du traitement. 
                                </p>
                            </li>

                            <li className='mt-4'>
                                <h4>Sécurité</h4>
                                <p className='justify-text'>
                                    Le responsable du traitement met en œuvre les mesures techniques et organisationnelles appropriées afin de garantir un niveau 
                                    de sécurité du traitement et des données récoltées au regard des risques présentés par le traitement et de la nature des données 
                                    à protéger adapté au risque. Il tient compte de l'état des connaissances, des coûts de mise en œuvre et de la nature, de la portée, 
                                    du contexte et des finalités du traitement ainsi que des risques pour les droits et libertés des utilisateurs.
                                </p>
                                <p className='justify-text'>
                                    Le responsable du traitement utilise toujours les technologies de cryptage qui sont reconnues comme les standards industriels 
                                    au sein du secteur IT quand il transfert ou reçoit les données sur le site web. 
                                </p>
                                <p className='justify-text'>
                                    Le responsable du traitement a mis en place des mesures de sécurité appropriées pour protéger et éviter la perte, l’usage 
                                    abusif ou l’altération des informations reçues sur le site web.  
                                </p>
                                <p className='justify-text'>
                                    Dans le cas où les données à caractère personnel que le responsable de traitement contrôle devaient être compromises, 
                                    il agira rapidement afin d’identifier la cause de cette violation et prendre les mesures de remédiation adéquates.  
                                </p>
                                <p className='justify-text'>
                                    Le responsable de traitement informe l’utilisateur de cet incident si la loi l’y oblige.  
                                </p>
                            </li>

                            <li className='mt-4'>
                                <h4>Réclamation et plainte</h4>
                                <p className='justify-text'>
                                    Si l’utilisateur souhaite réagir à l'une des pratiques décrites dans la présente Politique, 
                                    il est conseillé de contacter directement le responsable de traitement.
                                </p>
                                <p className='justify-text'>
                                    L’utilisateur peut également introduire une réclamation auprès de son autorité nationale de contrôle, 
                                    dont les coordonnées sont reprise sur le site officiel de la Commission européenne :  
                                    <a href='http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080'>http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080</a>. 
                                </p>
                                <p className='justify-text'>
                                    En outre, l’utilisateur a la possibilité de porter plainte devant les juridictions nationales compétentes :  
                                    <ul>
                                        <li>Sur le site internet de la CNIL :
                                            <ul>
                                                <li>dans certains cas déterminés, par le téléservice de plainte en ligne ;</li>
                                                <li>dans les autres cas non prévus par le téléservice, par le service "Besoin d'aide".</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Par courrier postal en écrivant à : CNIL - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.
                                        </li>
                                    </ul>
                                </p>

                            </li>

                            <li className='mt-4'>
                                <h4>Données de contact</h4>
                                <p className='justify-text'>
                                Pour toute question et/ou réclamation, relatives à la présente Politique, l’utilisateur peut contacter le responsable du traitement : 
                                    <ul>
                                        <li>Par email : <a href='mailto:info@bilansanteinternational.com'>info@bilansanteinternational.com</a>
                                        </li>
                                        <li>Par courrier : 27 boulevard Saint-Martin, 75003 Paris - France</li>  
                                    </ul>
                                </p>
                            </li>

                            <li className='mt-4'>
                                <h4>Modification</h4>
                                <p className='justify-text'>
                                    Le responsable du traitement se réserve le droit de modifier à tout moment les dispositions de la présente Politique. 
                                    Les modifications seront publiées directement sur le site web du responsable de traitement.
                                </p>
                            </li>

                            <li className='mt-4'>
                                <h4>Droit applicable et juridiction compétente</h4>
                                <p className='justify-text'>
                                    La présente Politique est régie par le droit national du lieu d'établissement principal du responsable du traitement.
                                </p>

                                <p className='justify-text'>
                                    Tout litige relatif à l’interprétation ou l’exécution de la présente Politique sera soumis aux juridictions de ce droit national.
                                </p>

                                <p className='justify-text'>
                                    La présente version de la Politique date du 01/08/2022.
                                </p>
                            </li>

                        </ol>

                    </div>
            </div>
        </section>


    </>
  )
}
