import React from 'react'

export default function GlobeTrotters() {
  return (
    <>
        <div className="page-title-area page-title-bg-trotters">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Assurances pour globe-trotters et étudiants</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="about-area ptb-60 bg-f8f8f8">
            <div className="container">

                <div className='row'>
                <div className='col-lg-8 col-md-8 col-12'>
                    <ul>
                            <p className='justify-text' data-aos="fade-up">
                                PVTiste étudiant ou simplement globe-trotter, seul ou en groupe, nous avons LA solution pour vous couvrir à l’étranger !
                            </p>

                            <p className='justify-text' data-aos="fade-up">
                                Les assurances que nous proposons prendront en charge vos frais en cas inopiné d’hospitalisation ou de visite médicale, le rapatriement sanitaire si besoin était, etc.
                            </p>

                            <p className='justify-text' data-aos="fade-up">
                            BSI a sélectionné pour vous les meilleures offres du marché, quel que soit votre profil (pays d’origine, pays de destination, âge, durée de votre séjour, travail ou non envisagé…). 
                            </p>

                            <p className='justify-text' data-aos="fade-up">
                            Attention : ces formules « à durée ferme » ne sont reconductibles qu’au bon vouloir de l’assureur. En cas de pathologie grave survenue pendant votre séjour et risquant d’entraîner des dépenses récurrentes (maladie chronique déclenchée après la souscription), vous risquez de vous retrouver sans couverture à l’échéance. A ne souscrire donc que si l’on connaît, a priori, la durée de son séjour à l’étranger et que l’on retrouve la sécurité sociale au retour. 
                            C’est aussi la raison pour laquelle ces contrats sont relativement économiques car ils ne bénéficient pas d’une garantie viagère.
                            </p>

                            <p className='justify-text' data-aos="fade-up">
                                Bien vérifier également si le contrat que vous envisagez de souscrire vous couvre en cas d’accident du travail, si vous avez l’intention de travailler à l’étranger.
                            </p>

                            <p className='justify-text' data-aos="fade-up">
                                En cas de doute nous sommes là pour vous conseiller mais demandez-nous un devis pour commencer !
                            </p>

                            <p className='justify-text' data-aos="fade-up">
                                Avant de partir, consultez la page de conseils aux voyageurs de France Diplomatie.
                            </p>

                            <p className='justify-text' data-aos="fade-up">
                                Et vous pouvez commencer vos recherches en vous rendant sur les sites de nos principaux partenaires pour effectuer vos simulations.
                            </p>

                            <p className='justify-text'>Demandez-nous votre projet, sans engagement ! La souscription est ensuite 
                                très simple, avec nos conseils en prime !</p>

                        <a href='/Demandededevis/particuliers' data-aos="fade-right"  className="default-btn"><i className="fa-solid fa-chart-column fa-1x fa-flip">&nbsp;&nbsp;</i> Demandez votre devis !<span></span></a>

            
                        <div className='row another-link' data-aos="fade-right" >
                            <h3 className='mt-5'>Vous pourriez être intéressé par les produits suivants :</h3>

                            <div className='col-md-4 mt-2'>
                                <a href="/Expatries"   className=""><b>Assurances expatriés</b> <span></span></a>
                            </div>

                            <div className='col-md-4 mt-2'>
                                <a href="/Pas-expatriés"   className=""><b>Assurances non expatriés</b> <span></span></a>
                            </div>

                            <div className='col-md-4 mt-2'>
                                <a href="/Schengen"   className=""><b>Assurances Schengen</b> <span></span></a>
                            </div>

                        </div>

                    </ul>

                       
 
                </div>
                <div className='col-lg-4 col-md-8 col-12'>
                <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="single-about-box page-particulier">
                                    <img src="assets/img/logo-chapka1.png" width={150} alt=""/>
                                <p className='justify-text'> 
                                    Toutes les solutions de l’un de nos assureurs préférés en fonction de l’objet de votre séjour. Vous trouverez ici différentes formules : dans le doute demandez-nous conseil !

                                </p>
                                <span><a href="https://www.chapkadirect.fr/index.php?action=produit&id=728&app=cd_bilan" target="_blank"><b>En savoir plus</b></a></span>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="single-about-box page-particulier">

                                    <img src="assets/img/partner-image/logo-april-70x70.jpg"  alt=""/>
                                <p className='justify-text'>Assurance « au 1er euro » ou en complément de la Sécurité sociale (ou CFE) pour les voyageurs temporaires (de 15 jours à 12 mois) 
                                    de toutes nationalités à l’étranger. Parfaitement adapté pour les PVTistes, Globe trotters ou travailleurs temporaires, 
                                    conforme pour l’obtention du visa pour les programmes « Vacances – Travail ». Couverture valable dans tous les 
                                    pays visités et lors des retours temporaires dans le pays de nationalité. 2 niveaux de garanties.</p>

                                <span><a href="https://tarif-assurance-expat.april-international.com/convergence/?LI=7F3D7817-F025-4BF2-A7BC-A671E1D72E2D&Produit=MHIV1&Marque=APRIL&ContexteLayout=COBRAND&langue=fr-FR&CO=I56533@APMOB" target="_blank"><b>En savoir plus</b></a></span>

                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-12 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="single-about-box page-particulier">
                               
                                <img src="assets/img/extrapass.png" width={80} alt=""/>

                                <p className='justify-text'>Contrat qui vous couvre partout dans le monde pour une durée d’une semaine à 12 mois.
                                    Lycéen, étudiant, actif ou retraité, que vous partiez dans l’Union européenne ou dans le reste du monde, et quelle que 
                                    soit la durée de votre séjour, Extra Pass est l’assurance qu’il vous faut !</p>

                                <span><a href="https://www.extrapass.fr/bsi/souscription?ipa=26&id_cat=14"><b>En savoir plus</b></a></span>

                            </div>
                        </div>


                        <div className="col-lg-12 col-md-12 col-12 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="single-about-box page-particulier">
                               
                            <img src="assets/img/logo-assurtravel.jpg" width={100} alt=""/>

                                <p className='justify-text'>Vous êtes domicilié en Europe Occidentale et vous partez à l’étranger dans le cadre de vos études, d’un stage, 
                                    d’un séjour au pair, ou dans le cadre d’un Visa Vacances Travail (VVT ou PVT) et / ou Working Holiday (WHV) ? 
                                    Contactez-nous directement pour ce contrat : info@bilansanteinternational.com</p>

                                <span><a href="#"target="_blank"><b>En savoir plus</b></a></span>

                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-12 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="single-about-box page-particulier">
                               
                            <img src="assets/img/logo-acs.gif" width={100} alt=""/>

                                <p className='justify-text'>Contrat d’assurance voyage garanti par Allianz destiné à couvrir : les étudiants, stagiaires, voyageurs, et PVTistes se 
                                    déplaçant en dehors de leur pays d’origine, dans le monde entier.</p>

                                <span><a href="http://www.acs-ami.com/fr/acs/globe?part=bilansante"target="_blank"><b>En savoir plus</b></a></span>

                            </div>
                        </div>
                    </div>
                </div>
                </div>
                
 
            </div>
        </section>
    </>
  )
}
