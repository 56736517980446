import React, {useState,useEffect} from 'react'
import {SelectComponentMulti} from "../../common/SelectComponent"
import CurrentDate from '../../common/getCurrentDate'
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { setCouvertureExistante, setCouvertureSouhaite, setTypeCourverture } from "../../../features/enterpriseForm/enterpriseFormSlice";



export default function Step3({CurrentTitle,Prev,Next,errors,setErrors}) {

  const {typecourverture, courvertureExistante, courvertureSouhaite} = useSelector((state) => state.enterpriseData);
  const dataStep3 = useSelector((state) => state.enterpriseData);
  const dispatch = useDispatch()


  const NextStep = () => {

    if ( typeof typecourverture !== undefined && typecourverture.length !== 0) {

      if (typecourverture.includes("Collectifs santé et prévoyance")) {

        if(courvertureSouhaite && courvertureExistante) {
  
          setErrors({})
          Next(); 
  
          }else {  
  
          for(const v in dataStep3) {
            if(dataStep3[v] === "") {
              errors = ({...errors, [v] : 'required'})
            }
          } 
            setErrors(errors)    
              
          }
        
      }else {
        setErrors({})
        Next(); 
      }

      
    }else {

       setErrors({...errors, typecourverture: 'required'}) 

    }

  }

  const handleChangeZoneCourverture = (e) => {

    if(!e) {
      setErrors({...errors, courvertureSouhaite: 'required'})
    }else {
      setErrors({...errors, courvertureSouhaite: ''})
      dispatch( setCouvertureSouhaite(e))
    }  
    
  }

  const handleChangeTypeCourverture = (e) => {

    
    const typeValue = e.map( m => {
      return m.value;
    })

    if(!typeValue.includes("Collectifs santé et prévoyance")){
      dispatch(setCouvertureExistante(''), setCouvertureSouhaite(''))
    }

    if(typeof e === 'undefined' || e.length === 0) {
      dispatch( setTypeCourverture(typeValue), setCouvertureExistante(''), setCouvertureSouhaite(''))
      setErrors({...errors, typecourverture: 'required'})
    }else {

      if(typeValue.includes("Collectifs santé et prévoyance")) {

        setErrors({...errors, typecourverture: ''})
        dispatch( setTypeCourverture(typeValue))

      }else {

        setErrors({...errors, typecourverture: '', courvertureExistante: 'noMessage', courvertureSouhaite: 'noMessage'})
        dispatch( setTypeCourverture(typeValue), setCouvertureExistante(''), setCouvertureSouhaite(''))

      }

    } 
    
  }

  const handleChangeCourvertureExistante = (e) => {

    if(!e) {
      setErrors({...errors, courvertureExistante: 'required'})
    }else {
      setErrors({...errors, courvertureExistante: ''})
      dispatch( setCouvertureExistante(e))
    }

  }

  const optionCouvertureSouhaite = [

    { value: 'Afrique', label: 'Afrique' },
    { value: 'Europe', label: 'Europe' },
    { value: 'Afrique et Europe', label: 'Afrique et Europe' },
    { value: 'Monde entier sauf USA', label: 'Monde entier sauf USA' },
    { value: 'Monde entier', label: 'Monde entier' }

  ]

  const optionCouvertureExistante = [

    { value: 'Sans couverture', label: 'Sans couverture' },
    { value: 'Sécurité sociale', label: 'Sécurité sociale' },
    { value: 'CFE', label: 'CFE' },
    { value: 'Autre organisme', label: 'Autre organisme' }
    
  ]

  var defaultTypeCourverture = [];
  
    for (const key in typecourverture) {
      defaultTypeCourverture.push({ value: `${typecourverture[key]}`, label: `${typecourverture[key]}`})
    }
  
  return (
    <>
      <h6 className="text-center">Comment pouvons-nous vous aider ?</h6>
      <div className="row">

      <div style={{zIndex: '7000'}} className="offset-md-1 col-lg-10 col-md-10 col-sm-12">
          <div className="form-group">
            <label for="">Quels sont vos besoins ?<abbr>*</abbr></label>
            <SelectComponentMulti
              handleChangeMulti={(e) => {handleChangeTypeCourverture(e)} } 
              defaultValue={defaultTypeCourverture}
              placeholder={"Type de courverture"}
            >
            </SelectComponentMulti>
            {
                errors.typecourverture === 'required' ?
                <div className="errorStyle">
                  Le champ est obligatoire.
                </div>
                  : errors.typecourverture === ''  
                  ?
                <div className="successStyle">
                  Correct ! 😊
                </div>
                : ''
                }
          </div>
        </div>

        {typecourverture.includes("Collectifs santé et prévoyance") &&

          <>

          <div className="offset-md-1 col-lg-10 col-md-10 col-sm-12" style={{ zIndex: '6000'}}>
          <div className="form-group">
            <label for="inputState">Couverture existante<abbr>*</abbr></label>

            <Select
              defaultValue={courvertureExistante}
              key={courvertureExistante}
              onChange={(e) => handleChangeCourvertureExistante(e.value)}
              options={optionCouvertureExistante}
              id="inputState"
              className="App"
              name="courvertureExistante"
              isSearchable={true}
              placeholder={courvertureExistante ? courvertureExistante : "Sélectionner une zone de courverture existante"}
            />

            {
                errors.courvertureExistante === 'required' ?
                <div className="errorStyle">
                  Le champ est obligatoire.
                </div>
                  : errors.courvertureExistante === ''  
                  ?
                <div className="successStyle">
                  Correct ! 😊
                </div>
                : ''
                }
          </div>
          </div>

          <div className="offset-md-1 col-lg-10 col-md-10 col-sm-12" style={{ zIndex: '5000'}}>
          <div className="form-group">
            <label for="inputState">Zone de couverture souhaitée<abbr>*</abbr></label>


            <Select
              defaultValue={courvertureSouhaite}
              onChange={(e) => handleChangeZoneCourverture(e.value)}
              options={optionCouvertureSouhaite}
              key={courvertureSouhaite}
              id="inputState"
              className="App"
              name="courvertureSouhaite"
              isSearchable={true}
              placeholder={courvertureSouhaite ? courvertureSouhaite : "Sélectionner une zone de courverture"}
            />

            {
                errors.courvertureSouhaite === 'required' ?
                <div className="errorStyle">
                  Le champ est obligatoire.
                </div>
                  : errors.courvertureSouhaite === ''  
                  ?
                <div className="successStyle">
                  Correct ! 😊
                </div>
                : ''
                }
          </div>
          </div>

          </>

        }

        

        <div style={{ color: 'red'}} className='offset-md-1 col-lg-6 col-md-6 col-sm-12 mt-5'>* Champ obligatoire</div>
      </div>

      <div className={`col-lg-12 col-md-12 col-sm-12 form-footer-content-position 
      ${CurrentTitle===3 || CurrentTitle===4 || CurrentTitle===5 ? 'form-footer-margin' : ''}`}>
      
      <button type="button" onClick={Prev}  className="default-btn-annuler">
      Retour </button><span></span>

      <button type="button" onClick={NextStep}  style={{ marginLeft: "10px"}} className="default-btn">
      Suivant</button><span></span>
      <div className="clearfix"></div>
    </div>
    </>
  );
}
