import React from 'react'

export default function Kidnapping() {
  return (
    <>

        <div className="page-title-area page-title-bg-kidnapping">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Assurances kidnapping, crise et sécurité</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="about-area ptb-60 bg-f8f8f8">
            <div className="container">

                <div className='row'>
                <div className='col-lg-12 col-md-12 col-12'>
                    <div>
                            <p data-aos="fade-up" className='justify-text'>
                                <b>Pourquoi souscrire une assurance enlèvement pour vous-même, votre famille ou vos collaborateurs ?</b> <br/>Des entreprises internationales, 
                                des ONG et des particuliers continuent de développer des activités et des échanges dans des zones sujettes à des troubles militaires, 
                                politiques, sociaux, épidémiques, mettant parfois leur vie en danger… L’assurance, bien qu’elle ne puisse pas couvrir 100% des risques 
                                pour la vie humaine, offre une protection individuelle et collective, concentrée dans deux domaines.
                            </p>

                        <h2 data-aos="fade-right" className='mt-5'>Assurance kidnapping et rançon</h2>
                            <p data-aos="fade-up" className='justify-text'>
                                Bien que la plupart des enlèvements ne durent que quelques heures ou quelques jours, certains peuvent durer des semaines, 
                                voire des années… Il peut aussi parfois s’agir d’un cas "simple" d’extorsion : menace d’enlèvement,  menace de mort, chantage, 
                                pirate menaçant de cyberattaque, menace de contamination pour un fabricant…
                            </p>

                            <p data-aos="fade-up" className='justify-text'>
                            Le profil des victimes est variable et la motivation de l’acte est généralement financière. Les multinationales et les expatriés sont 
                            des cibles de choix, de même que les célébrités et leurs proches dans leur pays. L’assurance enlèvement couvrira la rançon en cas 
                            d’enlèvement d’une personne physique ou de tentative d’extorsion.  En amont, l’assureur aura organisé la négociation en employant 
                            des entreprises leaders dans ce type d’intervention. Il s’agit souvent d’anciens militaires reconvertis qui seront sur le terrain 
                            pour que l’affaire aboutisse (en théorie !). Une telle assurance enlèvement couvrira aussi généralement les pertes d’exploitation 
                            d’une entreprise en cas de décès d’un employé, des frais psychiatriques, médicaux ou d’invalidité d’un otage libéré, et même des 
                            frais juridiques éventuels pour l’entreprise. 
                            </p>

                            <p data-aos="fade-up" className='justify-text'>
                            Ce type de contrat est confidentiel et la plupart des grands groupes internationaux souscrivent une assurance en cas d’enlèvement 
                            de leurs employés ou de leurs missionnaires dans le monde entier. Pour couvrir cinquante têtes exposées dans des zones à risque, 
                            la prime annuelle sera inférieure à 2% du montant assuré. Cette garantie est donc importante, mais abordable pour les entreprises 
                            qui envoient des collaborateurs dans des endroits plus dangereux du monde ou qui sont exposées, par leurs activités, à des risques de piratage 
                            et de chantage. Pour des raisons de rapidité, d’efficacité ou simplement de discrétion, un entrepreneur peut préférer cette option aux 
                            voies diplomatiques ou légales, qui peuvent également se dérouler en parallèle.
                            </p>

                        <h2 data-aos="fade-right" className='mt-5'>Assistance en cas de crise et sécurité</h2>

                            <p data-aos="fade-up" className='justify-text'>
                            Cet abonnement a pour but de fournir conseils et logistique aux personnes en danger qui ne sont pas soutenues par un contrat de 
                            rapatriement standard faisant l’objet de nombreuses exclusions. Le contrat de base fonctionne effectivement à la suite d’un accident 
                            ou d’une maladie subite, l’assuré étant évacué si le problème médical ne pouvait pas être traité sur place. Cependant, un employeur 
                            responsable ou un individu conscient peut également souhaiter pouvoir intervenir pour organiser une évacuation de groupe ou individuelle 
                            sans égard aux conditions médicales! Par exemple, si la situation militaire ou sanitaire locale l’exige. Cela pourrait être le cas dans 
                            certaines régions du monde, où de nombreux expatriés vivent des risques quotidiens. Risques d’enlèvement en Algérie ? Ebola en Afrique de 
                            l’Ouest ? Des troubles en Syrie ? Dans ce cas, le contrat de sécurité et d’assistance en cas de crise donne accès à différents niveaux 
                            d’alerte au preneur d’assurance et à l’assuré – l’évacuation étant l’ultime étape déclenchée par le souscripteur. 
                            Les frais de rapatriement (billets d’avion, etc.) sont alors facturés à l’abonné. Dans le cadre d’un contrat collectif, <b>la prime annuelle sera inférieure à 200 € par personne assurée.</b>
                            </p>

                            <p data-aos="fade-up" className='justify-text'>
                            Que vous soyez responsable des ressources humaines pour les expatriés de votre entreprise, 
                            PDG de l’entreprise ou personne concernée, contactez-nous discrètement, pour une étude personnalisée.
                            </p>

                            <p data-aos="fade-up" className='justify-text'>
                                Il peut en effet être judicieux de regrouper, au sein d’une même plate-forme d’assistance, les différents services 
                                pour bénéficier d’une couverture d’assurance couvrant 
                                toutes ces situations et de la tranquillité d’esprit que procure le fait de se savoir couvert en toutes circonstances.
                            </p>

                            <p data-aos="fade-up" className='justify-text'>
                                Cependant, sur la base des données des audits que nous menons régulièrement pour le compte de nos clients, nous constatons que des couvertures sont 
                                parfois inutilement souscrites avec des primes redondantes alors que des garanties complémentaires pourraient s’avérer judicieuses.
                            </p>

                            <p data-aos="fade-up" className='justify-text'>
                                Les services et les conseils du courtier sont généralement une source d’économies pour le client.
                            </p>

                        <p className='justify-text'>Demandez-nous votre projet, sans engagement ! La souscription est ensuite 
                            très simple, avec nos conseils en prime !</p>

                        <a href='/Demandededevis/entreprises' data-aos="fade-right"  className="default-btn"><i className="fa-solid fa-chart-column fa-1x fa-flip">&nbsp;&nbsp;</i> Demandez votre devis !<span></span></a>

            
                        <div className='row another-link' data-aos="fade-right" >
                            <h3 className='mt-5'>Vous pourriez être intéressé par les produits  suivants :</h3>

                            <div className='col-md-4 mt-2'>
                                <a href="/ContratCollectifPrevoyance"   className=""><b>Collectifs santé et prévoyance</b> <span></span></a>
                            </div>

                            <div className='col-md-4 mt-2'>
                                <a href="/AuditRisquesEntreprises"   className=""><b>Audit risques entreprises</b> <span></span></a>
                            </div>

                            <div className='col-md-4 mt-2'>
                                <a href="/ConseilCima"   className=""><b>Conseil CIMA</b> <span></span></a>
                            </div>

                        </div>

                    </div>

                       
 
                </div>
    
                </div>
                
 
            </div>
        </section>
        
    </>
  )
}
