import React from 'react'

export default function Faq() {
  return (
    <>
        <div className="page-title-area page-title-bg-Faq">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>FAQ</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="accordion_two_section ptb-60">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-2 col-lg-8 col-md-8 col-12 accordionTwo">
                            <div className="panel-group" id="accordionTwoLeft">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordionTwoLeft" aria-expanded="false" className="collapsed checkState1">
                                                QUELLE EST L'ÉTENDUE DES GARANTIES D'UN CONTRAT SANTÉ INTERNATIONAL ?
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapseTwoLeftone" className="panel-collapse collapse" aria-expanded="false" role="tablist">
                                        <div className="panel-body">
                                            Tous les contrats proposent une garantie de base qui prend en charge les dépenses en cas d'hospitalisation, 
                                            généralement à 100% des frais réels, avec des plafonds souvent élevés et suffisants (à vérifier quand même 
                                            dans certains pays comme les USA, Singapour... dans lesquels il est moins coûteux d'acheter une voiture que 
                                            de se casser le bras !). En option, vous pourrez couvrir les dépenses de médecine courante (le "out patient"), 
                                            les soins dentaires, l'optique, la maternité et bien sûr l'évacuation (ou rapatriement) sanitaire. Mais en général, 
                                            le dentifrice restera à votre charge ;-)
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="collapsed checkState2" data-toggle="collapse" data-parent="#accordionTwoLeft" aria-expanded="false">
                                                QUELLE DIFFÉRENCE ENTRE "TACITE RECONDUCTION" OU "DURÉE FERME" ?
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapseTwoLeftTwo" className="panel-collapse collapse" aria-expanded="false" role="tablist">
                                        <div className="panel-body">
                                            Si vous partez à l'étranger pour plus de 12 mois, il est recommandé de souscrire un contrat par "tacite reconduction" 
                                            et avec une garantie viagère. Cela signifie qu'une fois votre dossier accepté, l'assureur ne pourra résilier le contrat 
                                            même si vous souffrez d'une pathologie "longue durée". C'est la garantie d'être couvert jusqu'à la fin de ses jours. 
                                            Un contrat à durée ferme est souvent moins onéreux mais son éventuel renouvellement est à l'appréciation de l'assureur : 
                                            autrement dit, si vous lui coûter plus cher que vous ne lui rapportez... on ne vous fait pas un dessin ! 
                                            Cela peut donc être très problématique en cas de survenance d'une pathologie lourde.
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="collapsed checkState3" data-toggle="collapse" data-parent="#accordionTwoLeft" aria-expanded="false">
                                                LES PATHOLOGIES PRÉEXISTANTES SERONT-ELLES COUVERTES ?
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapseTwoLeftThree" className="panel-collapse collapse" aria-expanded="false" role="tablist">
                                        <div className="panel-body">
                                            Avant d'accorder sa garantie et de confirmer un tarif, l'assureur va vérifier que l'assuré potentiel ne souscrit 
                                            pas par opportunisme ! Sélectionner les risques, c'est la base du métier et le futur assuré va donc compléter 
                                            un questionnaire médical. Poids, taille, consommation d'alcool ou de cigarettes, maladies passées, séquelles 
                                            d'accidents, traitement en cours ou antécédents familiaux seront étudiés par des médecins de l'assureur qui 
                                            peuvent refuser ou accepter un dossier sans avoir à se justifier. Les profils de santé "hors norme" pourront 
                                            être l'objet de majoration ou des exclusions particulières pourront être proposées à l'assuré. Il est important 
                                            de bien comprendre la logique de l'assurance santé. De la même manière que tout le monde comprend que ce n'est 
                                            pas après l'accident de voiture qu'il faut penser à s'assurer...
                                        </div>
                                    </div>
                                </div>

                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="collapsed checkState4" data-toggle="collapse" data-parent="#accordionTwoLeft" aria-expanded="false">
                                                LE CONTRAT PRÉVOIT-IL DES FRANCHISES ET/OU DES DÉLAIS D'ATTENTE ?
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapseTwoLeftFour" className="panel-collapse collapse" aria-expanded="false" role="tablist">
                                        <div className="panel-body">
                                            La prime proposée par l'assureur sera fonction des "franchises" (la somme que l'assuré accepte de conserver à sa charge). 
                                            Pour réduire votre prime annuelle, vous pourrez par exemple choisir de n'être remboursé qu'à 80% ou 90% pour la médecine 
                                            courante. Cette franchise est généralement appréciée par l'assureur car elle "moralise" la consommation. Autre point à 
                                            considérer : à partir de quand serez-vous couvert ? Généralement les garanties sont immédiates en cas d'accident et maladie.
                                            Seuls certains postes ne seront accordés qu'après une période de carence : maternité, prothèses dentaires ou encore 
                                            psychiatrie par exemple.
                                        </div>
                                    </div>
                                </div>

                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="collapsed checkState5" data-toggle="collapse" data-parent="#accordionTwoLeft" aria-expanded="false">
                                                QUELLE EST LA DIFFÉRENCE ENTRE UNE ÉVACUATION SANITAIRE ET UN RAPATRIEMENT ?
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapseTwoLeftFive" className="panel-collapse collapse" aria-expanded="false" role="tablist">
                                        <div className="panel-body">
                                            L'évacuation sanitaire permet à l'assuré d'être évacué vers le centre médical adéquat le plus proche. Le rapatriement 
                                            sanitaire quant à lui lui assure d'être rapatrié dans son pays d'origine ou de nationalité. Il est vrai qu'en cas d'urgence, 
                                            l'assuré, comme sa famille, préfèrent en général que le malade ou l'accidenté revienne "à la maison". Mais l'objectif de la 
                                            garantie est tout d'abord de tout mettre en œuvre pour sauver la vie d'un assuré quand ce n'est pas possible localement.
                                        </div>
                                    </div>
                                </div>

                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="collapsed checkState6" data-toggle="collapse" data-parent="#accordionTwoLeft" aria-expanded="false">
                                                COMMENT SONT DÉCIDÉS UNE ÉVACUATION SANITAIRE OU UN RAPATRIEMENT ?
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapseTwoLeftSix" className="panel-collapse collapse" aria-expanded="false" role="tablist">
                                        <div className="panel-body">
                                            Dans tous les cas, ce sont deux médecins qui vont mettre en œuvre cette garantie : celui en charge du patient localement 
                                            et celui de l'assisteur. L'assuré ne choisit donc ni le moment ni les conditions de l'intervention ! Celle-ci, une fois 
                                            décidée, pourra se faire sur vol de ligne régulière (généralement en business className et avec un médecin détaché pour le voyage) 
                                            ou en avion médicalisé si nécessaire. Il est  impératif de respecter les procédures de l'assureur et de ne rien décider ni 
                                            financer sans avoir eu son accord (on insiste !). Certains contrats d'assistance proposent le rapatriement du corps en cas 
                                            de décès et parfois le retour de l'assuré en cas de maladie ou décès d'un proche.
                                        </div>
                                    </div>
                                </div>

                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="collapsed checkState7" data-toggle="collapse" data-parent="#accordionTwoLeft" aria-expanded="false">
                                                CFE OU PAS CFE ?
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapseTwoLeftSeven" className="panel-collapse collapse" aria-expanded="false" role="tablist">
                                        <div className="panel-body">
                                            Adhérer volontairement à la Caisse des Français de l'Étranger est un choix individuel qui mérite d'être réfléchi. 
                                            Il faut prendre en compte les limites de la CFE, qui rembourse sur les bases de la Sécurité Sociale française, ce qui, 
                                            à l'étranger, peut s'avérer très insuffisant. Il est donc conseillé de souscrire une assurance "complémentaire CFE" 
                                            ("mutuelle") et une garantie "rapatriement sanitaire". Les cotisations sont fonction de l'âge et de la situation familiale 
                                            (solo / famille). Avantage indéniable : les pathologies préexistantes seront prises en charge par la CFE. Au-delà du choix 
                                            "citoyen" qui pourrait être la motivation du bénéficiaire (la solidarité nationale organisée pour la santé et éventuellement 
                                            la retraite), l'aspect financier reste souvent le critère déterminant pour faire un choix. A l'étranger, nombre d'expatriés 
                                            ou binationaux font donc le choix d'un assureur privé, pour une prise en charge à 100% de leurs frais médicaux dans le monde : 
                                            on parle alors d'assurance santé privée au "1er euro". Les pathologies préexistantes peuvent là par contre donner lieu à 
                                            majoration ou dans certains cas, exclusions. En fonction de votre situation, vous aurez donc plus ou moins intérêt 
                                            (financièrement parlant) à choisir l'une ou l'autre des solutions qui s'offrent à vous. Sachant qu'à l'arrivée, le 
                                            niveau de prestations d'un contrat CFE + complémentaire CFE ou 1er euro sont identiques. Si vous n'avez pas tout 
                                            saisi, pas de panique, contactez-nous et on vous ré-explique tout ça !
                                        </div>
                                    </div>
                                </div>

                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="collapsed checkState8" data-toggle="collapse" data-parent="#accordionTwoLeft" aria-expanded="false">
                                                POURQUOI MA PRIME AUGMENTE CHAQUE ANNÉE ? 
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapseTwoLeftEight" className="panel-collapse collapse" aria-expanded="false" role="tablist">
                                        <div className="panel-body">
                                            Ces augmentations s'expliquent essentiellement par l'inflation des coûts médicaux dans le monde (comme votre kilo 
                                            de tomates, finalement). A cela s'ajoute le fait que chaque année (ou par tranches d'âges) les tarifs des assureurs 
                                            (et de la CFE / OSSOM) augmentent... tout comme l'assuré qui prend inévitablement un an de plus (on ne vous apprend 
                                            rien !). On est statistiquement moins malade à 20 ans qu'à 60 !
                                        </div>
                                    </div>
                                </div>

                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="collapsed checkState9" data-toggle="collapse" data-parent="#accordionTwoLeft" aria-expanded="false">
                                                VOUS AVEZ D'AUTRES QUESTIONS ?
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapseTwoLeftNine" className="panel-collapse collapse" aria-expanded="false" role="tablist">
                                        <div className="panel-body">
                                            On a essayé de penser à tout, mais comme on n'est pas des machines, vous avez peut-être des questions complémentaires. 
                                            Et c'est là que notre joyeuse équipe intervient : contactez-nous, on adore vous conseiller !
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                           
                        </div>
                       
                    </div>
                </div>
                
            </section>

        
    </>
  )
}
