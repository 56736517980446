import React from 'react'

export default function AuditRisquesEntreprises() {
  return (
    <>

<div className="page-title-area page-title-bg-audit">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Audit risques entreprises</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="about-area ptb-60 bg-f8f8f8">
            <div className="container">

                <div className='row'>
                <div className='col-lg-12 col-md-12 col-12'>
                    <div>
                            <p data-aos="fade-up" className='justify-text'>
                            Les risques font partie de la vie : bien les maîtriser est un facteur de stabilité et de développement et 
                            peut permettre de <b>réduire de manière significative le budget “assurance” de l’entreprise</b>.
                            </p>

                            <p data-aos="fade-up" className='justify-text'>
                            Pour autant, vue depuis les sièges en Europe, l’assurance de l’entreprise à l’étranger est un domaine parfois difficile 
                            à appréhender. Même si des “courtiers internationaux” existent, on peut parfois remettre en question leur expertise 
                            locale ou leur indépendance…
                            </p>

                            <p data-aos="fade-up" className='justify-text'>
                            François Masson, précédemment Directeur Général et Administrateur de compagnie d’assurance internationale, 
                            expert en assurances IARD et santé, vous propose un <b>audit de contrats d’assurance de votre entreprise à 
                            l’étranger</b> et vous accompagne dans l’analyse de vos risques et des couvertures en place. 
                            C’est particulièrement utile quand on a des filiales car nombreuses sont les couvertures 
                            souvent inutilement souscrites, notamment en matière de Responsabilité Civile ou d’assistance, 
                            alors que d’autres primordiales peuvent manquer. Les valeurs assurées sont souvent inadaptées ou des 
                            garanties sont oubliées (pertes d’exploitation, assurance bris de machine…). 
                            </p>

                            <p data-aos="fade-up" className='justify-text'>
                            En fonction de vos priorités, ce diagnostic pourra être global, ou ciblé (par pays, par branche : transport, responsabilités, incendie, 
                            protection sociale…).
                            </p>


                            <p data-aos="fade-up" className='justify-text'>
                            Cet état des lieux effectué permettra de détecter les risques qui peuvent être assumés par l’entreprise seule (en “auto-assurance”, 
                            par la mise en place de “franchises” dans les contrats, en travaillant sur la prévention et la formation…) et ceux qui doivent 
                            impérativement être assurés.
                            </p>

                            <p data-aos="fade-up" className='justify-text'>
                            Ce type de mission, facturé sur base d’honoraires, permet en général des économies substantielles sur le <b>budget assurances de l’entreprise</b>.
                            </p>

                            <p data-aos="fade-up" className='justify-text'>
                            À l’issue de cette étude, nous pouvons éventuellement nous charger de placer vos risques sur le marché, aux meilleures conditions, 
                            dans le cadre de votre budget, avec un intermédiaire local ou non, suivant votre souhait.
                            </p>

                            <p data-aos="fade-up" className='justify-text'>
                            Nous pouvons en effet coordonner localement, bien évidemment avec vos équipes locales avec qui il sera important de collaborer pendant l’étude, 
                            mais également si besoin est en nous appuyant sur notre <b>réseau de partenaires Courtiers</b> et sur les meilleures compagnies d’assurances européennes ou locales.
                            </p>

                            <p data-aos="fade-up" className='justify-text'>
                            Nous intervenons dans le monde entier et notamment en <b>Afrique (nous sommes spécialistes du code CIMA), au Proche-Orient et en Asie</b> : vérifications de risques et 
                            audit de contrats en place, harmonisation de couvertures existantes, renégociations de conditions et de taux…
                            </p>

                            <p data-aos="fade-up" className='justify-text'>
                            <b>Dans un domaine très technique</b>, BSI apporte, par son expertise, un service complet à l’entreprise. Nous nous appuyons, si besoin, 
                            sur des experts recrutés ponctuellement pour des 
                            missions nécessitant un appui technique local ou international, en fonction de vos besoins et du cahier des charges défini ensemble.
                            </p>

                            <p data-aos="fade-up" className='justify-text'>
                            En cas de sinistre, nous sommes également aux côtés de nos clients, soit contractuellement quand nous sommes “courtier en charge”, 
                            soit en tant qu’expert et sur base d’honoraires, en fonction des demandes spécifiques que vous pouvez nous confier. Nous veillons 
                            alors à la bonne application des garanties souscrites et des procédures d’expertise, jusqu’au terme du sinistre.
                            </p>

                        {/* <h2 data-aos="fade-right" className='mt-5'>Découvrez les étapes de la souscription.</h2> */}
                        <p className='justify-text'>Pour commencer, demandez-nous votre projet, sans engagement ! La souscription est ensuite 
                            très simple avec nos conseils en prime…</p>

                        <a href='/Demandededevis/entreprises' data-aos="fade-right"  className="default-btn"><i className="fa-solid fa-chart-column fa-1x fa-flip">&nbsp;&nbsp;</i> Demandez votre devis !<span></span></a>

            
                        <div className='row another-link' data-aos="fade-right" >
                            <h3 className='mt-5'>Vous pourriez etre intéressé par les produits  suivant :</h3>

                            <div className='col-md-4 mt-2'>
                                <a href="/ContratCollectifPrevoyance"   className=""><b>Collectifs santé et prévoyance</b> <span></span></a>
                            </div>

                            <div className='col-md-4 mt-2'>
                                <a href="/Kidnapping"   className=""><b>Assurance kidnapping, crise et sécurité</b> <span></span></a>
                            </div>

                            <div className='col-md-4 mt-2'>
                                <a href="/ConseilCima"   className=""><b>Conseil CIMA</b> <span></span></a>
                            </div>

                        </div>

                    </div>

                </div>
                
                </div>
                
 
            </div>
        </section>

    </>
  )
}
