import { useState,useEffect,useRef } from "react";
import CurrentDate from '../../common/getCurrentDate'


export default function ChildForm({position,onChildClose,handleChangeEnfant,values,oldEnfant,setOldEnfant,errors,old,key}) {

  const titleRef = useRef("");

  const timeout = (ms) => {
      return new Promise((resolve) => {setTimeout(resolve,ms)})
  }


  useEffect(() => {
    
    let isCancelled = false;

    const handleExecution = async() => {

      await timeout(200)

      if(values[position]) {

        const newOldEnfant = [...oldEnfant]

          if (!isCancelled) {
          
            if(oldEnfant[position]) {

                newOldEnfant[position] = old(values[position].dateNaissanceEnfant);

            }else { 

                newOldEnfant.push(old(values[position].dateNaissanceEnfant))
    
            }

            setOldEnfant(newOldEnfant);

          }

      }    

    }

    handleExecution();

    return () => { 
      isCancelled = true;
    };

  }, [values[position]])


  return (
    <>
      <div className="row">
        <h6 className="offset-md-1 col-lg-10 col-md-6 col-sm-12">Enfant {position + 1}</h6>
        <div className="offset-md-9 offset-4 col-lg-2 col-md-6">
          <i onClick={() => onChildClose(position)} className="fas fa-trash" style={{fontSize:'15px',
              color: '#ffffff',
              background: 'rgb(220, 53, 69)',
              border: '1px solid rgb(220, 53, 69)',
              borderRadius:'50%',
              padding:'8px'}}>
          </i>
        </div>
        <div className="offset-md-1 col-lg-10 col-md-6 col-sm-12">
          <div className="form-group">
            <label>Date de naissance<abbr>*</abbr>
              
                {  window.innerWidth < 720 &&  values[position] ? '' : <b className='fa-beat-fade' style={{ color: "#C14742"}}>Cliquez 02 fois 👇</b> }

              <b>
                { 
                  // (oldEnfant[position] >= 1) ? '(' + oldEnfant[position] + `${oldEnfant[position] > 1 ? ' ans' : oldEnfant[position] === 1 ? 'an' : ''})` : (oldEnfant[position] === 0) ? '( 0 an )' : ''
                }
              </b>
            </label>

                <input
                  ref={titleRef}
                  type={ window.innerWidth > 600 ? 'date' : 'text'}
                  name="date"
                  max={CurrentDate()}
                  onInput={(e) => handleChangeEnfant(e.target.value,position)}
                  defaultValue={values[position] ? values[position].dateNaissanceEnfant : ''}
                  key={key}
                  className="form-control"
                  placeholder="jj/mm/aaaa"
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                />

            {
              errors.child && errors.child[position] === 'required' ?
              <div className="errorStyle">
                Le champ est obligatoire.
              </div>
                : errors.child && errors.child[position] === ''  
                ?
              <div className="successStyle">
                Correct ! 😊
              </div>
              : ''
            }
          </div>
        </div>
      </div>
    </>
  );
}
