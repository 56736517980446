import React from 'react'
import logo_bsi from '../styles/img/logo-bsi-header.webp'
import ReactGA from 'react-ga';



export default function Header() {

    const trakerEventProduit = (event) => {

        ReactGA.event({
            category: 'Produit',
            action: `Produit : ${event.target.innerText}`,
            });

    }
        
  return (
    <>
        <div className='nav-background nav-background1'>
            <nav className='container laptop-nav'>
                <div className='row d-flex justify-content-center paddingHeader'> 
                        <div className='col-md-10 nav_content_height_mobile'>

                            <ul className="liste">
                                <li><a href="/"><img src={logo_bsi} className="logo"/></a></li>
                                <li className="items"><a href="#" className="active">Particuliers <i className="fas fa-angle-down"></i> </a>
                
                                        <ul className="drop">
                                    
                                            <li className="nav-item"><a href="/Expatries" onClick={trakerEventProduit} className="nav-link">Assurances expatriés</a></li>

                                            <li className="nav-item"><a href="/Pas-Expatries" className="nav-link">Assurances non expatriés</a></li>

                                            <li className="nav-item"><a href="/GlobeTrotters" className="nav-link">Globe-trotters et étudiants</a></li>

                                            <li className="nav-item"><a href="/Schengen" className="nav-link">Assurances Schengen</a></li>

                                        </ul>
                                  
                                </li>
                                <li className="items"><a href="#">Entreprises <i className="fas fa-angle-down"></i></a>
                                    <ul className="drop">
                                        <li className="nav-item"><a href="/ContratCollectifPrevoyance" className="nav-link">Collectifs santé et prévoyance</a></li>

                                        <li className="nav-item"><a href="/AuditRisquesEntreprises" className="nav-link">Audit risques entreprises</a></li>

                                        <li className="nav-item"><a href="/Kidnapping" className="nav-link">Assurance kidnapping</a></li>

                                        <li className="nav-item"><a href="/ConseilCima" className="nav-link">Conseil CIMA</a></li>

                                    </ul>
                                </li>
                                <li className="items"><a href="/E-bsi">E-bsi</a></li>
                                <li className="items"><a href="/Bsimmobilier">Bsimmobilier</a></li>
                                <li className="items"><a href="/Apropos">À propos</a></li>
                                <li className="items whatsappus">
                                    <a href="https://api.whatsapp.com/send?phone=33632854396" target="_blank">
                                        <i className="fab fa-whatsapp fa-2x fa-beat-fade"></i>
                                        <span><span>WhatsApp us!</span><br/> <span className="nav-box" >+33 6 32 85 43 96</span></span>
                                    </a>
                                    
                                </li>

                            </ul>
                        
                        </div>
            
                </div>
            </nav>

            <nav className='container mobile-nav'>
                <div className='row d-flex justify-content-center paddingHeader'> 
                        <div className=''>
                            <div className="">
                                <a className="col-2" href="/"><img src={logo_bsi} className="logo" alt="image"/></a>
                                <button className="col-2">
                                    <span className="hambuger"><img src="../../assets/img/hamburger.svg" alt="hambuger"/></span>
                                    <span className="close-nav"><i className="fa fa-close fa-2x"></i></span>
                                </button>
                            </div>

                            <ul id="displayNavItem" className="liste-responsive col-sm-10 col-xs-10">
                                <li className="items item1"><a href="#" className="active">Particuliers <i style={{marginLeft: '45px'}} className="fas fa-angle-down pull-right"></i> </a>
                
                                        <ul className="drop-responsive dr2">
                                    
                                            <li className="nav-item"><a href="/Expatries" className="">Assurances expatriés</a></li>

                                            <li className="nav-item"><a href="/Pas-Expatries" className="">Assurances non expatriés</a></li>

                                            <li className="nav-item"><a href="/GlobeTrotters" className="">Globe-trotters et étudiants</a></li>

                                            <li className="nav-item"><a href="/Schengen" className="">Assurances Schengen</a></li>

                                        </ul>
                                  
                                </li>
                                <li className="items item2"><a href="#">Entreprises <i style={{marginLeft: '52px'}} className="fas fa-angle-down"></i></a>
                                    <ul className="drop-responsive dr1">
                                        <li className="nav-item"><a href="/ContratCollectifPrevoyance" className="">Collectifs santé et prévoyance</a></li>

                                        <li className="nav-item"><a href="/AuditRisquesEntreprises" className="">Audit risques entreprises</a></li>

                                        <li className="nav-item"><a href="/Kidnapping" className="">Assurance kidnapping</a></li>

                                        <li className="nav-item"><a href="/ConseilCima" className="">Conseil CIMA</a></li>

                                    </ul>
                                </li>
                                <li className="items"><a href="/E-bsi">E-bsi</a></li>
                                <li className="items"><a href="/Bsimmobilier">Bsimmobilier</a></li>
                                <li className="items"><a href="/Blog">Blog</a></li>
                                <li className="items"><a href="/Apropos">À propos</a></li>
                            </ul>
                        
                        </div>
            
                </div>
            </nav>
        </div>
        

    </>
  )
}



