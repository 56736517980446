import React, {useEffect, useState} from "react";
import "../styles/css/devis.css";
import Step1 from "../components/ApplicationPage/FormParticulierStep/Step1";
import Step2 from "../components/ApplicationPage/FormParticulierStep/Step2";
import Step3 from "../components/ApplicationPage/FormParticulierStep/Step3";
import Step4 from "../components/ApplicationPage/FormParticulierStep/Step4";
import Step5 from "../components/ApplicationPage/FormParticulierStep/Step5";
import FormBottomText from "../components/common/FormBottomText";
import { postEntity } from "../services/api";

export default function DemandededevisParticulier() {

  const [values, setValues] = useState({
    nom : '',
    prenom : '',
    expatriationCountry : '',
    enfants : [],
    courvertureSouhaite : '',
    courvertureExistante : '',
    dateNaissanceAssurePrincipal : '',
    sexeAssurePrincipal : '',
    nationaliteAssurePrincipal : '',
    whatsapp : '',
    email : '',
    captcha: '',
    autreInformations: '',
    plusDeDouzeMois: false,
    finCourverture: '',
    debutCourverture: '',
    conjoint: {
      nationaliteConjoint:'',
      dateNaissanceConjoint:''
    },

  });

  useEffect(() => {

      const path = window.location.pathname;
      window.history.pushState('', '', path+'#form');

  }, [])

  const [errors, setErrors] = useState({
    child:{},
    conjoint:{}
  });
  
  const [activeRadio, setActiveRadio] = useState({
    step1Radio1 : "",
    step1Radio2 : "",
    step4Radio1 : "",
    step4Radio2 : ""
  });

  const [checked, setChecked] = useState("");
  const [checkedDuration, setCheckedDuration] = useState("");
  const [radioChild, setRadioChild] = useState([]);
  const [radioConjoint, setRadioConjoint] = useState("");
  const [currentTitle, setCurrentTitle] = useState(1)

  const Next = () => {
    setCurrentTitle( currentTitle + 1);
  }

  const Prev = () => {
    setCurrentTitle( currentTitle - 1);
  }

  const onSubmit = () => {

    // here is the code to send data to backend

          postEntity("/particular/save",values).then(() => {
            setCurrentTitle(6);
            setValues({});
            setErrors({});
            }, (errors) => {
              console.log('errors')
            });

  }

  return (
    <>
      <section className="insurance-details-area ptb-60">
        <div className="container">
          <div className="insurance-details-header">
            <div className="row papa align-items-center justify-content-center">
              <div className="col-lg-9 col-md-12">
                { currentTitle === 6 ?

                  <div>
                      <div className="card" style={{width: '60 rem'}}>
                        <div className="card-body">
                          <h4 className="card-title"><b>Demande envoyée avec succès </b>😊</h4>
                          {/* <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6> */}
                          {/* <p className="card-text mt-3">Nous vous répondrons rapidement {values.whatsapp ? "Whatsapp" : "Email"} !!! */}
                          <p id="form" className="card-text mt-3">Nous vous répondrons rapidement.
                          <br/>Merci pour votre intérêt !
                          </p>
                          <div className="mt-3">
                            <a href="/Demandededevis/particuliers" className="default-btn">Revenir au formulaire particulier</a>{"  "}
                            <a href="/" className="default-btn-annuler">Page d'accueil</a>{"  "}
                          </div>
                          
                          {/* <a href="#" className="default-btn">Formulaire entreprise</a> */}

                        </div>
                      </div>
                  </div>

                  :

                  <div className="row papa">
                  <div className="row papa">
                    <div className="card cardStyle cardTitle title">
                        <h5 className="text-center">
                          Demandez votre devis 
                        </h5>
                      </div>
                  </div>

                    <div className="row container papa contentPadding" id="form">
                      <div className="col-lg-4 col-md-4 col-sm-12 col-12 background card cardStyle">
                        <div className={`col-lg-12 formTitle ${values.whatsapp || values.email  ? 'cursor-pointer' :''} ${currentTitle===1 ? 'vl activee' : ''}`}   onClick={ () => values.whatsapp || values.email ? setCurrentTitle(1) : ''} > Contact </div>

                        {
                          window.innerWidth < 600 && (

                            currentTitle===1 ?
                              <div className="col-lg-8 col-md-8 col-sm-12 col-12 card cardStyle formContent">
                                <div className="contact-form">
                                    <form id="contactForm" className="">
                                    
                                              <Step1 
                                              CurrentTitle={currentTitle} 
                                              Next={Next} 
                                              setValues={setValues}
                                              values={values}
                                              activeRadio={activeRadio}
                                              setActiveRadio={setActiveRadio}
                                              checked={checked}
                                              setChecked={setChecked}
                                              setErrors={setErrors}
                                              errors={errors}
                                            />
                                    </form>
                                </div>
                              </div>

                              :''
                         )
                        }
                        <div className={`col-lg-12 formTitle ${values.nom && values.prenom  ? 'cursor-pointer' :''} ${currentTitle===2 ? 'vl activee' : ''}`}   onClick={ () => values.nom && values.prenom ? setCurrentTitle(2) : ''} > Identité</div>

                        {
                          window.innerWidth < 600 && (

                            currentTitle===2 ?
                              <div className="col-lg-8 col-md-8 col-sm-12 col-12 card cardStyle formContent">
                                <div className="contact-form">
                                    <form id="contactForm" className="">

                                            <Step2 
                                            CurrentTitle={currentTitle} 
                                            Next={Next} 
                                            Prev={Prev}
                                            setValues={setValues}
                                            setErrors={setErrors}
                                            values={values}
                                            errors={errors}
                                          />
                                    </form>
                                </div>
                              </div>
                            
                            :''
                         )
                        }

                        <div className={`col-lg-12 formTitle ${values.expatriationCountry ? 'cursor-pointer' :''} ${currentTitle===3 ? 'vl activee' : ''}`}   onClick={ () => values.expatriationCountry ? setCurrentTitle(3) : ''} > Couverture</div>

                        {
                          window.innerWidth < 600 && (

                            currentTitle===3 ?
                              <div className="col-lg-8 col-md-8 col-sm-12 col-12 card cardStyle formContent">
                                <div className="contact-form">
                                    <form id="contactForm" className="">

                                            <Step3 
                                            CurrentTitle={currentTitle} 
                                            Next={Next} 
                                            Prev={Prev}
                                            setValues={setValues}
                                            values={values}
                                            activeRadio={activeRadio}
                                            setActiveRadio={setActiveRadio}
                                            checkedDuration={checkedDuration}
                                            setCheckedDuration={setCheckedDuration}
                                            setErrors={setErrors}
                                            errors={errors}
                                          />
                                    </form>
                                </div>
                              </div>

                          :''
                         )
                        }

                        <div className={`col-lg-12 formTitle ${values.nationaliteAssurePrincipal && values.dateNaissanceAssurePrincipal && values.sexeAssurePrincipal ? 'cursor-pointer' :''} ${currentTitle===4 ? 'vl activee' : ''}`}   onClick={ () => values.nationaliteAssurePrincipal && values.dateNaissanceAssurePrincipal && values.sexeAssurePrincipal ? setCurrentTitle(4) : ''} > Personne(s) à assurer</div>

                        {
                          window.innerWidth < 600 && (

                           currentTitle===4 ?
                              <div className="col-lg-8 col-md-8 col-sm-12 col-12 card cardStyle formContent">
                                <div className="contact-form">
                                    <form id="contactForm" className="">
                                    
                                            

                                            <Step4 
                                            CurrentTitle={currentTitle} 
                                            Next={Next} 
                                            Prev={Prev}
                                            setValues={setValues}
                                            setErrors={setErrors}
                                            values={values}
                                            errors={errors}
                                            radioChild={radioChild}
                                            setRadioChild={setRadioChild}
                                            radioConjoint={radioConjoint}
                                            setRadioConjoint={setRadioConjoint}
                                          />
                                    </form>
                                </div>
                              </div>

                              :''
                         )
                        }

                        <div className={`col-lg-12 formTitle ${values.autreInformations && values.captcha ? 'cursor-pointer' :''} ${currentTitle===5 ? 'vl activee' : ''}`}   onClick={ () => values.autreInformations && values.captcha ? setCurrentTitle(5) : ''} > Autre précision</div>

                        {
                          window.innerWidth < 600 && (

                            currentTitle===5 ?

                              <div className="col-lg-8 col-md-8 col-sm-12 col-12 card cardStyle formContent">
                                <div className="contact-form">
                                    <form id="contactForm" className="">

                                            <Step5 
                                            CurrentTitle={currentTitle} 
                                            setValues={setValues}
                                            setErrors={setErrors}
                                            values={values}
                                            errors={errors}
                                            onSubmit={onSubmit} 
                                            Prev={Prev}
                                          />
                                    </form>
                                </div>
                              </div>

                              :''
                         )
                        }

                      </div>
                      {
                        window.innerWidth > 600 && (
                          <div className="col-lg-8 col-md-8 col-sm-12 col-12 card cardStyle formContent">
                        <div className="contact-form">
                            <form id="contactForm">
                                    
                                {
                                      currentTitle===1 ? 
                                      
                                      <Step1 
                                        CurrentTitle={currentTitle} 
                                        Next={Next} 
                                        setValues={setValues}
                                        values={values}
                                        activeRadio={activeRadio}
                                        setActiveRadio={setActiveRadio}
                                        checked={checked}
                                        setChecked={setChecked}
                                        setErrors={setErrors}
                                        errors={errors}
                                      />

                                    : currentTitle===2
                                    ? <Step2 
                                        CurrentTitle={currentTitle} 
                                        Next={Next} 
                                        Prev={Prev}
                                        setValues={setValues}
                                        setErrors={setErrors}
                                        values={values}
                                        errors={errors}
                                      />

                                    : currentTitle===3
                                    ? <Step3 
                                        CurrentTitle={currentTitle} 
                                        Next={Next} 
                                        Prev={Prev}
                                        setValues={setValues}
                                        values={values}
                                        activeRadio={activeRadio}
                                        setActiveRadio={setActiveRadio}
                                        checkedDuration={checkedDuration}
                                        setCheckedDuration={setCheckedDuration}
                                        setErrors={setErrors}
                                        errors={errors}
                                      />

                                    : currentTitle===4
                                    ? <Step4 
                                        CurrentTitle={currentTitle} 
                                        Next={Next} 
                                        Prev={Prev}
                                        setValues={setValues}
                                        setErrors={setErrors}
                                        values={values}
                                        errors={errors}
                                        radioChild={radioChild}
                                        setRadioChild={setRadioChild}
                                        radioConjoint={radioConjoint}
                                        setRadioConjoint={setRadioConjoint}
                                      />

                                    : currentTitle===5
                                    ? <Step5 
                                        CurrentTitle={currentTitle} 
                                        setValues={setValues}
                                        setErrors={setErrors}
                                        values={values}
                                        errors={errors}
                                        onSubmit={onSubmit} 
                                        Prev={Prev}
                                      />
                                    :   ""

                                }

                            </form>
                        </div>
                        
                      </div>
                        )
                      }
                      

                      <div className="offset-md-2 col-md-8 text-center">
                        <FormBottomText></FormBottomText>
                      </div>
                      
                    </div>
                    
                </div>

                }
                
                
              </div>
                    
                    
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
