import React from 'react'
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  withRouter
} from "react-router-dom";

import Footer from "./layouts/Footer";
import Header from "./layouts/Header";
import Welcome from "./pages/Welcome";
import Ebsi from "./pages/Ebsi";
import Bsimmobilier from "./pages/Bsimmobilier";
import Apropos from "./pages/Apropos";
import Partner from "./pages/Partner";
import Plandusite from "./pages/Plandusite";
import Politiquevieprivee from "./pages/Politiquevieprivee";
import Mentionlegales from "./pages/Mentionlegales";
import Politiquecookies from "./pages/Politiquecookies";
import Blog from "./pages/Blog";
import DemandededevisPage from "./pages/DemandededevisPage";
import Expatries from "./pages/Expatries";
import PasExpatries from "./pages/PasExpatries";
import Faq from "./pages/Faq";
import GlobeTrotters from "./pages/GlobeTrotters";
import Schengen from "./pages/Schengen";
import Kidnapping from "./pages/Kidnapping";
import ConseilCima from "./pages/ConseilCima";
import ContratCollectifPrevoyance from "./pages/ContratCollectifPrevoyance";
import AuditRisquesEntreprises from "./pages/AuditRisquesEntreprises";
// import ReactGA from 'react-ga';



const SwitchRoutes = (props) => {
  return (
    <>
      <Header/>

        <Route path="/" exact component={Welcome} />
        <Route path="/E-bsi" exact component={Ebsi} /> 
        <Route path="/BSimmobilier" exact component={Bsimmobilier} /> 
        <Route path="/Apropos" exact component={Apropos} />
        <Route path="/Devenir_partenaire" exact component={Partner} /> 
        <Route path="/Plandusite" exact component={Plandusite} /> 
        <Route path="/Politiquevieprivee" exact component={Politiquevieprivee} />
        <Route path="/Mentionlegales" exact component={Mentionlegales} />
        <Route path="/Politiquecookies" exact component={Politiquecookies} />
        <Route path="/Blog" exact component={Blog} /> 
        <Route path="/Demandededevis/:query" exact component={DemandededevisPage} /> 
        <Route path="/Expatries" exact component={Expatries} /> 
        <Route path="/Pas-Expatries" exact component={PasExpatries} /> 
        <Route path="/Faq" exact component={Faq} />
        <Route path="/GlobeTrotters" exact component={GlobeTrotters} />
        <Route path="/Schengen" exact component={Schengen} /> 
        <Route path="/Kidnapping" exact component={Kidnapping} /> 
        <Route path="/ConseilCima" exact component={ConseilCima} /> 
        <Route path="/ContratCollectifPrevoyance" exact component={ContratCollectifPrevoyance} />
        <Route path="/AuditRisquesEntreprises" exact component={AuditRisquesEntreprises} /> 

      <Footer />
    </>
  )
}
const SwitchRoutesWithRouter = withRouter(SwitchRoutes)

const App = () => {

  //   useEffect( () => {
  //   ReactGA.initialize('UA-236913417-1')

  //   ReactGA.pageview(window.location.pathname + window.location.search)

  // }, [window.location]);

  return (
    <Router>
      <SwitchRoutesWithRouter/>
    </Router>
  );
}

export default App;
