import React from 'react'

export default function ContratCollectifPrevoyance() {
  return (
    <>


<div className="page-title-area page-title-bg-collectifs">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Collectifs santé et prévoyance</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="about-area ptb-60 bg-f8f8f8">
            <div className="container">

                <div className='row'>
                <div className='col-lg-12 col-md-12 col-12'>
                    <div>
                        
                            <p data-aos="fade-up" className='justify-text'>
                            Courtier spécialiste de la protection santé et prévoyance des salariés expatriés ou locaux, nous accompagnons nos clients 
                            entreprises dans la sélection et la mise en place de leurs contrats collectifs, partout dans le monde.
                            </p>
                        
 
                        
                            <p data-aos="fade-up" className='justify-text'>
                            <b>La responsabilité civile</b> (ou pénale) de l’employeur dans ce domaine est importante et être bien conseillé prend alors toute sa valeur. 
                            C’est aussi une <b>responsabilité morale</b> et nombreux DRH ou Dirigeants s’intéressent à cette question. Le Chef d’Entreprise visionnaire 
                            sait que la question de la protection sociale des salariés n’est pas seulement une obligation éventuelle mais une question d’éthique 
                            et de tranquillité d’esprit qui mérite pleine considération. Elle peut aussi s’avérer un impératif pour répondre aux cahiers des charges 
                            de multinationale attentives à leur image comme à celle de leurs sous-traitants. Elle est devenue indispensable pour <b>fidéliser ou recruter 
                            des collaborateurs</b> dans le cadre de packages qui couvrent le salarié et ses ayants-droit.
                            </p>
                        

                        
                            <p data-aos="fade-up" className='justify-text'>
                            Comme DRH ou Dirigeant d’entreprise, vous voulez également pouvoir compter sur des équipes disponibles et professionnelles pour assurer 
                            la sécurité de vos employés, partout dans le monde, en toutes circonstances : <b>accident, maladie, risques de guerres ou climatiques, 
                            d’épidémie ou d’ enlèvements…</b> Les exigences du salarié deviennent souvent légitimes en cas de problème. <b>L’assurance prévoit généralement 
                            des solutions pour tous ces cas de figure.</b> 
                            </p>
                        

                        
                            <p data-aos="fade-up" className='justify-text'>
                            Vous voulez mettre en place internationalement, régionalement ou localement une politique cohérente de protection sociale (maladie-accident, 
                            décès-invalidité, retraite) pour tout ou partie de vos équipes ? Vous recherchez les meilleures solutions sur le marché pour fidéliser et 
                            protéger vos collaborateurs à l’étranger ou en missions temporaires dans le monde ?
                            </p>
                        
   
                        

                            <p data-aos="fade-up" className='justify-text'>
                            Bilan Santé International est une société de courtage d’assurance indépendante et à taille humaine, basée à Paris, fondée par 
                            François Masson, un expert de l’assurance santé internationale intéressé par la question des problématiques de protection sociale, 
                            à l’étranger comme en France. Agréé par les meilleurs spécialistes (BUPA, AXA, Met Life, Allianz, MSH, ISOS, GMC…) et partenaire 
                            actuel ou potentiel de cabinets de courtage indépendants (ou non) dans le monde entier, Bilan Santé International a été Lauréat des 
                            Trophées du Courtage de l’Assurance. François Masson est par ailleurs Conseiller du Commerce Extérieur de la France.
                            </p>
                        

                        
                            <p data-aos="fade-up" className='justify-text'>
                            Nous vous proposons un accompagnement dans vos réflexions en matière de protection sociale, par rapport aux problématiques rencontrées 
                            en matière d’assurance santé, prévoyance ou retraite, en fonction :
                            <ul>
                                <li>de zones géographiques dans le monde ou de pays en particulier (U.E, Afrique, Asie, USA, etc.) ;</li>
                                <li>des catégories de personnels dans le monde (expatriés, locaux, cadres, non-cadres, etc.).</li>
                            </ul>
                            </p>
                        

                        
                            <p data-aos="fade-up" className='justify-text'>
                            Après avoir délimité ensemble le périmètre d’étude que vous souhaitez confier à notre cabinet :
                            <ul>
                                <li>nous réalisons un audit de vos contrats en cours ;</li>
                                <li>nous définissons ensemble le socle minimal recherché par votre entreprise ;</li>
                                <li>nous recherchons localement ou internationalement, les meilleures formules d’assurances, adaptées à vos besoins, en fonction de la réglementation.</li>
                            </ul>
                            </p>
                    

                        
                            <p data-aos="fade-up" className='justify-text'>
                                Le résultat est généralement une économie sur le poste assurances sociales mais également une harmonisation progressive de la 
                                couverture de salariés fidélisés : un challenge pour de nombreuses entreprises mondialisées.
                            </p>
                        

                        
                            <p data-aos="fade-up" className='justify-text'>
                            Bilan Santé International est indépendant des compagnies d’assurances ou sociétés de courtage internationales qui souhaitent imposer 
                            leurs produits ou leur business modèle de gestion de sinistres. Pour autant, nous sommes conscients qu’ils sont les mieux placés pour 
                            apporter aux assurés un service mondial, 365j/365 : notre rôle est de les mettre en concurrence dans le cadre du mandat confié par notre 
                            client.
                            </p>
                        

                        
                            <p data-aos="fade-up" className='justify-text'>
                            Pour les expatriés ou cadres dirigeants, quelle que soit la nationalité ou le pays de résidence, nous proposons des contrats collectifs 
                            internationaux en compléments aux différents organismes sociaux européens ou au 1er euro (ou 1er USD). Nous pouvons également accompagner 
                            les entreprises françaises dans la mise en place de leur affiliation collective à la CFE, dont les salariés expatriés européens pourront 
                            être bénéficiaires. Un avantage majeur d’un contrat collectif est qu’il est généralement sans questionnaire médical : cela signifie que 
                            les salariés (généralement au-delà de 10) seront couverts même si des pathologies préexistent.
                            </p>
                        

                        
                            <p data-aos="fade-up" className='justify-text'>
                            Concernant les assurances collectives locales des personnels locaux et dans le cadre des réglementations du pays, c’est avec la même objectivité 
                            que nous pouvons aider l’entreprise à renégocier des conditions et à sélectionner un assureur local ou régional qui apportera un service de 
                            proximité et de qualité aux bénéficiaires.
                            </p>
                        

                        
                            <p data-aos="fade-up" className='justify-text'>
                            Notre conseil est rémunéré moyennant des honoraires convenus à l’avance en fonction de la mission ou sous la forme de commissions versées 
                            par l’assureur si vous nous confiez le placement de vos contrats, ou les deux. Les missions sont conduites personnellement par 
                            François Masson, Aurélie Faure ou Gillus Landehou, associés de Bilan Santé International, avec l’appui de collaborateurs du cabinet ou 
                            d’experts extérieurs recrutés ponctuellement.
                            </p>
                        

                        
                            <p data-aos="fade-up" className='justify-text'>
                            À titre d’exemple, nous avons récemment mis en place une solution santé / prévoyance pour un groupe français, permettant à la société 
                            d’optimiser la protection de ses expatriés au Nigeria, Vietnam et Algérie tout en réduisant sensiblement le budget global et en simplifiant 
                            les procédures pour l’entreprise comme ses expatriés. Un autre de nos clients au Congo nous a confié la mise en place du contrat en 
                            complément de la CFE pour l’ensemble de ses agents.
                            </p>
                        

                        <p>Demandez-nous votre projet, sans engagement ! La souscription est ensuite 
                            très simple, avec nos conseils en prime !</p>

                        <a href='/Demandededevis/entreprises' data-aos="fade-right"  className="default-btn"><i className="fa-solid fa-chart-column fa-1x fa-flip">&nbsp;&nbsp;</i> Demandez votre devis !<span></span></a>

            
                        <div className='row another-link' data-aos="fade-right" >
                            <h3 className='mt-5'>Vous pourriez être intéressé par les produits suivants :</h3>

                            <div className='col-md-4 mt-2'>
                                <a href="/Kidnapping"   className=""><b>Assurance kidnapping, crise et sécurité</b> <span></span></a>
                            </div>

                            <div className='col-md-4 mt-2'>
                                <a href="/AuditRisquesEntreprises"   className=""><b>Audit risques entreprises</b> <span></span></a>
                            </div>

                            <div className='col-md-4 mt-2'>
                                <a href="/ConseilCima"   className=""><b>Conseil CIMA</b> <span></span></a>
                            </div>

                        </div>

                    </div>

                       
 
                </div>
                
                </div>
                
 
            </div>
        </section>


    </>
  )
}
