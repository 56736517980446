import React, {useState} from 'react'
import SelectComponent from "../../common/SelectComponent"
import { useSelector, useDispatch } from 'react-redux';
import { setCompany, setFonction, setPaysResidence, setNom, setPrenom } from "../../../features/enterpriseForm/enterpriseFormSlice";


export default function Step2({CurrentTitle,Prev,Next,setErrors,errors}) {

  const {pays, nom, prenom, fonction, company} = useSelector((state) => state.enterpriseData);
  const dataStep2 = useSelector((state) => state.enterpriseData);
  const dispatch = useDispatch()


  const NextStep = () => {

      if(nom && prenom && pays && fonction && company) {
        setErrors({})
        Next(); 
      }else {  

        for(const v in dataStep2) {
          if(dataStep2[v] === "") {
            errors = ({...errors, [v] : 'required'})
          }
         } 
         setErrors(errors)  
      }

  }

  const handleChangeNom = e => {     
    if(!e) {
      setErrors({...errors, nom: 'required'})
      dispatch( setNom(""))

    }else {
      setErrors({...errors, nom: ''})
      dispatch( setNom(e.target.value))

    }  
  }

  const handleChangePrenom = e => { 
    if(!e) {
      setErrors({...errors, prenom: 'required'})
      dispatch( setPrenom(''))
      
    }else {
      setErrors({...errors, prenom: ''})
      dispatch( setPrenom(e.target.value))

    }
  }
  

  const handleChangePaysResidence = (e) => {

    if(!e) {
      setErrors({...errors, country: 'required'})
    }else {
      setErrors({...errors, country: ''})
      dispatch( setPaysResidence(e.value))
    }  

  }

  const handleChangeFonction= (e) => {

    if(!e) {
      setErrors({...errors, fonction: 'required'})
      dispatch( setFonction(''))

    }else {
      setErrors({...errors, fonction: ''})
      dispatch( setFonction(e.target.value))
    }  

  }

  const handleChangeCompany= (e) => {

    if(!e) {
      setErrors({...errors, company: 'required'})
      dispatch( setCompany(''))

    }else {
      setErrors({...errors, company: ''})
      dispatch( setCompany(e.target.value))

    }  

  }

  return (
    <div className="container">
      <h6 className="text-center">Nous aimerions vous connaître ! 😊</h6>
      
      <div className="row">
          <div className="form-group col-lg-6 col-md-6 col-sm-12">
            <label>Nom<abbr>*</abbr></label>
            <input
              type="text"
              name="nom"
              onInput={(e) => handleChangeNom(e)}
              defaultValue={nom}
              id="nom"
              className="form-control"
              required
              data-error="Saisissez votre nom"
              placeholder="Nom"
            />
            {
              errors.nom === 'required' ?
              <div className="errorStyle">
                Le champ est obligatoire.
              </div>
                : errors.nom === ''  
                ?
              <div className="successStyle">
                Correct ! 😊
              </div>
              : ''
            }
          </div>


        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="form-group">
            <label htmlFor="">Prénom<abbr>*</abbr></label>
            <input
              type="text"
              name="prenom"
              onInput={(e) => handleChangePrenom(e)}
              defaultValue={prenom}
              id="prenom"
              className="form-control"
              required
              data-error="Saisissez votre prénom"
              placeholder="Prénom"
            />
             {
              errors.prenom === 'required' ?
              <div className="errorStyle">
                Le champ est obligatoire.
              </div>
                : errors.prenom === ''  
                ?
              <div className="successStyle">
                Correct ! 😊
              </div>
              : ''
            }
          </div>
        </div>

        <div className="col-lg-12 col-md-6 col-sm-12">
          <div className="form-group">
            <label htmlFor="">Fonction<abbr>*</abbr></label>
            <input
              type="text"
              name="fonction"
              onInput={(e) => handleChangeFonction(e)}
              defaultValue={fonction}
              id="prenom"
              className="form-control"
              required
              placeholder="Fonction"
            />
             {
              errors.fonction === 'required' ?
              <div className="errorStyle">
                Le champ est obligatoire.
              </div>
                : errors.fonction === ''  
                ?
              <div className="successStyle">
                Correct ! 😊
              </div>
              : ''
            }
          </div>
        </div>

        <div className="col-lg-12 col-md-6 col-sm-12">
          <div className="form-group">
            <label htmlFor="">Société<abbr>*</abbr></label>
            <input
              type="text"
              name="company"
              onInput={(e) => handleChangeCompany(e)}
              defaultValue={company}
              id="prenom"
              className="form-control"
              required
              placeholder="Société"
            />
             {
              errors.company === 'required' ?
              <div className="errorStyle">
                Le champ est obligatoire.
              </div>
                : errors.company === ''  
                ?
              <div className="successStyle">
                Correct ! 😊
              </div>
              : ''
            }
          </div>
        </div>

        <div style={{zIndex: '1000'}} className="col-lg-12 col-md-6 col-sm-12">
          <div className="form-group">
            <label for="inputState">Pays<abbr>*</abbr></label>
            <SelectComponent 
              handleChangePays={handleChangePaysResidence} 
              defaultValue={pays}
              placeholder={pays ? pays : "Pays"}

            ></SelectComponent>
            
            {
                errors.country === 'required' ?
                <div className="errorStyle">
                  Le champ est obligatoire.
                </div>
                  : errors.country === ''  
                  ?
                <div className="successStyle">
                  Correct ! 😊
                </div>
                : ''
                }
          </div>
        </div>

        <div style={{ color: 'red'}} className='col-lg-6 col-md-6 col-sm-12 mt-5'>* Champ obligatoire</div>
      </div>

      

      <div className={`col-lg-12 col-md-12 col-sm-12 form-footer-content-position 
      ${CurrentTitle===3 || CurrentTitle===4 || CurrentTitle===5 ? 'form-footer-margin' : ''}`}>
      
      <button type="button" onClick={Prev}  className="default-btn-annuler">
      Retour </button><span></span>
      
      <button type="button" onClick={NextStep}  style={{ marginLeft: "10px"}} className="default-btn">
      Suivant</button> <span></span>
      <div className="clearfix"></div>

    </div>
      
    </div>
  );
}
