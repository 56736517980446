import React from 'react'
import asfe from '../styles/img/partner-image/asfe-logo-70x70.jpg'

export default function Expatries() {
  return (
    <>
        <div className="page-title-area page-title-bg-expatries">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Assurances pour expatriés</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="about-area ptb-60 bg-f8f8f8">
            <div className="container">

                <div className='row'>
                <div className='col-lg-8 col-md-8 col-12'>
                    <div>
                            <p className='justify-text' data-aos="fade-up">
                                On est généralement considéré <b>expatrié</b> quand on vit plus de six mois et un jour
                                 par an en dehors de son pays d’origine. On peut aussi être simplement <b>détaché</b> si
                                  l’on continue à cotiser à un régime de base en Europe ou ailleurs. Les <b>binationaux</b>
                                   seront également généralement considérés expatriés de fait aux yeux de l’assureur,
                                    même dans leur pays d’origine.
                            </p>

                            <p className='justify-text' data-aos="fade-up">
                                Être expatrié offre en matière d’assurance santé, prévoyance ou retraite, de nombreux avantages,
                                 dont notamment <b>la liberté de choisir son assureur</b> et l’accès à un vaste choix de solutions internationales.
                                  Quand on est expatrié, il existe toujours des solutions pour s’assurer en cas de maladie ou d’accident,
                                   complètement ou partiellement, seul ou en famille, temporairement ou pour une durée indéfinie.
                            </p>

                            <p className='justify-text' data-aos="fade-up">
                                <b></b>Les Français expatriés pourront prolonger leur couverture française à l’étranger en <a href="#">adhérant à la CFE
                                    et à une complémentaire CFE</a>, ou préférer un <a href="#">contrat “au premier euro”</a>. 
                            </p>

                            <p className='justify-text' data-aos="fade-up">
                                Depuis <b>le vote de décembre 2018 à l’Assemblée Nationale</b> (<a href="#">lire notre article sur le sujet</a>) la CFE a
                                 grandement amélioré ses critères pour <b>faciliter l’adhésion du plus grand nombre</b> : elle reste souvent
                                  la meilleure solution pour les Français… et même désormais les Européens à l’étranger ! <b>Nous nous
                                    chargeons alors de vous accompagner dans vos formalités d’adhésion à la CFE</b> à l’occasion de la
                                    souscription de votre contrat “complémentaire CFE”. Nous proposons différents “packs” en fonction
                                     de votre budget et de vos besoins.
                            </p>

                            <p className='justify-text' data-aos="fade-up">
                                Grâce à notre expertise et aux facilités qu’offre internet (sans oublier le téléphone), vous pourrez
                                 partout dans le monde souscrire très simplement avec nous à une couverture santé internationale au meilleur
                                  coût, avec l’un des leaders français ou internationaux dont nous sommes partenaires.
                            </p>

                            <p className='justify-text' data-aos="fade-up">
                                En fonction de la solution retenue,  <a href="#">un questionnaire médical</a> pourra vous être demandé. Les pathologies
                                 préexistantes risquent d’être exclues ou de majorer votre cotisation : <b>mieux vaut donc s’assurer tant
                                    qu’on est en bonne santé et généralement avant de partir à l’étranger !</b>
                            </p>

                            <p className='justify-text' data-aos="fade-up">
                                Avant de souscrire à un contrat santé expatrié, il est également important de se poser les bonnes questions.
                                 Vous pouvez lire à ce sujet notre article : <a href="#">10 conseils pour bien choisir son assurance santé internationale.</a>
                            </p>

                            <p className='justify-text' data-aos="fade-up">
                                Pour compléter la CFE, vous pouvez effecteur vos propres simulations avec <b>ASFE</b> ou <b>APRIL</b>. Sinon <b>MSH</b> vous permet
                                 de faire votre simulation au 1er euro.
                            </p>

                            <p className='justify-text' data-aos="fade-up">
                                Mais compte tenu du nombre de <a href="#">nos partenaires</a>, nous avons beaucoup d’autres solutions qui ne sont pas en 
                                ligne, alors demandez-nous !
                            </p>

                             <p className='justify-text'>Demandez-nous votre projet, sans engagement ! La souscription est ensuite 
                            très simple, avec nos conseils en prime !</p>

                        <a href='/Demandededevis/particuliers' data-aos="fade-right"  className="default-btn"><i className="fa-solid fa-chart-column fa-1x fa-flip">&nbsp;&nbsp;</i> Demandez votre devis !<span></span></a>

            
                        <div className='row another-link' data-aos="fade-right" >
                            <h3 className='mt-5'>Vous pourriez être intéressé par les produits suivants :</h3>

                            <div className='col-md-4 mt-2'>
                                <a href="/Pas-Expatries"   className=""><b>Assurances non expatriés</b> <span></span></a>
                            </div>

                            <div className='col-md-4 mt-2'>
                                <a href="/GlobeTrotters"   className=""><b>Globe-trotters et étudiants</b> <span></span></a>
                            </div>

                            <div className='col-md-4 mt-2'>
                                <a href="/Schengen"   className=""><b>Assurances Schengen</b> <span></span></a>
                            </div>

                        </div>

                    </div>

                       
 
                </div>
                <div className='col-lg-4 col-md-8 col-12'>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="single-about-box page-particulier">
                                
                                    <img src="assets/img/partner-image/asfe-logo-70x70.jpg" width={100} alt=""/>

                                <h3>Notre coup de cœur pour compléter la CFE</h3>
                                <p className='justify-text'><i className="fa-solid fa-heart fa-beat fa-2x" style={{faAnimationDuration: "2s", color: "#C14742"}}></i></p>
                                <p className='justify-text'> ASFE est actuellement l’un de nos assureurs préférés pour compléter la CFE. 
                                    Existe aussi au 1er euro. Votre tarif en direct et souscription immédiate par
                                     CB si vous le souhaitez !
                                </p>
                                <span><a href="https://www.asfe-expat.com/index.php?a=fr&b=Nos-Solutions&c=Relais-Expat-Plus&d=Devis&p=f460d829" target="_blank"><b>En savoir plus</b></a></span>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="single-about-box page-particulier">

                                    <img src="assets/img/partner-image/logo-april-70x70.jpg"  alt=""/>

                                <h3>Le bon plan pour les familles en complément à la CFE</h3>
                                <p className='justify-text'>Un contrat qui complète la CFE (ou la SS) avec possibilité de moduler garanties 
                                    et franchises en fonction du pays d’expatrition.</p>

                                <span><a href="https://tarif-assurance-expat.april-international.com/convergence/?LI=7F3D7817-F025-4BF2-A7BC-A671E1D72E2D&Produit=MHIV1&Marque=APRIL&ContexteLayout=COBRAND&langue=fr-FR&CO=I56533@APMOB" target="_blank"><b>En savoir plus</b></a></span>

                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-12 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="single-about-box page-particulier">
                               
                                {/* <img src="assets/img/partner-image/logo-april-70x70.jpg" alt=""/> */}

                                <h3>Expatrié à la recherche d'un contrat "au 1er euro" ?</h3>
                                <p className='justify-text'>Si vous ne dépendez pas d’un régime obligatoire, ou qu’il ne fonctionne pas
                                     correctement dans votre situation ou encore si vous choisissez de le quitter, 
                                    vous aurez intérêt à souscrire à une assurance santé internationale privée au 1er euro…</p>

                                <span><a href="#"><b>En savoir plus</b></a></span>

                            </div>
                        </div>


                        <div className="col-lg-12 col-md-12 col-12 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="single-about-box page-particulier">
                               
                            <img src="assets/img/partner-image/asfe-logo-70x70.jpg" width={100} alt=""/>

                                <h3>Sans CFE ? ASFE existe aussi "au 1er euro" &#128578;</h3>
                                <p className='justify-text'>Pour les expatriés pressés qui recherchent un contrat au 1er euro, obtenez votre tarif directement ici !</p>

                                <span><a href="https://www.asfe-expat.com/index.php?a=fr&b=Nos-Solutions&c=First-Expat-Plus&d=Devis&p=f460d829"target="_blank"><b>En savoir plus</b></a></span>

                            </div>
                        </div>
                    </div>
                </div>
                </div>
                
 
            </div>
        </section>
    </>
  )
}
