import React from 'react'
import HeroSection from '../components/Welcome/HeroSection'
import QuoteBoxSection from '../components/Welcome/QuoteBoxSection'
import GuarantiSection from '../components/Welcome/GuarantiSection'
import ServiceSection from '../components/Welcome/ServiceSection'
import StepSection from '../components/Welcome/StepSection'
import TarificateurSection from '../components/Welcome/TarificateurSection'
import TestimonialsSection from '../components/Welcome/TestimonialsSection'
import TeamSection from '../components/Welcome/TeamSection'
import PartnerSection from '../components/Welcome/PartnerSection'
import BlogSection from '../components/Welcome/BlogSection'











export default function Welcome() {
  return (
    <div>
        
        <HeroSection/>
        <QuoteBoxSection/>
        <GuarantiSection/>
        <ServiceSection/>
        <StepSection/>
        <TarificateurSection/>
        <TestimonialsSection/>
        <TeamSection/>
        <PartnerSection/>
        <BlogSection/>

    </div>
  )
}
