import React, { useState } from "react";
import FormBottomText from "../components/common/FormBottomText";
import brokerslinKSeeMoreFile from "../statics/brokerslink_files/Brokerslink.pdf";
import { Alert } from 'react-bootstrap';
import { postEntity } from "../services/api";

export default function Apropos() {

  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [button, setButton] = useState(<i className="fa fa-paper-plane"></i>);


  const onSubmit = (event) => {
    event.preventDefault();

    const buttonContent = <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>;

    try {

      if(data.length != []) {
        
        setButton(buttonContent)

        postEntity("/contact/save",data).then(() => {

          setAlert({ success: true, message: 'Votre soumission a été prise en compte' })
          setButton(<i className="fa fa-paper-plane"></i>)
          
          }, () => {
              setAlert({ success: false, message: 'Erreur l\'ors de l\'envoie' });
          }).finally(() => {
              setTimeout(() => {
                setAlert(null);
                window.location.reload()
              }, 5000);
              
            });

        }else {
          setAlert({ success: false, message: 'Veuillez renseigner tous les champs du formulaire' })
        }
      

      } catch (e) {
        console.log('Error')
      }
    
  }

  return (
    <>
      <div className="page-title-area1 page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Qui sommes-nous ?</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about-area ptb-120-60 bg-black-color">
        <div className="container" data-aos="fade-up" data-aos-once="false">
          <div className="row">
            <div
              className="col-lg-12 col-md-12"
              data-aos="fade-left"
              data-aos-once="false"
            >
              <div className="section-title-left">
                <h2>
                  Nous sommes Courtiers spécialistes de <b>l’assurance</b> et de
                  la <b>réassurance</b>.
                </h2>
              </div>
              <div className="" style={{ marginTop: "-30px" }}>
                <p className="justify-text">
                  Après un riche parcours aux AGF, marqué par la naissance
                  d’internet et une carrière internationale,{" "}
                  <b>François Masson</b> rejoint le Bénin en 2006 comme DG de la
                  filiale d'Allianz.
                </p>

                <p className="justify-text">
                  Il y lance notamment <b>Mondial Assistance</b>, pour répondre
                  à une forte demande des expatriés en zone CIMA. Il fonde{" "}
                  <b>BSI</b> en 2010, un courtier{" "}
                  <b style={{ color: "#C14742" }}>"pure player"</b> dans
                  l’assurance, avec la culture du "benchmark” , tant comme
                  utilisateur que fournisseur de solutions d’assurances.
                </p>

                <p className="justify-text">
                  BSI est depuis un <b>spécialiste de l'assurance santé internationale</b> et de
                  l'<b>assistance</b> sanitaire mais également une{" "}
                  <b>équipe experte de la zone CIMA</b> et un{" "}
                  <b>éditeur de solutions informatiques IARD et Vie.</b>
                </p>

                <p className="justify-text">
                  Précurseur en France en matière de digitalisation{" "}
                  <b>(Lauréat des Trophées du Courtage 2013)</b>, BSI apporte
                  aujourd’hui son conseil en sa qualité d’<b>assurtech</b> à des
                  compagnies d’assurances, principalement en Afrique (zone
                  CIMA), qui souhaitent faire évoluer leur offre, la digitaliser
                  et <b>bâtir une identité forte</b> à l’ère de la révolution
                  numérique.
                </p>
              </div>
            </div>

            {/* <div
              className="col-lg-4 col-md-12"
              data-aos="fade-right"
              data-aos-once="false"
            >
              <div className="about-image align-items-end">
                <img
                  src="assets/img/insurtech.jpg"
                  height="400px"
                  alt="image"
                />
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="blog-area ptb-60 pb-70">
        <div className="container" data-aos="fade-up" data-aos-once="false">
          <div className="row">
            <div
              className="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-once="false"
            >
              <img
                src="assets/img/Logo-ebsi.webp"
                style={{ marginTop: "-20px" }}
                width=""
                alt="image"
              />
            </div>
            <div
              className="col-lg-6 col-md-12 col-sm-12 section-title-left"
              data-aos="fade-right"
              data-aos-once="false"
            >
              <h2>Notre Assurtech</h2>
              <p
                className="mt-3 justify-text"
                data-aos="fade-up"
                data-aos-once="false"
              >
                {" "}
                <b style={{ color: "#C14742" }}>E-BSI</b>{" "}
                <b>(Business System for Insurance)</b> est le département IT de
                BSI. Nous sommes à la fois{" "}
                <b>spécialistes « métier » de l’assurance IARD et Vie</b> et
                nous nous sommes entourés de &nbsp;<b>développeurs</b>, experts
                en digitalisation.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-area ptb-60 pb-100 bg-black-color">
        <div className="container" data-aos="fade-up" data-aos-once="false">
          <div
            className="section-title"
            data-aos="fade-up"
            data-aos-once="false"
          >
            <h2>NOUS PRATIQUONS LA SOUSCRIPTION MÉDICALE POUR COMPTE</h2>
            {/* <h2>Souscription médicale</h2> */}
          </div>
          <div
            className="col-lg-12 col-md-12 col-sm-12"
            style={{ marginTop: "-80px" }}
          >
            <div className="row">
              <div
                className="col-lg-6 col-md-12 col-12"
                data-aos="fade-left"
                data-aos-once="false"
              >
                <div className="content">
                  <div className="mt-5">
                    <h4 className="font-weight-900 justify-text">
                      Spécialistes de l'assurance santé.
                    </h4>
                    <p className="justify-text">
                      Spécialistes de l'assurance santé, notre équipe collabore
                      depuis 2010 avec les meilleures compagnies mondiales,
                      spécialisées dans l'assurance{" "}
                      <b>santé individuelle ou collective</b>.
                    </p>
                  </div>

                  <div className="" style={{ marginTop: "70px" }}>
                    <h4 className="font-weight-900 justify-text">
                      Ce benchmark et cette expertise sont désormais proposés à
                      des assureurs en zone CIMA.
                    </h4>
                    <p className="justify-text">
                      Ce benchmark et cette expertise sont désormais proposées à
                      des assureurs en zone CIMA qui souhaitent nous confier
                      leur <b>souscription médicale</b>. De la même manière que
                      la gestion des sinistres peut être confiée à un « TPA » ,{" "}
                      <b>
                        nous avons a mis en place notre propre département de
                        "souscription médicale"
                      </b>{" "}
                      , entièrement digitalisé et effectuons la souscription
                      pour compte de l'assureur.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-6 col-md-12 col-12"
                data-aos="fade-right"
                data-aos-once="false"
              >
                <div className="content">
                  <div className="mt-5">
                    <h4 className="font-weight-900 justify-text">
                      Sous la supervision d’un médecin conseil à Cotonou.
                    </h4>
                    <p className="justify-text">
                      Sous la supervision d’un médecin conseil à Cotonou, la
                      commission médicale BSI a des procédures normées et
                      coordonne avec le médecin conseil de l’assureur. Elle
                      analyse chaque dossier et donne un avis médical à
                      l'assureur.
                    </p>
                  </div>

                  <div className="" style={{ marginTop: "40px" }}>
                    <h4 className="font-weight-900 justify-text">
                      Nos procédures garantissent à l'assureur des souscriptions
                      impartiales.
                    </h4>
                    <p className="justify-text">
                      Nos procédures garantissent à l'assureur des souscriptions
                      impartiales, sans favoritisme ni interférence, au tarif
                      approprié, avec a posteriori un meilleur contrôle des
                      autorisations préalables et donc des résultats techniques
                      mieux maitrisés.{" "}
                      <b>
                        La souscription médicale en Afrique, c'est une
                        spécialité qui prend aussi en compte le contexte local !
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-area ptb-60 pb-70">
        <div className="container" data-aos="fade-up" data-aos-once="false">
          <div className="row">
            <div
              className="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-once="false"
            >
              <img
                src="assets/img/brokerslink.webp"
                style={{ marginTop: "-20px", borderRadius: "5px" }}

                alt="image"
              />
            </div>
            <div
              className="col-lg-6 col-md-12 col-sm-12 section-title-left"
              data-aos="fade-right"
              data-aos-once="false"
            >

              {/* <h2>BROKERSLINK AFFILIATE</h2> */}
              <h2>NOUS SOMMES AFFILIÉS BROKERSLINK</h2>
              <p className="justify-text mt-3">
                Nous sommes fiers et ravis d'être affiliés au{" "}
                <b style={{ color: "#C14742" }}>
                  réseau mondial Brokerslink via notre participation dans ABC au
                  Bénin
                </b>{" "}
                : 35 milliards de dollars de primes, via 125 courtiers
                indépendants, dans 125 pays. Une longueur d'avance, une
                expertise unique et un accès aux solutions internationales les
                plus pointues, avec le support précieux de l'équipe centrale
                Brokerslink et son Board of Directors.
              </p>
              <p className="col-md-12 col-sm-12 col-12">
                En savoir plus sur le réseau BrokersLink ?{" "}
                <a
                  className="become-partner-btn"
                  href={brokerslinKSeeMoreFile}
                  target="_blank"
                  style={{ fontSize: "15px", color: "#C14742" }}
                >
                  <b>Cliquez ici</b> <i className="fa fa-arrow-right"></i>
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-area ptb-60 pb-70">
        <div className="container" data-aos="fade-up" data-aos-once="false">
          <div className="row">
            <div
              className="col-lg-8 col-md-8 col-sm-12 col-12 section-title-left"
              style={{ marginBottom: "-2px" }}
              data-aos="fade-left"
              data-aos-once="false"
            >
              {/* <h2>Devenir partenaire</h2> */}
              <h2>REJOIGNEZ-NOUS</h2>
              <p className="justify-text">
                Le partenariat conçu par BSI permet à des personnes physiques (généralement Agents de compagnies ou Courtiers) n’ayant pas les connaissances techniques, la capacité de placement ou tout simplement le temps, de fidéliser des clients existants ou d’en conquérir de nouveaux, grâce à des solutions variées en matière d’assurance santé internationale ou prévoyance.
              </p>
              <p className="col-md-12 col-sm-12 col-12">
                Devenir partenaire ?{" "}
                <a
                  className="become-partner-btn"
                  href="/Devenir_partenaire"
                  style={{ fontSize: "15px", color: "#C14742" }}
                >
                  <b>Cliquez ici</b> <i className="fa fa-arrow-right"></i>
                </a>
              </p>
            </div>

            <div
              className="col-lg-4 col-md-4 col-sm-12 col-12 mt-3"
              data-aos="fade-right"
              data-aos-once="false"
            >
              <img
                src="assets/img/devenir-partenaire-apropos.webp"
                style={{ borderRadius: "5px" }}
                alt="image"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="team-area ptb-60">
        <div
          className="container"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-once="false"
        >
          <div className="section-title" data-aos="zoom-in-down">
            <h2>Les associés BSI</h2>
            {/* <p className="">Des spécialistes « métiers » à votre service !</p> */}
            <p>Des spécialistes « métiers » à votre service. Et en plus on est sympa 😄</p>
          </div>

          <div className="team-slides owl-carousel owl-theme mtreduce-35">
            <div
              className="single-team-box"
              data-aos="flip-left"
              data-aos-offset="200"
              data-aos-delay="10"
              data-aos-duration="2500"
            >
              <div className="image">
                <img
                  src="assets/img/team-image/image-aurelie.webp"
                  alt="image"
                />

                <ul className="social">
                  <li>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="content">
                <h3>Aurélie Faure</h3>
                <span>Associée BSI</span>
                <p>
                  Diplômée de Grenoble Ecole de Management, spécialisation
                  marketing & communication. Aurélie est notre responsable
                  clientèle VIP et finances.
                </p>
              </div>
            </div>

            <div
              className="single-team-box"
              data-aos="flip-right"
              data-aos-offset="200"
              data-aos-delay="10"
              data-aos-duration="2500"
            >
              <div className="image">
                <img src="assets/img/team-image/image-gillus.webp" alt="image" />

                <ul className="social">
                  <li>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="content">
                <h3>Gillus Landehou</h3>
                <span>Associé BSI</span>
                <p>
                  Certifié en assurance et réassurance par London School of
                  Insurance & Africa Re. Diplômé de l’Ecole Nationale d’Economie
                  du Bénin. Gillus est celui qui sait parler à l'oreille de nos
                  développeurs :).
                </p>
              </div>
            </div>

            <div
              className="single-team-box"
              data-aos="flip-up"
              data-aos-offset="200"
              data-aos-delay="10"
              data-aos-duration="2500"
            >
              <div className="image">
                <img
                  src="assets/img/team-image/image-françois.webp"
                  alt="image"
                />

                <ul className="social">
                  <li>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="content">
                <h3>François Masson</h3>
                <span>Associé et fondateur BSI - C.C.E.F</span>
                <p>
                  Ancien DG Allianz Bénin. François a une expérience très vaste
                  dans l'univers de l'assurance internationale et de la zone
                  CIMA.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-area ptb-60 pb-70">
        <div
          className="container"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-once="false"
        >
          <div className="section-title">
            <h2>Ils nous ont fait confiance</h2>
            <p className="">
              Nous sommes fiers de collaborer avec les meilleurs assureurs et
              gestionnaires tiers-payant spécialistes de la santé et de la
              prévoyance dans le monde.
            </p>
          </div>
          <div
            className="col-lg-12 col-md-12 col-sm-12 text-center"
            style={{ marginTop: "-30px" }}
          >
            <div className="container">
              <div className="partner-slides owl-carousel owl-theme">
                <a
                  href="http://www.atlantiqueassurances.bj/"
                  className="single-partner-item"
                >
                  <img src="assets/img/logo-atlantique-iardt.png" alt="image" />
                </a>

                <a
                  href="https://aps-benin.com/"
                  className="single-partner-item"
                >
                  <img src="assets/img/logo-aps.png" alt="image" />
                </a>

                <a
                  href="https://aabvie.net/public/accueil"
                  className="single-partner-item"
                >
                  <img src="assets/img/logo-atlantique-vie.png" alt="image" />
                </a>

                <a
                  href="https://www.sanlampanafrica.com/"
                  className="single-partner-item"
                >
                  <img src="assets/img/logo-sanlam.png" alt="image" />
                </a>

                <a
                  href="https://www.groupensia.com/"
                  className="single-partner-item"
                >
                  <img src="assets/img/logo-nsia.png" alt="image" />
                </a>

                <a
                  href="http://www.africabrokersonline.com/"
                  className="single-partner-item"
                >
                  <img
                    src="assets/img/logo_africa_brokers_company.png"
                    alt="image"
                  />
                </a>

                <a
                  href="https://www.orabank.net/fr"
                  className="single-partner-item"
                >
                  <img src="assets/img/logo-oragroup.png" alt="image" />
                </a>

                <a
                  href="https://sfa-congo.com/"
                  className="single-partner-item"
                >
                  <img src="assets/img/logo-sfa.png" alt="image" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-area ptb-60 pb-70 bg-black-color">
        <div
          className="container"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-once="false"
        >
          <div className="section-title">
            <h2>Nous contacter</h2>
          </div>
          <div
            className="col-lg-12 col-md-12 col-sm-12 text-center"
            style={{ marginTop: "-30px" }}
          >
            <div className="offset-md-3 offset-lg-3 col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div className="contact-form">
                {
                  alert && <Alert style={{ color: '#fff', backgroundColor: '#4AA340' }} variant={alert.success ? 'success' : 'danger'} >
                    {alert.message}</Alert>
                }
                <form id="contactForm" onSubmit={onSubmit} method="post">
                  <div className="row">
                    <div
                      className="col-lg-12 col-md-6"
                      data-aos="fade-left"
                      data-aos-delay="250"
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          onInput={(e) => setData({ ...data, name: e.target.value })}
                          name="name"
                          id="name"
                          className="form-control"
                          required
                          data-error="Saisissez votre nom"
                          placeholder="Nom"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div
                      className="col-lg-12 col-md-6"
                      data-aos="fade-left"
                      data-aos-delay="260"
                    >
                      <div className="form-group">
                        <input
                          type="email"
                          onInput={(e) => setData({ ...data, email: e.target.value })}
                          name="email"
                          id="email"
                          className="form-control"
                          required
                          data-error="Saisissez votre adresse email"
                          placeholder="Email"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div
                      className="col-lg-12 col-md-12"
                      data-aos="fade-left"
                      data-aos-delay="270"
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          onInput={(e) => setData({ ...data, phone: e.target.value })}
                          name="phone_number"
                          id="phone_number"
                          required
                          data-error="Saisissez votre numéro de téléphone"
                          className="form-control"
                          placeholder="Téléphone"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div
                      className="col-lg-12 col-md-12"
                      data-aos="fade-left"
                      data-aos-delay="280"
                    >
                      <div className="form-group">
                        <textarea
                          onInput={(e) => setData({ ...data, message: e.target.value })}
                          name="message"
                          className="form-control"
                          id="message"
                          cols="30"
                          rows="6"
                          required
                          data-error="Saisissez le message"
                          placeholder="Votre Message"
                        ></textarea>
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div
                      className="col-lg-12 col-md-12 text-center"
                      data-aos="fade-left"
                    >
                      <button type="submit" className="default-btn">
                        Envoyer {button}<span></span>
                      </button>
                      {/* <div
                        id="msgSubmit"
                        className="h3 text-center hidden"
                      ></div> */}
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </form>
              </div>
              <FormBottomText></FormBottomText>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
