import React from 'react'

export default function ConseilCima() {
  return (
    <>
        <div className="page-title-area page-title-bg-cima">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Conseil en zone CIMA</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="about-area ptb-60 bg-f8f8f8">
            <div className="container">

                <div className='row'>
                <div className='col-lg-12 col-md-12 col-12'>
                    <div>
                            <p data-aos="fade-up" className='justify-text'>
                                <b style={{ color: "#C14742"}}>François Masson est un expert de la zone CIMA</b> (Conférence Interafricaine des Marchés d’Assurance). Après avoir exercé pour une grande compagnie internationale, il bénéficie de larges connexions dans 
                                le monde de l’assurance et d’une fine perception du marché et de ses évolutions. Il est également implanté 
                                à Cotonou (Bénin) depuis 2010 comme courtier en assurance <a href='http://africabrokersonline.com/' target="_blank">(Africa Brokers’ Company)</a>.
                            </p>
                        

                            <p data-aos="fade-up" className='justify-text'>
                            Bilan Santé International propose aux sociétés d’assurances internationales ou africaines ainsi qu’aux banques habilitées de les 
                            accompagner dans la mise en place et le management de leurs projets d’assurances, micro-assurance ou bancassurance en zone CIMA.
                            </p>
                        

                            <p data-aos="fade-up" className='justify-text'>
                            Pour rappel la zone CIMA regroupe 14 pays essentiellement francophones, qui ont adopté un code des assurances identique et 
                            harmonisé leurs réglementations en matière d’assurance. Son siège est à Brazzaville au Gabon. 
                            </p>
                        

                            <p data-aos="fade-up" className='justify-text'>

                                Les Pays de la Zone CIMA

                                <p className='row'>

                                    <ul className='offset-1 col-md-3'>
                                        <li >Bénin</li>
                                        <li >Burkina Faso</li>
                                        <li >Cameroun</li>
                                        <li >Centrafrique</li>
                                        <li >Congo</li>
                                    </ul>

                                    <ul className='offset-1 col-md-3'>
                                        <li >Côte d’Ivoire</li>
                                        <li >Gabon</li>
                                        <li >Guinée-Bissau</li>
                                        <li >Guinée Équitoriale</li>
                                        <li >Mali</li>
                                    </ul>

                                    <ul className='offset-1 col-md-3'>
                                        <li >Niger</li>
                                        <li >Sénégal</li>
                                        <li >Tchad</li>
                                        <li >Togo</li>
                                    </ul>

                                </p>

                            </p>
                        

                        <h2 data-aos="fade-right" className='mt-5'>Assistance en cas de crise et sécurité</h2>

                            <p data-aos="fade-up" className='justify-text'>
                            De nouveaux besoins naissent en Afrique, portés par une classe moyenne demandeuse (assurance santé, assurance en ligne…) 
                            et avide de nouvelles technologie. Dans cette zone CIMA, Assureurs africains et européens sont amenés à des échanges 
                            accrus : fronting, actuariat, création et marketing de produits… La zone CIMA est en ce sens une région attractive pour 
                            les porteurs de projets d’assurance et la croissance y est rapide comparativement aux marchés saturés d’Europe.
                            </p>
                        

                            <p data-aos="fade-up" className='justify-text'>
                                BSI  apporte un vrai savoir-faire et une connaissance de la zone CIMA, particulièrement en matière d’assurance santé 
                                et de digitalisation dans l’assurance.
                            </p>
                        

                            <p data-aos="fade-up" className='justify-text'>
                                C’est une des raisons pour lesquelles <b style={{ color: "#C14742"}}>BSI a été "Lauréat des Trophées du Courtage de l’Assurance" !</b>
                            </p>
                        

                            <p data-aos="fade-up" className='justify-text'>
                            Parmi les dernières réalisations de BSI, nous sommes fiers de citer le contrat lancé au Bénin par <a href="https://aps-benin.com/" target="_blank"><b>l’APS avec Atlantique Assurances.</b></a> Bilan Santé International a apporté ici son expertise dans la souscription médicale individuelle pour créer une “assurtech” d’un 
                            genre nouveau en zone CIMA.
                            </p>
                        

                            <p data-aos="fade-up" className='justify-text'>
                            François Masson a également été co-organisateur avec West Africa Business School de la conférence annuelle Bancassurance en 
                            zone CIMA.
                            </p>
                        

                            <p data-aos="fade-up" className='justify-text'>
                            François Masson est également <a href='https://www.cnccef.org/' target="_blank">Conseiller du Commerce Extérieur de la France</a> et 
                            investisseur solidaire dans <a href="http://www.solidarites-entreprises.org/" target="_blank">Solidarité Entreprise Nord Sud</a>. 
                            Il s’appuie dans ses missions sur son équipe en France (Paris, Grenoble) et à Cotonou, mais également ponctuellement sur des experts et 
                            intervenants extérieurs (formateurs, webmasters, actuaires…).
                            </p>
                        

                            <p data-aos="fade-up" className='justify-text'>
                                Contactez-nous pour connaître nos références ou nous faire part de vos projets !
                            </p>
                        

                        <p className='justify-text'>Parlons ensemble de vos besoins ! Notre équipe d'experts est à votre écoute !</p>

                        <a href='/Demandededevis/entreprises' data-aos="fade-right"  className="default-btn"><i className="fa-solid fa-chart-column fa-1x fa-flip">&nbsp;&nbsp;</i> Demandez votre devis !<span></span></a>

            
                        <div className='row another-link' data-aos="fade-right" >
                            <h2 className='mt-5'>Vous pourriez être intéressé par les produits  suivants :</h2>

                            <div className='col-md-4 mt-2'>
                                <a href="/ContratCollectifPrevoyance"   className=""><b>Collectifs santé et prévoyance</b> <span></span></a>
                            </div>

                            <div className='col-md-4 mt-2'>
                                <a href="/AuditRisquesEntreprises"   className=""><b>Audit risques entreprises</b> <span></span></a>
                            </div>

                            <div className='col-md-4 mt-2'>
                                <a href="/Kidnapping"   className=""><b>Assurances kidnapping, crise et sécurité</b> <span></span></a>
                            </div>

                        </div>

                    </div>

                       
 
                </div>
                
                </div>
                
 
            </div>
        </section>
    </>
  )
}
