import React from 'react'
import coronavirus from '../../styles/img/coronavirus.jpg'
import brokerslink from '../../styles/img/brokerslink.jpg'
import sportsante from '../../styles/img/sportsanté.jpg'

export default function BlogSection() {
  return (
    <section className="blog-area ptb-60">
            <div className="container"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-once="false">
                <div className="section-title" data-aos="slide-up" data-aos-offset="200"
                data-aos-delay="10" data-aos-duration="2000">
                    <h2>Articles du Blog</h2>
                    <p>Retrouvez toutes nos actualités ici !</p>
                </div>

                {
                    window.innerWidth < 767
                    ?  
                    <div className="row mtreduce-35 team-slides owl-carousel owl-theme">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-blog-post">
                                <div className="post-image">
                                    <a href="#"><img src={coronavirus} alt="image"/></a>

                                    <div className="date"><i className="flaticon-timetable"></i> 05.02.2020</div>
                                </div>

                                <div className="post-content">
                                    <h3><a href="#">Autour du Coronavirus</a></h3>
                                    <p>
                                        L’avis d’un expert : Selon le Professeur Christian D’Enfert : « On a des niveaux de 2 à 3 % de 
                                        mortalité chez les personnes qui présentent des symptômes sévères, explique le directeur de 
                                        l’Unité Biologie ...
                                    </p>

                                    <a href="#" className="default-btn">Lire plus <span></span></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-blog-post">
                                <div className="post-image">
                                    <a href="#"><img src={brokerslink} alt="image"/></a>

                                    <div className="date"><i className="flaticon-timetable"></i> 01.12.2021</div>
                                </div>

                                <div className="post-content">
                                    <h3><a href="#">A Cotonou, François Masson rejoint le réseau Brokerslink</a></h3>
                                    <p>
                                        François Masson, associé de BSI mais également du cabinet Africa Brokers’ Company au Bénin,
                                        rejoint le réseau mondial de courtiers en assurances Brokerslink ...
                                    </p>

                                    <a href="#" className="default-btn">Lire plus <span></span></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">

                            <div className="single-blog-post">
                                <div className="post-image">
                                    <a href="#"><img src={sportsante} alt="image"/></a>

                                    <div className="date"><i className="flaticon-timetable"></i> Sep 13, 2021</div>
                                </div>

                                <div className="post-content">
                                    <h3><a href="#">ASSUREZ-VOUS ET FAÎTES DU SPORT !</a></h3>
                                    <p>METTEZ TOUTES LES CHANCES DE VOTRE CÔTÉ : ASSUREZ-VOUS ET FAÎTES DU SPORT ! 
                                        L’hygiène de vie est primordiale pour votre santé et le sport devrait  – quel que soit votre âge
                                        – en faire partie ...
                                    </p>

                                    <a href="#" className="default-btn">Lire plus <span></span></a>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <div className="row mtreduce-35">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="post-image">
                                <a href="#"><img src={coronavirus} alt="image"/></a>

                                <div className="date"><i className="flaticon-timetable"></i> 05.02.2020</div>
                            </div>

                            <div className="post-content">
                                <h3><a href="#">Autour du Coronavirus</a></h3>
                                <p>
                                    L’avis d’un expert : Selon le Professeur Christian D’Enfert : « On a des niveaux de 2 à 3 % de 
                                    mortalité chez les personnes qui présentent des symptômes sévères, explique le directeur de 
                                    l’Unité Biologie ...
                                </p>

                                <a href="#" className="default-btn">Lire plus <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="post-image">
                                <a href="#"><img src={brokerslink} alt="image"/></a>

                                <div className="date"><i className="flaticon-timetable"></i> 01.12.2021</div>
                            </div>

                            <div className="post-content">
                                <h3><a href="#">A Cotonou, François Masson rejoint le réseau Brokerslink</a></h3>
                                <p>
                                    François Masson, associé de BSI mais également du cabinet Africa Brokers’ Company au Bénin,
                                     rejoint le réseau mondial de courtiers en assurances Brokerslink ...
                                </p>

                                <a href="#" className="default-btn">Lire plus <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">

                        <div className="single-blog-post">
                            <div className="post-image">
                                <a href="#"><img src={sportsante} alt="image"/></a>

                                <div className="date"><i className="flaticon-timetable"></i> Sep 13, 2021</div>
                            </div>

                            <div className="post-content">
                                <h3><a href="#">ASSUREZ-VOUS ET FAÎTES DU SPORT !</a></h3>
                                <p>METTEZ TOUTES LES CHANCES DE VOTRE CÔTÉ : ASSUREZ-VOUS ET FAÎTES DU SPORT ! 
                                    L’hygiène de vie est primordiale pour votre santé et le sport devrait
                                </p>

                                <a href="#" className="default-btn">Lire plus <span></span></a>
                            </div>
                        </div>
                    </div>

                </div>
                }

                

                <div className="col-lg-12 col-md-12 text-center">
                    <a href="/Blog" className="default-btn">Voir plus d'articles <i className="flaticon-right-chevron"></i><span></span></a>
                </div>
                </div>
            {/* </div> */}
        </section>
  )
}
