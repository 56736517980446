import React from 'react'

export default function Mentionlegales() {
  return (
    <>

        <div className="page-title-area page-title-bg-mentions">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Mentions légales</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="blog-area ptb-60 pb-70">
            <div className="container" data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-once="false">
                    <div className="col-lg-12 col-md-12 col-sm-12" style={{marginTop: "-30px"}}>
                        <ol className='politiqueCookiesListe' type="I">

                            <li>
                                <h4 className='mt-4'>Préambule</h4>
                                <p className='justify-text'>
                                    Le site internet <a href='/'>bilansanteinternational.com</a> est destiné à informer les visiteurs (clients et prospects) 
                                    sur les solutions d’assurances et les services mis à leur disposition.
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Qui sommes-nous ?</h4>
                                <p className='justify-text'>
                                    L’éditeur du site <a href='/'>bilansanteinternational.com</a> est la société BILAN SANTÉ INTERNATIONAL.
                                    BILAN SANTÉ INTERNATIONAL est une SARL au capital de 5000 euros, immatriculée au Registre du Commerce et des Sociétés de Paris sous 
                                    le numéro 519 688 550. Siège sociale : 27 boulevard Saint-Martin, 75 003 Paris. BILAN SANTÉ INTERNATIONAL est intermédiaire en assurances 
                                    immatriculé à l'ORIAS sous le n° 10055568 (<a href="https://www.orias.fr">https://www.orias.fr</a>) et est une entreprise soumise au contrôle de l'Autorité de Contrôle 
                                    Prudentiel et de Résolution (4 Place de Budapest 75436 PARIS CEDEX 09).
                                </p>
                                <p className='justify-text'>Contact : François MASSON</p>
                                <p className='justify-text'>Vous trouverez plus de détails sur nos métiers sur la page <a href="/Apropos">À propos</a></p>
                                <p>
                                L’hébergeur du Site <a href='/'>bilansanteinternational.com</a> est OVH, SAS au capital de 10 174 560 euros, immatriculée au Registre du Commerce 
                                et des Sociétés de Lille Métropole sous le numéro 424 761 419 00045 dont le siège social est situé au 2 rue Kellermann - 59100 
                                Roubaix – France. 
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>À quoi servent nos Conditions Générales d’Utilisation ?</h4>
                                <p className='justify-text'>
                                    Les présentes Conditions Générales d’Utilisation (CGU) encadrent juridiquement l’utilisation des services de notre site 
                                    <a href='/'> bilansanteinternational.com</a> (ci-après dénommé « le Site ») par Vous-même.
                                </p>

                                <p className='justify-text'>
                                    « Vous » êtes internaute et utilisateur du Site. En accédant à notre Site et en l’utilisant, Vous acceptez les termes 
                                    des présentes CGU sans réserve.
                                </p>

                                <p className='justify-text'>
                                    Votre accord sur leur contenu résulte de l’utilisation de notre Site sans que Vous ayez besoin d’apposer votre signature 
                                    manuscrite ou électronique sur un document quelconque.
                                </p>

                                <p className='justify-text'>
                                    Nos CGU sont accessibles à tout moment sur notre Site. En cas de modification, la version qui Vous est applicable est 
                                    celle en vigueur sur bilansanteinternational.com au moment de votre dernière consultation du site.
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Accès et disponibilité du Site</h4>
                                <p className='justify-text'>
                                    Pour accéder à nos services, Vous aurez besoin de matériel informatique et d’une connexion internet.<br/>
                                    Ces frais sont à votre charge. Vous pourrez accéder gratuitement à la majeure partie de notre Site.
                                </p>

                                <p className='justify-text'>
                                    Pour accéder à nos services, Vous aurez besoin de matériel informatique et d’une connexion internet.<br/>
                                    Ces frais sont à votre charge. Vous pourrez accéder gratuitement à la majeure partie de notre Site.
                                </p>

                                <p className='justify-text'>
                                    Vous n’avez aucune obligation d’achat. Mais la souscription à l’un des produits d’assurance que nous distribuons sera 
                                    payante, selon les conditions prévues pour chacun des produits et/ou services.
                                </p>

                                <p className='justify-text'>
                                    De même, en fonction des services que nous mettons à votre disposition, certains nécessitent d’obtenir au préalable, de 
                                    la part de nos services, des identifiants et mots de passe : il s’agit des espaces partenaires et des espaces assurés. 
                                    Nous réservons leur accès – en fonction des services - aux distributeurs membres de notre réseau, à nos prospects et à 
                                    nos adhérents et souscripteurs.
                                </p>

                                <p className='justify-text'>
                                    Enfin, pour que notre site fonctionne correctement, nous devons parfois l’interrompre pour des raisons de maintenance. 
                                    Dans cette hypothèse, l’accès au site pourra être interrompu ou suspendu sans préavis ni justification. Sachez que nous 
                                    mettrons tout en œuvre pour rétablir la situation dans les plus brefs délais.
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Nos engagements et les vôtres</h4>
                                <p className='justify-text'>
                                    Nous engageons notre responsabilité pour des faits qui nous seraient directement imputables et qui
                                    Vous causeraient un préjudice direct, à l'exclusion de tout préjudice indirect ou à un tiers.
                                    En revanche, notre responsabilité ne sera pas engagée dans les cas suivants :
                                    <ul>
                                        <li>si Vous avez fait une mauvaise utilisation des services de notre Site – que ce soit volontairement ou pas ;</li>
                                        <li>
                                            en raison des contenus disponibles sur bilansanteinternational.com : sachez que les informations diffusées 
                                            sur notre Site sont présentées à titre purement informatif et sont sans valeur contractuelle. 
                                            Nous mettons toutefois beaucoup d’importance à leur qualité. Notre responsabilité pourra être 
                                            retenue si nos contenus sont considérés comme illicites, et qu’après nous l’avoir demandé, 
                                            nous n’avons pas rendu indisponibles ces contenus ;
                                        </li>
                                        <li>
                                            si des modifications des dispositions administratives et juridiques apparaissant 
                                            après la publication et nous sont applicables ;
                                        </li>
                                        <li>
                                            si notre Site devait être hacké et que votre matériel devait être infecté par un virus, un vers, 
                                            un cheval de troie ou toute autre attaque destructrice. En aucun cas, nous ne pourrons être considérés 
                                            comme responsable des dommages de toute nature directs ou indirects, résultant de l’accès et/ou de 
                                            l’utilisation du Site, incluant notamment : toute perte d’exploitation ; perte de programme ; altération, 
                                            destruction ou perte de données ; toute détérioration ou virus qui pourraient infecter l’équipement 
                                            informatique ou tout autre bien de l’Utilisateur de l’Application ; de la négligence de l’Utilisateur 
                                            ou de la mauvaise utilisation des informations fournies à l’Utilisateur au titre du Site et des Services.
                                        </li>
                                        
                                    </ul>
                                </p>

                                    <p className='justify-text'>
                                        Notez toutefois que la sécurité informatique est prise très au sérieux au sein de notre société et que nos 
                                        équipes informatiques mettent tout en œuvre pour qu’aucun de ces dommages n’arrivent.
                                    </p>

                                    <p className='justify-text'>
                                        Vous acceptez l’ensemble des risques découlant de l’utilisation du Site.
                                    </p>

                                    <p className='justify-text'>
                                        Lorsque Vous êtes invité à fournir des informations : il Vous incombe de Vous assurer que toutes 
                                        les informations communiquées sont sincères, exactes et à jour.
                                    </p>

                                    <p className='justify-text'>
                                        Lorsque Vous êtes sur notre Site, Vous Vous interdisez notamment :
                                        <ul>
                                            <li>
                                                de Vous livrer à des actes de diffamation, d’injures, de menaces, de harcèlement ou à des actes 
                                                attentatoires à la vie privée ou à la dignité humaine, d’apologie des crimes contre l’humanité, de 
                                                négation de génocides, d’incitation à la violence, à la haine raciale ou à la pornographie ;
                                            </li>
                                            <li>
                                                de modifier, désassembler ou arranger tout ou partie du Site, en ce compris les logiciels utilisés 
                                                dans le Site, d’enlever ou tenter d’enlever les mentions copyright et noms des personnes 
                                                mentionnées sur le Site ;
                                            </li>
                                            <li>
                                                d’entraver le bon fonctionnement du Site de quelque manière que ce soit, notamment par 
                                                l’intrusion d’éventuels virus ou autres infections informatiques circulant 
                                                sur le réseau Internet.
                                            </li>
                                        </ul>
                                    </p>

                                    <p className='justify-text'>
                                        Vous êtes responsable de l'utilisation que Vous faites des services de notre Site et des informations 
                                        que Vous nous transmettez dans les formulaires. Nous Vous recommandons de vérifier leur exactitude 
                                        et de rectifier d’éventuelles erreurs.
                                    </p>

                                    <p className='justify-text'>
                                        Force majeure :
                                        Notre responsabilité et la vôtre ne pourront pas être engagées, peu importe le préjudice, dommage 
                                        ou perte, s’ils sont la conséquence d’un cas de force majeure tel que reconnu par l’article 1218 
                                        du code civil.
                                    </p>

                            </li>

                            <li>
                                <h4 className='mt-4'>Propriété intellectuelle</h4>
                                <p className='justify-text'>
                                    Nous sommes propriétaires du contenu de notre Site, des marques et logos (autres que ceux de nos partenaires), 
                                    des illustrations graphiques, des textes, … Ces éléments sont protégés par les lois françaises et 
                                    internationales relatives à la propriété intellectuelle, par le Code de la propriété intellectuelle
                                    et par le droit d’auteur. Vous n’avez pas le droit de reproduire, en partie ou en totalité, pour quelle 
                                    que raison que ce soit, le contenu de nos pages. Vous pourriez être poursuivi pour délit de contrefaçon.
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Liens hypertextes</h4>
                                <p className='justify-text'>
                                    Le Site peut être constitué de liens hypertextes. En cliquant sur ces derniers, Vous sortirez 
                                    de la plateforme. Cette dernière n’a pas de contrôle et ne peut pas être tenue responsable 
                                    du contenu des pages web relatives à ces liens.
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Crédit photos et illustrations</h4>
                                <p className='justify-text'>
                                    Crédits photos : ©Shutterstock, ©Fotolia, @Adobe Stock, ©Freepik, ©Yanick Folly, ©Bilan Santé International.
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Conception graphique</h4>
                                <p className='justify-text'>
                                    Conceptions et designs graphiques réalisés par : Gillus LANDEHOU & Aurélie FAURE.
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Convention sur la preuve</h4>
                                <p className='justify-text'>
                                    Tous les données, informations, fichiers et tout autre élément numérique que Vous échangerez 
                                    avec nous ou que nous conserverons, notamment dans nos bases de données ou sur nos serveurs, 
                                    constitueront des preuves recevables, valides, opposables et ayant la force probante d'un 
                                    acte sous seing privé. Ils seront réputés valables et opposables de la même manière, 
                                    dans les mêmes conditions et avec la même force probante que tout document qui serait 
                                    établi, reçu ou conservé par écrit.
                                </p>

                                <p className='justify-text'>
                                    Si Vous deviez contester la recevabilité, la validité, l’opposabilité ou la force probante, des 
                                    éléments de nature ou sous format électronique précités, sur le fondement de leur nature 
                                    électronique, Vous devrez en apporter la preuve.
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Modification des conditions d'utilisation du Site et des Services</h4>
                                <p className='justify-text'>
                                    BILAN SANTÉ INTERNATIONAL se réserve le droit, à tout moment, de modifier tout ou 
                                    partie des conditions d'utilisation des Sites ou des Services proposés, avec 
                                    ou sans notification.<br/>
                                    Les modifications entreront en vigueur dès leur mise en ligne.<br/>
                                    Il appartient donc à l'Utilisateur de revoir de manière régulière les termes des présentes. 
                                    Le simple fait de continuer à utiliser les Services implique l'acceptation par 
                                    l'Utilisateur de toutes modifications apportées aux présentes.
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Invalidité</h4>
                                <p className='justify-text'>
                                    Si une ou plusieurs stipulations des présentes conditions d'utilisation sont tenues 
                                    pour non valides ou déclarées telles en application d'une loi, d'un règlement ou 
                                    à la suite d'une décision devenue définitive d'une juridiction compétente, les autres 
                                    stipulations garderont toute leur force et leur portée.
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Généralités : durée, loi applicable, juridiction compétente …</h4>
                                <p className='justify-text'>
                                    Les présentes CGU sont valables pour une durée indéterminée. Elles prennent effet 
                                    dès que Vous commencez à utiliser nos services.
                                </p>
                                <p className='justify-text'>
                                    Pour toutes les questions d’application, d’interprétation des présentes CGU, ou tous les 
                                    cas non prévus par celles-ci, ou toute contestation ou réclamation relative aux 
                                    présentes, nous tenterons de trouver un accord amiable entre nous.
                                </p>
                                <p className='justify-text'>
                                    A défaut, nous devrons avoir recours aux tribunaux français compétents pour régler le contentieux.
                                </p>
                            </li>
                        </ol>

                    </div>
            </div>
        </section>

    </>
  )
}
