import React from 'react'

export default function Plandusite() {
  return (
    <>

        <div className="page-title-area page-title-bg-sitemap">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Plan du site</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="sitemap-area ptb-60">
            <div className="container" data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-once="false">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 sitemap-area-items">
                                 
                                 <ul>
                                    <h2>Particuliers</h2>

                                    <li><a href="/Expatries">Assurances expatriés</a></li>
                                    <li><a href="/Pas-Expatries">Assurances non expatriés</a></li>
                                    <li><a href="#">Globe-trotters et étudiants</a></li>
                                    <li><a href="#">Assurances Schengen</a></li>
                                 </ul>
                         </div>
     
                         <div className="col-lg-12 col-md-12 col-sm-12 sitemap-area-items">
                                  
                                  <ul>
                                    <h2>Entreprises</h2>

                                    <li><a href="#">Collectifs santé et prévoyance</a></li>
                                    <li><a href="#">Audit risques entreprises</a></li>
                                    <li><a href="#">Assurance kidnapping, crise et sécurité</a></li>
                                    <li><a href="#">Conseil CIMA</a></li>
                                  </ul>
                          </div>
     
                          <div className="col-lg-12 col-md-12 col-sm-12 sitemap-area-items">

                                    <ul>
                                        <hr />

                                        <li><a href="/E-bsi">E-BSI</a></li>
                                        <li><a href="/BSimmobilier">BSimmobilier</a></li>
                                        <li><a href="/Apropos">À propos</a></li>
                                        <li><a href="/Devenir_partenaire">Devenir partenaire</a></li>
                                        <li><a href="/Blog">Blog</a></li>
                                        <li><a href="/Faq">FAQ</a></li>
                                        <li><a href="/Mentionlegales">Mentions légales</a></li>
                                        <li><a href="/Politiquevieprivee">Politique vie privée</a></li>
                                        <li><a href="/Politiquecookies">Politique cookies</a></li>
                                        <li><a href="/Politiquecookies">Plan du site</a></li>

                                    </ul>
  
                          </div>
                    </div>         
            </div>
        </section>

    </>
  )
}
