import React from 'react'
import { useEffect, useState } from "react";
import aetna from '../../styles/img/partner-image/AETNA.png'
import allianz from '../../styles/img/partner-image/Allianz-NEW.jpg'
import cfe from '../../styles/img/partner-image/cfe-80x80.jpg'
import humanis from '../../styles/img/partner-image/Humanis-3-150x90.png'
import april from '../../styles/img/partner-image/logo-april-70x70.jpg'
import cigma from '../../styles/img/partner-image/logo-cigna-80x80.png'
import chapka from '../../styles/img/chapka.png'
import moncey from '../../styles/img/partner-image/MONCEY-150x90.jpg'
import asfe from '../../styles/img/partner-image/asfe-logo-70x70.jpg'
import { getEntity } from "../../../src/services/api";
import moment from 'moment';
import 'moment/locale/fr';

import { Swiper, SwiperSlide } from 'swiper/react';
//import { EffectCoverflow, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

export default function PartnerSection() {

  const [partenaireData, partenaireCharge] = useState([]);
  useEffect(() => {
    moment.locale('fr');
    getEntity("/partenaires").then((res) => {

      partenaireCharge(res.data.data);
      console.log(res.data.data);
    }).catch((err) => {
      console.log(err.message);
    })
  }, []);

  return (


    <>
      <section className="team-area ptb-60">
        <div className="container"
          data-aos="fade-up"
          data-aos-duration="1500" slot=''
          data-aos-once="false">
          <div className="section-title" data-aos="zoom-in-down">
            <h2>Nos partenaires</h2>
            <p>Nous sommes fiers de collaborer avec les meilleurs assureurs et TPA spécialistes de la santé et de la prévoyance dans le monde.</p>
          </div>


         
            <Swiper
              autoplay={{ delay: 3000, disableOnInteraction: true }}
              // observer={false}
              // observeslidechildren={false}
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              spaceBetween={1}
              slidesPerView={1}
              // navigation
              pagination={{ clickable: true }}
              // scrollbar={{ draggable: false }}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => {
                setTimeout(() => {
                  swiper.update(); // ------> this solution
                }, 3000);
              }}
            >
              <SwiperSlide>
                <div className="single-partner-item" >
                  <a href="https://www.aetna.com/">
                    <img src={aetna} alt="image" />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="single-partner-item">
                  <a href="https://www.allianz.fr/">
                    <img src={allianz} alt="image" />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="single-partner-item">
                  <a href="http://cfe.fr">
                    <img src={cfe} alt="image" />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="single-partner-item">
                  <a href="https://www.malakoffhumanis.com/">
                    <img src={humanis} alt="image" />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="single-partner-item">
                  <a href="https://tarif-assurance-expat.april-international.com/convergence/?LI=7F3D7817-F025-4BF2-A7BC-A671E1D72E2D&Produit=MHIV1&Marque=APRIL&ContexteLayout=COBRAND&langue=fr-FR&CO=I56533@APMOB">
                    <img src={april} alt="image" />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="single-partner-item">
                  <a href="https://fr.cignaglobal.com/quote/pages/quote/BQTAboutYou.html?BQT=d5e89d4da17d119c5d5758a287d844dc&owner=CUSTOMER&new=true">
                    <img src={cigma} alt="image" />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="single-partner-item">
                  <a href="https://www.chapkadirect.fr/index.php?action=produit&id=795&app=cd_bilan">
                    <img src={chapka} alt="image" />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="single-partner-item">
                  <a href="https://www.moncey-assurances.com/">
                    <img src={moncey} alt="image" />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="single-partner-item">
                  <a href="https://www.asfe-expat.com/index.php?p=f460d829">
                    <img src={asfe} alt="image" />
                  </a>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
       
      </section>
    </>
  )
}
