import React, {useState,useEffect} from 'react'
import SelectComponent from "../../common/SelectComponent"
import Select from 'react-select';
import {DefaultFinCouverture,CheckDebutCouverture,CheckFinCouverture,CurrentDate, formatDate} from '../../../helpers/moment'



export default function Step3({CurrentTitle,Prev,Next,values,setValues,activeRadio,setActiveRadio,checkedDuration,setCheckedDuration,errors,setErrors}) {

  const [minFirstDate, setMinFirstDate] = useState("")
  const [minSecondDate, setMinSecondDate] = useState("")


  useEffect( () => {
    
    setMinFirstDate(CurrentDate().add(1, 'd').format("YYYY-MM-DD"))

    if(values.finCourverture < values.debutCourverture) {
      setValues({...values, finCourverture : ""});
    }

  },[values.finCourverture,values.debutCourverture,minSecondDate])

  const NextStep = () => {

    if(values.expatriationCountry && values.courvertureSouhaite && values.courvertureExistante) {

      if(activeRadio.step4Radio2 === 'checked') {
          
        if(values.debutCourverture && values.finCourverture) {

          if (errors.debutCourverture === 'errorMessage') {
            setErrors({...errors, debutCourverture: 'required'})
          }else if (errors.finCourverture === 'errorMessage') {
            setErrors({...errors, finCourverture: 'required'})
          }else {
            setErrors({})
            Next(); 
          } 
        }else {
          if (!values.debutCourverture) {
            setErrors({...errors, debutCourverture: 'required'})
          }else if (!values.finCourverture) {
            setErrors({...errors, finCourverture: 'required'})
          }else {
            setErrors({...errors, debutCourverture: 'required', finCourverture: 'required'})
          }
          
        }
      }

      if(activeRadio.step4Radio1 === 'checked') {
          
        if(values.plusDeDouzeMois === true) {
          setErrors({})
          Next();
        }else {
          setErrors({...errors, plusDeDouzeMois: 'required', debutCourverture: '', finCourverture: ''})
        }
      }

    }else {  

     for(const v in values) {
      if(values[v] === "") {
        errors = ({...errors, [v] : 'required'})
      }
     } 
     setErrors(errors)    
        
    }

  }

  const handleChangePaysResidence = (e) => {

    if(!e) {
      setErrors({...errors, expatriationCountry: 'required'})
    }else {
      setErrors({...errors, expatriationCountry: ''})
      setValues({...values, expatriationCountry : e.value}); 
    }  

  }

  const handleChangeZoneCourverture = (e) => {

    if(!e) {
      setErrors({...errors, courvertureSouhaite: 'required'})
    }else {
      setErrors({...errors, courvertureSouhaite: ''})
      setValues({...values, courvertureSouhaite : e}); 
    }  
    
  }

  const handleChangeCourvertureExistante = (e) => {

    if(!e) {
      setErrors({...errors, courvertureExistante: 'required'})
    }else {
      setErrors({...errors, courvertureExistante: ''})
      setValues({...values, courvertureExistante : e}); 
    }

  }

  const handleChangeDebutCourverture = (e) => {

    if(!e) {
      setErrors({...errors, debutCourverture: 'required'})
      setValues({...values, debutCourverture : ''});
    }else {
      if (CheckDebutCouverture(e) === true) {
        setErrors({...errors, debutCourverture: ''})
        setValues({...values, debutCourverture : e}); 
        setMinSecondDate(DefaultFinCouverture(e))
      }else {
        setErrors({...errors, debutCourverture: 'errorMessage'})
        setValues({...values, debutCourverture : ''});
      }
      
    }
 
  }

  const handleChangeFinCourverture = (e) => {

    if(!e) {
      setErrors({...errors, finCourverture: 'required'})
      setValues({...values, finCourverture : ''});
    }else {
      if (CheckFinCouverture(e,minFirstDate) === true) {
      setErrors({...errors, finCourverture: ''})
      setValues({...values, finCourverture : e}); 
      }else {
        setErrors({...errors, finCourverture: 'errorMessage'})
        setValues({...values, finCourverture : ''});
      }
    }
 
  }

  const OnChecked = (event) => {

    if(event.target.name === "isNotDouzeMois") {
      setErrors({...errors, plusDeDouzeMois: 'noMessage'})
      setActiveRadio({...activeRadio, step4Radio2: 'checked', step4Radio1: ''});
      setValues({...values, plusDeDouzeMois : false, finCourverture: '', debutCourverture: ''});
    }else if(event.target.name === "isDouzeMois") {
        setErrors({...errors, debutCourverture: 'noMessage', finCourverture: 'noMessage'})
        setActiveRadio({...activeRadio, step4Radio1: 'checked', step4Radio2: ''});
        setValues({...values, plusDeDouzeMois : true, finCourverture: '', debutCourverture: ''});
    }else {
        setActiveRadio({...activeRadio, step4Radio1: "", step4Radio2: ""});
    }
    setCheckedDuration(event.target.name);    

  }

  const optionCouvertureSouhaite = [

    { value: 'Afrique', label: 'Afrique' },
    { value: 'Europe', label: 'Europe' },
    { value: 'Afrique et Europe', label: 'Afrique et Europe' },
    { value: 'Monde entier sauf USA', label: 'Monde entier sauf USA' },
    { value: 'Monde entier', label: 'Monde entier' }

  ]

  const optionCouvertureExistante = [

    { value: 'Sans couverture', label: 'Sans couverture' },
    { value: 'Sécurité sociale', label: 'Sécurité sociale' },
    { value: 'CFE', label: 'CFE' },
    { value: 'Autre organisme', label: 'Autre organisme' },
    { value: 'Monde entier', label: 'Monde entier' }
    
  ]

  return (
    <>
      <h6 className="text-center">Informations relatives à votre couverture</h6>
      <div className="row">
        <div style={{zIndex: '7000'}} className="offset-md-1 col-lg-10 col-md-10 col-sm-12">
          <div className="form-group">
            <label for="inputState">Pays de résidence ou d’expatriation<abbr>*</abbr></label>
            <SelectComponent 
              handleChangePays={handleChangePaysResidence} 
              defaultValue={values.expatriationCountry}
              placeholder={values.expatriationCountry ? values.expatriationCountry : "Pays de résidence ou d’expatriation"}
            ></SelectComponent>
            {
                errors.expatriationCountry === 'required' ?
                <div className="errorStyle">
                  Le champ est obligatoire.
                </div>
                  : errors.expatriationCountry === ''  
                  ?
                <div className="successStyle">
                  Correct ! 😊
                </div>
                : ''
                }
          </div>
        </div>

        <div className="offset-md-1 col-lg-10 col-md-10 col-sm-12">
          <div className="form-group">
            <label htmlFor="">Durée de couverture souhaitée<abbr>*</abbr></label>

            <div className="form-check col-lg-6 col-md-6">
              <input
                className="form-check-dimensions form-check-input"
                type="radio"
                name="isDouzeMois"
                id="flexRadioDefault1"
                onChange={OnChecked}
                key={activeRadio.step4Radio1}
                checked={activeRadio.step4Radio1}
              />
              <label className="form-check-label" for="flexRadioDefault1">
                Plus de 12 mois
              </label>
            </div>

            <div className="form-check col-lg-6 col-md-6">
              <input
                className="form-check-dimensions form-check-input"
                type="radio"
                name="isNotDouzeMois"
                id="flexRadioDefault2"
                onClick={OnChecked}
                key={activeRadio.step4Radio2}
                checked={activeRadio.step4Radio2}
              />
              <label className="form-check-label" for="flexRadioDefault2">
                Moins de 12 mois
              </label>
            </div>

            {
              activeRadio.step4Radio2 === '' && activeRadio.step4Radio1 === '' ?
              <div className="errorStyle">
                Veuillez faire un choix
              </div>
              : ''
            }
          </div>
        </div>

       

        {
          checkedDuration === "isNotDouzeMois" &&
          <>
            <div className="offset-md-1 form-group col-lg-5 col-md-5 col-sm-12" style={{ zIndex: '8000'}}>
            <label>Début de courverture<abbr>*</abbr>{" "} 
              {  window.innerWidth < 720 && values.debutCourverture === "" && <b className='fa-beat-fade' style={{ color: "#C14742"}}>Cliquez 02 fois 👇</b> }
            </label>
          
                <input
                  type={ window.innerWidth > 600 ? 'date' : 'text'}
                  name="date"
                  min={minFirstDate}
                  onChange={(e) => handleChangeDebutCourverture(e.target.value)}
                  defaultValue={values.debutCourverture}
                  id="datefieldStart"
                  className="form-control"
                  placeholder="jj/mm/aaaa"
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                />

            {
                errors.debutCourverture === 'required' ?
                <div className="errorStyle">
                  Le champ est obligatoire.
                </div>
                  : errors.debutCourverture === ''  
                  ?
                <div className="successStyle">
                  Correct ! 😊
                </div>
                : errors.debutCourverture === 'errorMessage'
                ?
                <div className="errorStyle">
                  la date de début doit être postérieure au {formatDate(CurrentDate())}.
                </div>
                :
                ''
                }
          </div>


        { values.debutCourverture &&
          <div className="col-lg-5 col-md-5 col-sm-12" style={{ zIndex: '8000'}}>
            <div className="form-group">
            <label>Fin de courverture<abbr>*</abbr>
              {  window.innerWidth < 720 && values.finCourverture === "" && <b className='fa-beat-fade' style={{ color: "#C14742"}}>Cliquez 02 fois 👇</b> }
            </label>

                <input
                  type={ window.innerWidth > 600 ? 'date' : 'text'}
                  name="date"
                  max={minSecondDate}
                  onChange={(e) => handleChangeFinCourverture(e.target.value)}
                  defaultValue={values.finCourverture}
                  id="date"
                  className="form-control"
                  placeholder="jj/mm/aaaa"
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                />

              {
                  errors.finCourverture === 'required' ?
                  <div className="errorStyle">
                    Le champ est obligatoire.
                  </div>
                    : errors.finCourverture === ''  
                    ?
                  <div className="successStyle">
                    Correct ! 😊
                  </div>
                  : errors.finCourverture === 'errorMessage'
                  ?
                  <div className="errorStyle">
                    la date de fin de couverture doit être antérieure au {formatDate(minSecondDate)}. 
                  </div>
                  : ''
                  }
            </div>
          </div>
        }
        
          </>


        }

        <div className="offset-md-1 col-lg-10 col-md-10 col-sm-12" style={{ zIndex: '6000'}}>
          <div className="form-group App">
            <label for="inputState">Zone de couverture souhaitée<abbr>*</abbr></label>
            <Select
              defaultValue={values.courvertureSouhaite}
              onChange={(e) => handleChangeZoneCourverture(e.value)}
              options={optionCouvertureSouhaite}
              id="inputState"
              className="App"
              name="zoneCourverture"
              isSearchable={true}
              placeholder={values.expatriationCountry ? values.expatriationCountry : "Sélectionner une zone de courverture"}
            />
             
            {
                errors.courvertureSouhaite === 'required' ?
                <div className="errorStyle">
                  Le champ est obligatoire.
                </div>
                  : errors.courvertureSouhaite === ''  
                  ?
                <div className="successStyle">
                  Correct ! 😊
                </div>
                : ''
            }
          </div>
        </div>

        <div className="offset-md-1 col-lg-10 col-md-10 col-sm-12" style={{ zIndex: '5000'}}>
          <div className="form-group App">
            <label for="inputState">Couverture existante<abbr>*</abbr></label>

            <Select
              defaultValue={values.courvertureExistante}
              onChange={(e) => handleChangeCourvertureExistante(e.value)}
              options={optionCouvertureExistante}
              id="inputState"
              className="App"
              name="courvertureExistante"
              isSearchable={true}
              placeholder={values.courvertureExistante ? values.courvertureExistante : "Sélectionner une zone de courverture existante"}
            />
            {
                errors.courvertureExistante === 'required' ?
                <div className="errorStyle">
                  Le champ est obligatoire.
                </div>
                  : errors.courvertureExistante === ''  
                  ?
                <div className="successStyle">
                  Correct ! 😊
                </div>
                : ''
                }
          </div>
        </div>

        <div style={{ color: 'red'}} className='offset-md-1 col-lg-6 col-md-6 col-sm-12 mt-5'>* Champ obligatoire</div>
      </div>

      <div className={`col-lg-12 col-md-12 col-sm-12 form-footer-content-position 
      ${CurrentTitle===3 || CurrentTitle===4 || CurrentTitle===5 ? 'form-footer-margin' : ''}`}>
      
      <button type="button" onClick={Prev}  className="default-btn-annuler">
      Retour </button><span></span>

      <button type="button" onClick={NextStep}  style={{ marginLeft: "10px"}} className="default-btn">
      Suivant</button><span></span>
      <div className="clearfix"></div>
    </div>
    </>
  );
}
