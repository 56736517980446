import React from 'react';

const TYPECOUVERTURE = {
             0: 'Collectifs santé et prévoyance',
             1: 'Kidnapping',
             2: 'Homme clé',
             3: 'Multirisque entreprise',
             4: 'Cyber risques',
             5: 'Responsabilité civile professionnelle',
             6: 'RCMS',
             7: 'Audit risques entreprises',
             8: 'Conseil CIMA',
             9: 'Autre',
  }

export default TYPECOUVERTURE;