import React from 'react'

export default function FormBottomText() {
  return (
    <div className="mt-4 text-black">
        Vos données seront traitées pour répondre à votre demande de mise en relation conformément 
        à notre <a href='/Politiquevieprivee'>Politique vie privée</a> que nous vous invitons à consulter.
    </div>
  )
}
