import React from 'react'
import souscriptionWeb from '../../styles/img/rubrique_etape.webp'
import souscriptionMobile from '../../styles/img/Rubrique-ETAPES-DE-SOUSCRITPIONS-MOBILE-PNG.png'


export default function StepSection() {
  return (
    <section className="blog-area ptb-60">
            <div className="container" data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-once="false">
                <div className="section-title" data-aos="slide-up" data-aos-offset="200"
                    data-aos-delay="10" data-aos-duration="2000">
                    <h2>Les étapes de souscription</h2>
                    <p>Internet et le téléphone ont rendu les formalités très simples ! Découvrez ici les étapes de votre demande d’adhésion.</p>
                </div>

                <div className="row">

                    <div className="col-lg-12 col-md-12 col-12 text-center mtreduce-35 etape-image">
                        <img src={souscriptionWeb} className="" alt="image"/>  

                    </div>
                    <div className="col-lg-12 col-md-12 col-12 text-center mtreduce-35 etape-image-mobile">
                        <img src={souscriptionMobile} className="" alt="image"/>  

                    </div>
                    
                </div>

            </div>
        </section>
  )
}
