import React, {useState} from 'react'

export default function Step2({CurrentTitle,Prev,Next,values,setValues,setErrors,errors}) {


  const NextStep = () => {

      if(values.nom && values.prenom) {
        setErrors({})
        Next(); 
      }else {  

        for(const v in values) {
          if(values[v] === "") {
            errors = ({...errors, [v] : 'required'})
          }
         } 
         setErrors(errors)  
      }

  }

  const handleChangeNom = e => {     
    if(!e) {
      setErrors({...errors, nom: 'required'})
      setValues({...values, nom : ''});
    }else {
      setErrors({...errors, nom: ''})
      setValues({...values, nom : e}); 
    }  
  }

  const handleChangePrenom = e => { 
    if(!e) {
      setErrors({...errors, prenom: 'required'})
      setValues({...values, prenom : ''}); 
    }else {
      setErrors({...errors, prenom: ''})
      setValues({...values, prenom : e}); 
    }
  }

  return (
    <div className="container">
      <h6 className="text-center">Veuillez svp renseigner vos nom et prénom</h6>
      
      <div className="row">
          <div className="form-group col-lg-6 col-md-6 col-sm-12">
            <label>Nom<abbr>*</abbr></label>
            <input
              type="text"
              name="nom"
              onInput={(e) => handleChangeNom(e.target.value)}
              defaultValue={values.nom}
              id="nom"
              className="form-control"
              required
              data-error="Saisissez votre nom"
              placeholder="Nom"
            />
            {
              errors.nom === 'required' ?
              <div className="errorStyle">
                Le champ est obligatoire.
              </div>
                : errors.nom === ''  
                ?
              <div className="successStyle">
                Correct ! 😊
              </div>
              : ''
            }
          </div>


        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="form-group">
            <label htmlFor="">Prénom<abbr>*</abbr></label>
            <input
              type="text"
              name="prenom"
              onInput={(e) => handleChangePrenom(e.target.value)}
              defaultValue={values.prenom}
              id="prenom"
              className="form-control"
              required
              data-error="Saisissez votre prénom"
              placeholder="Prénom"
            />
             {
              errors.prenom === 'required' ?
              <div className="errorStyle">
                Le champ est obligatoire.
              </div>
                : errors.prenom === ''  
                ?
              <div className="successStyle">
                Correct ! 😊
              </div>
              : ''
            }
          </div>
        </div>

        <div style={{ color: 'red'}} className='col-lg-6 col-md-6 col-sm-12 mt-5'>* Champ obligatoire</div>
      </div>

      

      <div className={`col-lg-12 col-md-12 col-sm-12 form-footer-content-position 
      ${CurrentTitle===3 || CurrentTitle===4 || CurrentTitle===5 ? 'form-footer-margin' : ''}`}>
      
      <button type="button" onClick={Prev}  className="default-btn-annuler">
      Retour </button><span></span>
      
      <button type="button" onClick={NextStep}  style={{ marginLeft: "10px"}} className="default-btn">
      Suivant</button> <span></span>
      <div className="clearfix"></div>

    </div>
      
    </div>
  );
}
