import React from 'react'
import ReactGA from 'react-ga';


export default function TarificateurSection() {

    const trakerEventServiceextra = (event) => {

        ReactGA.event({
            category: 'Service',
            action: 'Service : EXTRAPASS MIX SANTE',
            });

    }

    const trakerEventServicemyTravel = (event) => {

        ReactGA.event({
            category: 'Service',
            action: 'Service : MY TRAVEL COVER APRIL',
            });
        

    }

    const trakerEventServiceexpaAsfe = (event) => {

        ReactGA.event({
            category: 'Service',
            action: "Service : START' SPAT ASFE",
            });
        

    }
  return (
    <section className="blog-area ptb-60">
            <div className="container" >
                <div className="section-title" data-aos="slide-up" data-aos-offset="200"
                    data-aos-delay="10" data-aos-duration="2000" data-aos-once="false">
                    <h2>Notre sélection d'assurances voyage</h2>
                    <p>Votre tarif en ligne avec nos <b>3 assureurs préférés.</b> Contrats <b style={{color: "#C14742"}}>"à durée ferme"</b> pour la prise en charge des cas "inopinés" 
                        lors de vos voyages. <b>Etudiants et PVT :</b> nous avons d'autres solutions pour vous !</p>
                </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 text-center mtreduce-35">

                        <div className="container" data-aos="fade-up" data-aos-delay="10" data-aos-duration="2000" data-aos-once="false">
                            <div className="row">
                                <div className="col-xs-12 col-12 offset-sm-4 col-sm-8">
                                    <div className="row">
                                        <div className="col-md-4 col-4 col-xs-4 theader free">
                                            <div className="ptitle">EXTRAPASS MIS SANTÉ</div>
                                            <div className="pprice">dès 34 € / mois</div>
                                        </div>
                                        <div className="col-md-4 col-4 col-xs-4 theader standard" >
                                            <div className="ptitle">MY TRAVEL COVER APRIL</div>
                                            <div className="pprice">dès 38 € / mois</div>
                                            <i style={{color: "#C14742"}} className="fa fa-heart fa-beat-fade"></i>
                                        </div>
                                        <div className="col-md-4 col-4 col-xs-4 theader premium">
                                            <div className="ptitle">START' EXPAT ASFE</div>
                                            <div className="pprice">dès 39 € / mois</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    Limite souscription
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">
                                            <span>aucune</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">
                                            <span>80 ans</span> 
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <span>65 ans</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    Durée
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">
                                            <span>15 jours à 12 mois</span>                    
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">
                                            <span>15 jours à 12 mois</span>                             
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <span>1 à 12 mois</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    Assistance 24/24
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">
                                            <span>oui</span>         
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">
                                            <span>oui</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <span>oui</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    Frais médicaux
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">
                                            <span>1er € ou complément SS</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">
                                            <span>1er € ou compl. SS</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            
                                            <span>1er €</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    Plafond annuel global
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">
                                            <span>aucun</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">
                                            <span>200 000 €</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <span>250 000 €</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    Prise en charge COVID 19
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">
                                            <span>oui</span>
                                        </div> 
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">
                                            <span>oui</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <span>oui</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    Capital décès / invalidité
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">
                                            <span>oui</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">
                                            <span>oui</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <span>oui</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    Perte de bagages
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">              
                                            <span>max 2 000 €</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">       
                                            <span>max 1 600 €</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <span>max 2 000 €</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    Motif du séjour
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">                     
                                            <span>sans restriction</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">                               
                                            <span>sans restriction</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <span>sans restriction</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row feature">
                                <div className="col-xs-12 col-12 col-sm-4 cfeature infos">
                                    RC Vie privée
                                </div>
                                <div className="col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">
                                            <span>oui</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">
                                            <span>oui</span>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <span>oui</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row feature color">
                                <div className="offset-md-4 col-xs-12 col-12 col-sm-8">
                                    <div className="row">
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature free">
                                            <a href="https://www.extrapass.fr/bsi/souscription?ipa=26&id_cat=14" target="_blank" className="btn btn-lg btn-xs devis-btn" onClick={trakerEventServiceextra}>Devis</a>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature standard">
                                            <a href="https://tarif-assurance-expat.april-international.com/convergence/?LI=7F3D7817-F025-4BF2-A7BC-A671E1D72E2D&Produit=MCTV1&Marque=APRIL&ContexteLayout=COBRAND&langue=fr-FR&CO=I56533@APMOB" onClick={trakerEventServicemyTravel}  target="_blank" className="btn btn-lg devis-btn-middle">Devis</a>
                                        </div>
                                        <div className="col-xs-4 col-4 col-sm-4 ccfreature premium">
                                            <a href=" https://www.asfe-expat.com/index.php?a=fr&b=Nos-Solutions&c=Start-Expat&d=Devis&p=f460d829" target="_blank" onClick={trakerEventServiceexpaAsfe} className="btn btn-lg devis-btn">Devis</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
            </div>
        </section>
  )
}
