import moment from 'moment';

export const Old = (birthdate) => {
    
    const age = moment().diff(birthdate, 'years'); 
    return age;
}

export const DefaultFinCouverture = (debut) => {

    const fin = moment(debut, "YYYY-MM-DD").add(1, 'years').add(1, 'd').format("YYYY-MM-DD");
    return fin;

}

export const CheckDebutCouverture = (debut) => {

    if(debut >= moment().add(1, 'd').format("YYYY-MM-DD")) {
        return true
    }else {
        return false
    }
}

export const CheckFinCouverture = (fin,debut) => {

    if(fin <= DefaultFinCouverture(debut)) {
        return true
    }else {
        return false
    }
}

export const CurrentDate = () => {

    const currentDate = moment();
    return currentDate;
}


export const formatDate = (date) => {

    const dateFormatted = moment(date).format("DD/MM/YYYY");
    return dateFormatted;
}

// export const La = (date) => {

//     const dateFormatted = moment(date).format("LLLL");
//     return dateFormatted;
// }
